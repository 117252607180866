import PureWebsocket from '~/api/global/webosket/samples/PureWebsocket';
import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';

export const tryConnectToWebsocket = () => {
  const {
    $api: { websocket },
    $config,
  } = useNuxtApp();
  try {
    websocket.connect($config.public.websocketChannel as string, WEBSOCKET_FIRST_CHANNEL, PureWebsocket);
  } catch {}
};
