<template>
  <div class="price-wrapper">
    <SharedKitPrice :variant="priceVariant" />
    <div class="discount">- {{ discount }}%</div>
  </div>
</template>

<script setup lang="ts">
import type { IPriceProps } from './Price.types';
import { ESharedKitPriceTypes, type TSharedKitPriceVariant } from '~/components/SharedKitPrice/SharedKitPrice.types';
import { CurrencyName } from '~/constants/currency.constants';
import type { TCurrencyType } from '~/types/Shared.types';

const { defaultCurrency } = useProjectSettings();

const props = defineProps<IPriceProps>();

const priceVariant = computed<TSharedKitPriceVariant>(() => {
  return {
    currency: CurrencyName[defaultCurrency as TCurrencyType],
    oldPrice: props.oldPrice,
    price: props.price,
    type: ESharedKitPriceTypes.MONEY,
  };
});
</script>

<style src="./Price.style.scss" lang="scss" scoped></style>
