import StatisticHttp from '~/repository/modules/statistic/StatisticHttp';

export default class StatisticService {
  private readonly statisticHttp: StatisticHttp;

  constructor() {
    this.statisticHttp = new StatisticHttp();
  }

  public getStatistic<T>() {
    return this.statisticHttp.getStatistic<T>();
  }
}
