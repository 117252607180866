import { useAuthStore } from '~/features/authentication/store/authorization';

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const { isAuth } = storeToRefs(authStore);

  useUTM(to).saveQuery();

  const baseRoute = useRouteBaseName();
  const localeRoute = useLocaleRoute();

  const toName = baseRoute(to);
  const isUnauthPage = toName === ROUTING.PAYMENT.UNAUTH.name;

  if (isUnauthPage && isAuth.value) return navigateTo(localeRoute(ROUTING.PAYMENT.MAIN));
  if (!isUnauthPage && !isAuth.value) return navigateTo(localeRoute(ROUTING.PAYMENT.UNAUTH));
});
