export const useAuthPopupStore = defineStore('general-auth/popup', () => {
  const isOpen = ref(false);

  const open = () => {
    isOpen.value = true;
  };

  const close = () => {
    isOpen.value = false;
  };

  return {
    close,
    isOpen,
    open,
  };
});
