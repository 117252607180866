import type { TBackgroundColor, TCurrencyType } from '~/types/Shared.types';

export enum ESharedKitPriceTypes {
  COINS = 'coins',
  MONEY = 'money',
}

export enum ECaseImageSize {
  SMALL = 152,
  BIG = 224,
}

interface ISharedKitPriceCoins {
  amount: number;
  color: TBackgroundColor;
  icon: string;
  type: ESharedKitPriceTypes.COINS;
}

interface ISharedKitPriceMoney {
  currency: TCurrencyType;
  oldPrice?: number;
  price: number;
  type: ESharedKitPriceTypes.MONEY;
}

export type TSharedKitPriceVariant = ISharedKitPriceCoins | ISharedKitPriceMoney;

export interface ISharedKitPriceProps {
  variant: TSharedKitPriceVariant;
}
