import type { RouteLocationNormalized } from '#vue-router';
import OAuthService from '~/repository/extensions/oauth/OAuth.service';

function hasRouteRedirectAfterOAuth(to: RouteLocationNormalized) {
  return to.query.u_tm;
}

export default defineNuxtRouteMiddleware((to) => {
  if (!hasRouteRedirectAfterOAuth(to)) return;
  const oauthService = new OAuthService();
  const url = oauthService.toLocation();

  if (!url) return;
  return navigateTo(url, {
    external: true,
  });
});
