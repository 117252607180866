export const BATTLES_ROUTES = {
  BATTLE: {
    getDynamicPath: (battleId: string) => `/${battleId}`,
    name: 'single-battle-page',
    path: '/:battleId',
  },
  CREATE: {
    name: 'create-battle-page',
    path: '/create',
  },
  HISTORY: {
    name: 'history-battles-page',
    path: '/history',
  },
  MAIN: {
    name: 'main-battles-page',
    path: '',
  },
  NOT_WORKING: {
    name: 'not-working-battle-page',
    path: '/temp',
  },
  STATISTICS: {
    name: 'statistics-battles-page',
    path: '/statistics',
  },
};
