import type { IApiCountriesItem } from '~/features/payment/types/countries/api.types';
import type { TPossibleNull } from '~/types/Shared.types';

export enum ECountryMethodField {
  DOCUMENT_NUMBER = 'doc_number',
  DOCUMENT_TYPE = 'doc_type',
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  PHONE = 'phone',
}

export enum ECountryMethodType {
  COMMON = 'common',
  SKIN = 'skins',
}

export interface ICountryButtons {
  desktop: number[];
  mobile: number[];
}

export interface ICountryCurrency {
  code: string;
  default: number;
  rate: number;
  symbol: string;
}

export interface IValidationOutput {
  masked: string;
  valid: boolean;
}

export type TValidationFunction = (value: string) => IValidationOutput;

export interface ICountryMethodFieldOption {
  label: string;
  value: string;
}

export interface ICountryMethodField {
  default: TPossibleNull<string>;
  field: ECountryMethodField;
  label: string;
  options?: ICountryMethodFieldOption[];
  required: boolean;
  validator?: TValidationFunction;
}

export interface ICountryMethod {
  fields: ICountryMethodField[];
  icon: string;
  id: number;
  min: number;
  name: string;
  payMethod: string;
  type: ECountryMethodType;
}

export interface ICountry {
  buttons: ICountryButtons;
  country: IApiCountriesItem;
  currency: ICountryCurrency;
  methods: ICountryMethod[];
}
