<template>
  <button :disabled="disabled" :class="classes" :style="styles" @click="props.onClick">
    <!-- @vue-skip -->
    <slot v-if="slots.prepend" name="prepend"></slot>
    <SharedText
      v-if="slots.default"
      as="span"
      :class="{ 'sharedButton__absolute-text': props.absoluteText }"
      :weight="FontWeights.BOLD"
      :size="props.fontSize"
      v-bind="props.textProps || {}"
    >
      <!--  @vue-ignore  -->
      <slot></slot>
    </SharedText>
    <!-- @vue-skip -->
    <slot v-if="slots.append" name="append"></slot>
  </button>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import type { ISharedButtonProps, ISharedButtonSlots } from './SharedButton.types';
import type { LinearGradientConfiguration } from '~/types/deprecated.types';
import type { IButtonBorder } from '~/types/SharedButton.types';
import { FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';

import {
  backgroundStyles,
  COMPONENT_NAME,
  sizes,
  stateWorkTypes,
  themes,
} from '~/components/SharedButton/SharedButton.data';

const GRADIENT_VAR = '--gradient-color';
const GRADIENT_HOVER_VAR = '--gradient-hover-color';
const TEXT_HOVER_VAR = '--text-hover-color';
const BORDER_RADIUS_VAR = '--btn-border-radius';
const MIN_WIDTH_LARGE_VAR = '--min-width-large';
const HOVER_BG_COLOR_VAR = '--hover-bg-color';
const BG_COLOR_VAR = '--bg-color';

const { proceedCssValue, pickTextColor } = GlobalUtils.CSS;

const slots = defineSlots<ISharedButtonSlots>();

const props = withDefaults(defineProps<ISharedButtonProps>(), {
  absoluteText: false,
  backgroundStyle: 'purple',
  disableMinimalWidth: false,
  disabled: false,
  gradient: Colors.GRADIENTS.LIGHT_RED_ORANGE,
  gradientHover: Colors.GRADIENTS.GOLD,
  isFullWidth: false,
  size: 'medium',
  stateWorkType: 'default',
  test: 'theme-dark',
  textHoverColor: Colors.ADDITIONAL.DARK_PINK,
  theme: 'primary',
});
const {
  disabled,
  size,
  fullWidth,
  event,
  color,
  border,
  height,
  width,
  fullHeight,
  radius,
  disableMinimalWidth,
  textProps,
  stateWorkType,
  theme,
  backgroundStyle,
  minWidth,
  minWidthLarge,
  hoverBgColor,
  gradient,
} = toRefs(props);

const isFullyGradient = computed(
  () =>
    hoverBgColor?.value &&
    // @ts-expect-error TODO remove or refactor
    (Array.isArray(hoverBgColor.value) || hoverBgColor.value.colorStops) &&
    color?.value &&
    // @ts-expect-error TODO remove or refactor
    (Array.isArray(color.value) || color.value.colorStops),
);

const classes = computed(() => {
  return {
    [COMPONENT_NAME]: true,
    [`${COMPONENT_NAME}--full-width`]: fullWidth.value,
    [`${COMPONENT_NAME}--full-height`]: fullHeight.value,
    [`${COMPONENT_NAME}--no-min-width`]: disableMinimalWidth.value,
    [`${COMPONENT_NAME}--bg-hovered`]: hoverBgColor?.value,
    [`${COMPONENT_NAME}--gradient-hover`]: isFullyGradient.value,
    [stateWorkTypes[stateWorkType.value]]: stateWorkType.value,
    [themes[theme.value]]: theme.value,
    [sizes[size.value]]: size.value,
    [backgroundStyles[backgroundStyle.value]]: backgroundStyle.value,
  };
});

const isTextColorApplied = computed(() => textProps?.value?.color);

const styles = computed(() => {
  const properties: CSSProperties = {};

  if (color?.value) {
    const colorsStyles = GlobalUtils.CSS.parseColor(color.value);
    if (colorsStyles.backgroundColor) properties.backgroundColor = colorsStyles.backgroundColor;
    properties.backgroundImage = colorsStyles.backgroundImage;

    if (isFullyGradient.value) properties[BG_COLOR_VAR] = colorsStyles.backgroundImage;
  }

  // Event is priority
  if (event?.value) Object.assign(properties, GlobalUtils.CSS.parseColor(Colors.EVENTS[event.value]));

  if (!isTextColorApplied.value && (event?.value || color?.value)) {
    if (properties.backgroundColor) properties.color = pickTextColor(properties.backgroundColor);
    else if (properties.backgroundImage) {
      const usedColors = (event?.value && Colors.EVENTS[event.value]) || color?.value;
      if (typeof usedColors === 'string') properties.color = pickTextColor(...usedColors.split(','));
      else if (Array.isArray(usedColors)) properties.color = pickTextColor(...usedColors);
      else properties.color = pickTextColor(...(color?.value as LinearGradientConfiguration).colorStops);
    }
  }
  if (radius?.value) properties[BORDER_RADIUS_VAR] = proceedCssValue(radius.value);
  if (gradient?.value) properties[GRADIENT_VAR] = proceedCssValue(gradient.value);
  if (props.gradientHover) properties[GRADIENT_HOVER_VAR] = proceedCssValue(props.gradientHover);
  if (props.textHoverColor) properties[TEXT_HOVER_VAR] = proceedCssValue(props.textHoverColor);
  if (height?.value) properties.height = proceedCssValue(height.value);
  if (width?.value) properties.width = proceedCssValue(width.value);
  if (minWidth?.value) properties.minWidth = proceedCssValue(minWidth.value);
  if (minWidthLarge?.value) properties[MIN_WIDTH_LARGE_VAR] = proceedCssValue(minWidthLarge.value);

  if (hoverBgColor?.value || isFullyGradient.value) {
    // @ts-expect-error TODO remove or refactor
    const { backgroundImage, backgroundColor } = GlobalUtils.CSS.parseColor(hoverBgColor.value);
    properties[HOVER_BG_COLOR_VAR] = backgroundImage !== 'none' ? backgroundImage : backgroundColor;
  }

  if (border?.value) {
    if (typeof border.value === 'string') {
      properties.border = border.value;
    } else {
      for (const borderProp in border.value) {
        const prop = borderProp as keyof IButtonBorder;
        if (prop === 'borderRadius') {
          properties[BORDER_RADIUS_VAR] = proceedCssValue(border.value[prop]);
        } else {
          properties[prop] = proceedCssValue(border.value[prop]);
        }
      }
    }
  }

  return properties;
});
</script>

<style src="./SharedButton.scss" scoped lang="scss"></style>
