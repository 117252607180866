import type {
  ICaseData,
  ILabelData,
  IPriceData,
  IPriceWithCoins,
  IlabelColorIndexable,
} from '~/features/innerOffer/types/innerOffer.types';
import { useEventStore } from '~/store/event/event.store';
import { CurrencyName } from '~/constants/currency.constants';
import type { IResponseOfferDTO } from '~/repository/modules/innerOffer/InnerOfferService.types';
import { Colors } from '~/constants/colors.constants';
import type { TCurrencyType } from '~/types/Shared.types';
import { useAuthStore } from '~/features/authentication/store/authorization';

export const useInnerOfferStore = defineStore('innerOffer', () => {
  const { $api } = useNuxtApp();

  const eventStore = useEventStore();
  const { accessToken } = storeToRefs(useAuthStore());

  const { coinIcon } = storeToRefs(eventStore);
  const { defaultCurrency } = useProjectSettings();

  const offerData = ref<IResponseOfferDTO>();
  const isGlobalOfferLoading = ref(false);
  const showModal = ref(true);

  const PRICE_ICON_SRC = 'https://s3.dev.tastyteam.cc/black/public/img/Money.svg';

  const title = ref('');
  const description = ref('');
  const headerImage = ref('');
  const offerName = ref('');
  const triggerId = ref<string | number>('');
  const offerId = ref<string | number>('');
  const triggerType = ref('');
  const caseData = ref<ICaseData[]>([]);
  const priceWithCoins = ref<IPriceWithCoins>({} as IPriceWithCoins);
  const priceData = ref<IPriceData>({} as IPriceData);
  const labelData = ref<ILabelData>({} as ILabelData);

  const isShowOffer = ref(false);

  const labelColors: IlabelColorIndexable = {
    'Новинка': Colors.TRIGGER_OFFER.NEW,
    'Обновлен': Colors.TRIGGER_OFFER.RENEWAL,
    'Скоро исчезнет': Colors.TRIGGER_OFFER.TEMPORARY,
    'Хит продаж': Colors.TRIGGER_OFFER.HIT,
  };

  watchEffect(() => {
    if (!offerData.value) return;
    priceWithCoins.value = {
      coinIcon: coinIcon.value,
      coinPrice: offerData.value.bonusCoins,
      currency: CurrencyName[defaultCurrency as TCurrencyType],
      price: offerData.value.bonusMoney,
      priceIcon: PRICE_ICON_SRC,
    };
  });

  // методы

  const setIsShowOfferStatus = (value: boolean) => {
    isShowOffer.value = value;
  };

  const resetOffer = () => {
    title.value = '';
    description.value = '';
    headerImage.value = '';
    triggerId.value = '';
    priceWithCoins.value = {} as IPriceWithCoins;
    caseData.value = [] as ICaseData[];
    labelData.value = {} as ILabelData;
    triggerType.value = '';
  };

  const setOfferData = (data: IResponseOfferDTO) => {
    offerData.value = data;

    title.value = data.title;
    description.value = data.description;
    headerImage.value = data.image;
    triggerId.value = data.triggerId;
    offerId.value = data.offerId;
    offerName.value = data.offerName;
    priceData.value = {
      oldPrice: data.oldPrice,
      price: data.price,
      sale: data.sale,
    };
    caseData.value = data.cases;

    const getLabelColor = () => {
      if (!data.label) return 'default';
      if (data.label === 'Другое') {
        return '#' + data.labelColor;
      }
      return labelColors[data.label];
    };

    labelData.value = {
      label: data.label,
      labelColor: getLabelColor(),
      labelName: data.labelName,
    };
    // пока с бэка не начнет приходить тип
    triggerType.value = data.triggerType;
  };

  const handleResponse = (data: IResponseOfferDTO | []) => {
    if (!Array.isArray(data) && data.triggerId) {
      setOfferData(data);
    } else {
      resetOffer();
    }
  };

  // получение оффера
  const getOffer = async () => {
    if (!accessToken.value) return;
    await GlobalUtils.Api.handleRequest(
      async () => {
        const data: IResponseOfferDTO | [] = await $api.innerOffer.getOffer();
        if (isShowOffer.value) return;
        handleResponse(data);
      },
      () => {},
      isGlobalOfferLoading,
    );
  };

  const getDailyCaseOffer = async (id: number) => {
    if (isShowOffer.value || !accessToken.value) return;
    await GlobalUtils.Api.handleRequest(async () => {
      const data = await $api.innerOffer.getTriggerPopupState(id);
      if (!data) return;

      handleResponse(data);
    });
  };

  return {
    caseData,
    description,
    getOffer,
    getDailyCaseOffer,
    resetOffer,
    image: headerImage,
    isShowOffer,
    labelData,
    offerId,
    offerName,
    priceData,
    priceWithCoins,
    setIsShowOfferStatus,
    title,
    triggerId,
    triggerType,
    showModal,
    isGlobalOfferLoading,
  };
});
