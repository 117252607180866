<template>
  <BaseModal v-model="isOpen" class="auth-popup">
    <GeneralAuthInstruction :buttons-amount="variants.length" />
    <GeneralAuthCheckboxes
      class="content-checkboxes"
      :is-selected-by-default="isCheckboxesSelectedByDefault"
      @is-all-checkboxes-checked="handleChecked"
    />
    <GeneralAuthButtonsGroup
      class="content-buttons"
      :variants="variants"
      :is-disabled="!isAllCheckboxesChecked"
      @init-auth="handleAuth"
    />
  </BaseModal>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';
import type { TOAuthKey } from '~/features/general-auth/components/ButtonsGroup/ButtonsGroup.types';
import { AuthEvents } from '~/repository/amplitude/events/auth';

const {
  auth: { isCheckboxesSelectedByDefault, variants },
} = useProjectSettings();

const authStore = useAuthStore();

const authPopupStore = useAuthPopupStore();
const { isOpen } = storeToRefs(authPopupStore);

const signupAmplitude = useSignupAmplitude();

const isAllCheckboxesChecked = ref(false);

const handleChecked = (value: boolean) => {
  isAllCheckboxesChecked.value = value;
};

const handleAuth = async (key: TOAuthKey) => {
  signupAmplitude.saveType(key);
  signupAmplitude.saveCreationData();

  AuthEvents.buttonClicked({
    SignUpType: key,
    Url: location.href,
  });

  await authStore.oauth(key);
};
</script>

<style lang="scss" src="./PopupController.scss" />
