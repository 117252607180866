import { volumesList } from './volumes.data';
import { volumesList as csVolumesList } from '~/project-layers/cs/store/volume/volumes.data';

import AudioProcessorService from '~/repository/extensions/audio/AudioProcessorService';

export const useVolumeStore = defineStore('global/volume', () => {
  // import
  const { $api } = useNuxtApp();

  // const audioElement = ref<HTMLAudioElement>();
  // const audioList = ref<Record<string, HTMLAudioElement>>({});
  const isMuteCookie = ref(false);

  /*
   * Есть специфический кейс, когда куки на сервере не находятся и неверно мутируется значение в куках при маунте
   * Для этого был применен такой подход
   */
  const getVolumeFromCookie = () =>
    useCookie<boolean>('Volumes', {
      sameSite: 'lax',
      default: () => false,
    });

  onMounted(() => {
    isMuteCookie.value = getVolumeFromCookie().value;
  });

  watch(getVolumeFromCookie(), (newValue) => {
    isMuteCookie.value = newValue;
  });

  // Громкость
  let VOLUME = 0.3;
  const audioContext = ref<AudioContext | undefined>();
  const audioList = ref<Record<string, AudioProcessorService>>({});

  // Функция изолирована в сторе, нужна для работы в режиме SSR
  // Позволяет создать контекст в момент, когда объект AudioContext уже доступен в WebApi
  const createAudioContext = () => {
    // Природа варна в консоли
    // Если AudioContext создан до того, как документ получил пользовательский жест, он будет создан в
    // "приостановленном" состоянии, и вам нужно будет вызвать resume() после получения пользовательского жеста.
    // Фикс звука на IOS + Chrome Web Audio Api
    // https://developer.chrome.com/blog/autoplay/
    // https://www.mattmontag.com/web/unlock-web-audio-in-safari-for-ios-and-macos#:~:text=It%20must%20be%20resumed%20(or,ringer%20is%20set%20to%20vibrate
    audioContext.value = new AudioContext();
  };

  const deleteLoadedAudio = (volumeKey: string) => {
    delete audioList.value[volumeKey];
  };

  const setAudioVolume = (volume: number = 0.3) => {
    VOLUME = volume;
  };

  // Подгрузить аудио дорожку предварительно
  const loadVolume = async (volumeKey: string) => {
    const { game } = useProjectSettings();
    const parsedVolumeList = game === 'cs' ? { ...volumesList, ...csVolumesList } : volumesList;

    if (audioList.value[volumeKey] || !(volumeKey in parsedVolumeList)) return;

    const volume = parsedVolumeList[volumeKey as keyof typeof parsedVolumeList];
    const volumeParsed = await volume();
    const data = await $api.audio.getAudioBuffer(volumeParsed);

    if (!audioContext.value) createAudioContext();
    if (!isMuteCookie.value) isMuteCookie.value = false;

    const audio = new AudioProcessorService(audioContext.value!, VOLUME, isMuteCookie.value);
    await audio.createAudio(data);

    audioList.value[volumeKey] = audio;
  };

  // Воспроизводим звук из объекта заготовленных заранее звуков
  const playVolume = (volumeKey: string) => {
    if (volumeKey in audioList.value) {
      audioList.value[volumeKey].play();
    }
  };

  const updateMuteStatus = () => {
    Object.keys(audioList.value).forEach((key) => {
      audioList.value[key].toggleMute(isMuteCookie.value);
    });
  };

  // Приглушить звук
  // Записывает в куки и приглушает на всех аудио дорожках
  const toggleMute = () => {
    isMuteCookie.value = !isMuteCookie.value;
    getVolumeFromCookie().value = isMuteCookie.value;
  };

  watch(isMuteCookie, updateMuteStatus);

  return {
    deleteLoadedAudio,
    isMuteCookie,
    loadVolume,
    playVolume,
    setAudioVolume,
    toggleMute,
  };
});
