import type { TApiPromo, IApiErrorPromo } from './api.types';
import { EPromoTypes, type TPromo, type IErrorPromo } from './client.types';

export const transform = (apiData: TApiPromo | IApiErrorPromo): TPromo | IErrorPromo => {
  if ('success' in apiData) return { message: apiData.message };

  if (apiData.type === 'instant') {
    return {
      sum: apiData.sum,
      type: EPromoTypes.INSTANT,
    };
  }

  if (apiData.type === 'common') {
    return {
      percent: apiData.percent,
      type: EPromoTypes.COMMON,
    };
  }

  return {
    offers: apiData.flexOffers.map((item) => ({
      case: item.case
        ? {
            image: item.case.img,
            name: item.case.name,
            price: item.case.price,
          }
        : undefined,
      coins: item.tcoinFactor,
      from: item.priceFrom,
      percent: item.moneyFactor - 100,
    })),
    type: EPromoTypes.BLOGGER,
  };
};
