export enum EAuthEvents {
  ACCOUNT_CREATED = 'Signup - Account Created',
  BUTTON_CLICKED = 'Signup - Button Clicked',
  ENTRY_CLICKED = 'Signup - Entry Clicked',
  LOGIN = 'Login - Authorization',
}

export enum EAuthButtonPlaceClicked {
  HEADER = 'header',
  BP = 'BP',
  FREE_CASES_BLOCK = 'Free Cases Block',
  FOOTER = 'footer',
  CASE = 'case',
  TECHIES = 'Techies',
  BATTLE = 'Battle',
  QUIZ = 'Quiz',
  PAYMENT = 'payment',
  PROVABLY_FAIR = 'PF Page',
}

export interface IAuthAccountCreatedEvent {
  'Url': string;
  'Utm full': string;
  'signupType': string;
  'utm_campaign': string;
  'utm_content': string;
  'utm_medium': string;
  'utm_source': string;
  'utm_term': string;
}

export interface IAuthAccountCreatedUser {
  'User ID': number;
  'signupDate': string;
  'signupType': string;
  'signupUtm': string;
}

export interface IAuthButtonClickedEvent {
  SignUpType: string;
  Url: string;
}

export interface IAuthEntryClickedEvent {
  'Button Place': EAuthButtonPlaceClicked;
  'Url': string;
}

export interface IAuthLoginEvent {
  Url: string;
  signupType: string;
}

export interface IAuthLoginUser {
  'User ID': number;
}
