import {
  EInnerOfferEvents,
  type IInnerOfferButtonClickedEvent,
  type IInnerOfferPopupShownEvent,
} from '../types/innerOffer.types';

export class InnerOfferEvents {
  static buttonClicked(event: IInnerOfferButtonClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EInnerOfferEvents.BUTTON_CLICKED,
      eventProperties: event,
    });
  }

  static popupShown(event: IInnerOfferPopupShownEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EInnerOfferEvents.POPUP_SHOWN,
      eventProperties: event,
    });
  }
}
