import { TIMES } from '~/constants/times.constants';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useUserStore } from '~/store/user/user.store';
import type { UTMParams } from '~/types/utm.types';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    const authStore = useAuthStore();
    if (!authStore.isAuth) return;

    const { $utm } = useNuxtApp();
    const userStore = useUserStore();

    const now = Date.now();
    const [validUtm] = $utm.value.filter((utm) => {
      const utmTime = new Date(utm.timestamp).getTime();
      return now - utmTime < TIMES.MONTH;
    });

    if (!validUtm) return;

    const queryData = [];
    let key: keyof UTMParams;
    for (key in validUtm.utmParams) {
      if (!validUtm.utmParams[key]) continue;
      queryData.push(`${key}=${validUtm.utmParams[key]}`);
    }

    const { origin, pathname } = location;
    const fullUrl = `${origin}${pathname}?${queryData.join('&')}`;

    userStore.postUserSession(fullUrl);
  });
});
