import { skipHydrate } from 'pinia';
import type { IBattlesShortInfoBattleEntity } from '~/repository/modules/battles';
import { EBattleStatuses } from '~/repository/modules/battles';
import { EBattlesStoreNames } from '~/features/battles/constants/general';

import type { IBattlesUserStatusInDataStore } from '~/features/battles/types/battlesStore.types';

/* Хранилище состояний различных данных необходимых для работы фичи батлов */
export const useBattlesDataStateStore = defineStore(EBattlesStoreNames.ALL_STATE, () => {
  /* -- Imports -- */
  /* Импорт апи */
  const {
    $api: { battles: BattlesApiService },
  } = useNuxtApp();

  /* -- Initialisation -- */
  /* Общий стейт фичи батлов */
  const dataState = ref({
    areSectionsLoaded: false,
    areSocketsSubscribed: false,
    battlesRendered: false,
    isAuthNeeded: false,
    isPageChanging: false,
  });

  /* Стейт пользователя на батлах */
  const myState = ref<IBattlesUserStatusInDataStore>({
    activeBattleUlid: null,
    fetched: false,
    inBattle: false,
  });

  /* -- Actions -- */
  const changeDataState = (stateKey: keyof typeof dataState.value, newValue: boolean) => {
    dataState.value[stateKey] !== undefined && (dataState.value[stateKey] = newValue);
  };

  const fetchUserStatus = async () => {
    myState.value.fetched = false;
    try {
      const result = await BattlesApiService.getUserStatus();
      Object.assign(myState.value, result);
    } catch {
    } finally {
      myState.value.fetched = true;
    }
  };

  /* Обработка сокетного события для контроля состояния пользователя */
  function handleUserStatusOnBattlesUpdate(data: { battle: IBattlesShortInfoBattleEntity }) {
    if (!data || !data.battle) return;

    const { battle } = data;

    const isFinished = battle.status === EBattleStatuses.FINISHED;
    const isTheSameBattle = battle.ulid === myState.value.activeBattleUlid;

    /* Если батл закончился, это батл, в котором участвовал "я" и "я" состою в батле на данный момент,
     * То нужно убрать состояние, так как батл закончен
     */
    if (myState.value.inBattle && isTheSameBattle && isFinished) {
      myState.value.inBattle = false;
      myState.value.activeBattleUlid = null;
    }
  }

  /* Функция очистки стора */
  const $reset = () => {
    Object.keys(dataState.value).forEach((key) => {
      changeDataState(key as keyof typeof dataState.value, false);
    });

    Object.assign(myState.value, {
      activeBattleUlid: null,
      fetched: false,
      inBattle: false,
    } as IBattlesUserStatusInDataStore);
  };

  /* -- Returns -- */
  return {
    $reset,
    changeDataState,
    dataState: skipHydrate(dataState),
    fetchUserStatus,
    handleUserStatusOnBattlesUpdate,
    myState: skipHydrate(myState),
  };
});
