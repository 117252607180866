import LocalizationRepository from '~/features/localization/api/repository';
import type { ILocalizationLanguage } from '~/server/services/localization/localization.types';
import { useAuthStore } from '~/features/authentication/store/authorization';

export const LOCALE_COOKIE = 'detected-locale';
export const IS_LOCALE_CHANGED = 'locale-changed';

export const useLocalizationStore = defineStore('localization', () => {
  const { $api } = useNuxtApp();
  const switchLocalePath = useSwitchLocalePath();

  const authStore = useAuthStore();

  const locales = ref<ILocalizationLanguage[]>([]);
  const currentLocale = ref<ILocalizationLanguage>();

  const localeCookie = useCookie(LOCALE_COOKIE);
  const isLocaleChangedCookie = useCookie(IS_LOCALE_CHANGED);

  const error = useError();
  const delayedUpdateLocale = ref<string>();

  const fetchLocales = async () => {
    if (
      isLocaleChangedCookie.value &&
      `${isLocaleChangedCookie.value}` === '1' &&
      localeCookie.value &&
      authStore.isAuth
    ) {
      $api.localization.saveUserLocale(localeCookie.value);
      isLocaleChangedCookie.value = '0';
    }

    // Fetch iso by IP
    const data = await LocalizationRepository.getLocalization();
    if (!data) return;

    locales.value = data.languages;

    if (error.value) {
      delayedUpdateLocale.value = data.defaultIso;
      return;
    }
    await setLocaleFromCookie(data.defaultIso);
  };

  const setNewLocale = (iso: string) => {
    localeCookie.value = iso;
    const newPath = switchLocalePath(iso);
    reloadNuxtApp({ path: newPath });
  };

  const changeLocale = (locale: ILocalizationLanguage) => {
    isLocaleChangedCookie.value = '1';
    setNewLocale(locale.iso);
  };

  const setLocaleFromCookie = async (defaultIso: string) => {
    if (!locales.value.length) return;

    if (authStore.isAuth && !localeCookie.value) {
      try {
        const userLocaleResponse = await $api.localization.getUserLocale();
        const foundLocale = locales.value.find((c) => c.iso === userLocaleResponse.data.language);
        if (foundLocale) {
          currentLocale.value = foundLocale;
          setNewLocale(currentLocale.value.iso);
          return;
        }
      } catch {}
    }

    const foundLocale = locales.value.find((c) => c.iso === localeCookie.value);
    if (foundLocale) {
      currentLocale.value = foundLocale;

      const newPath = switchLocalePath(foundLocale.iso);
      navigateTo(newPath);
      return;
    }

    const foundDefaultLocale = locales.value.find((c) => c.iso === defaultIso);
    currentLocale.value = foundDefaultLocale;
    if (!currentLocale.value) return;

    setNewLocale(currentLocale.value.iso);
  };

  watch(
    error,
    async (newError) => {
      if (newError) return;

      if (delayedUpdateLocale.value) {
        await setLocaleFromCookie(delayedUpdateLocale.value);
        delayedUpdateLocale.value = undefined;
      }
    },
    {
      immediate: true,
      deep: true,
    },
  );

  return {
    changeLocale,
    currentLocale,
    fetchLocales,
    localeCookie,
    locales,
  };
});
