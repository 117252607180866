export const useClickOutside = (element: HTMLElement, cb: () => void, removeListener = true) => {
  const clickOutside = (event: Event) => {
    event.stopPropagation();
    const wrapper = element?.parentNode || '';

    if (wrapper && !wrapper.contains(event.target as Node)) {
      cb();

      if (removeListener) {
        document.removeEventListener('click', clickOutside);
      }
    }
  };
  document.addEventListener('click', clickOutside);

  const remove = () => {
    document.removeEventListener('click', clickOutside);
  };

  return {
    remove,
  };
};
