import type { ComputedRef } from 'vue';
import { Breakpoints } from '../constants/media.constants';
import type { TBreakpoints } from '~/types/Shared.types';

export type TReturnUseMedia = Record<TBreakpoints, ComputedRef<boolean>>;

/**
 * Возвращает объект брейкпоинтов в виде реактивных значений
 * @param {"down" | "up"} mediaDirection - Направление адаптивности (mobile-first или desktop-first)
 * @returns TReturnUseMedia
 */
export const useMedia = (mediaDirection: 'down' | 'up' = 'up'): TReturnUseMedia => {
  const viewport = useViewport();

  const direction = mediaDirection === 'up' ? 'isGreaterOrEquals' : 'isLessThan';

  return (Object.keys(Breakpoints) as Array<TBreakpoints>).reduce((acc: TReturnUseMedia, key: TBreakpoints) => {
    acc[key] = computed(() => viewport[direction](key));
    return acc;
  }, {} as TReturnUseMedia);
};
