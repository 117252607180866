import FaqHttp from './FaqHttp';
import type { ICategories } from '~/features/faq/types/faq.types';

export default class FaqService {
  private readonly faqHttp: FaqHttp;

  constructor() {
    this.faqHttp = new FaqHttp();
  }

  public async getCategory(): Promise<ICategories> {
    const response = await this.faqHttp.getCategory<ICategories>();

    if (!response) throw new Error('Error');

    return response;
  }
}
