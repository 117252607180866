export const PAYMENT_ROUTES = {
  MAIN: {
    name: 'payment',
    path: '',
  },
  RESULT: {
    name: 'payment-result',
    path: '/result',
  },
  UNAUTH: {
    name: 'payment-unauth',
    path: '/unauth',
  },
};
