/* /payments + PaymentRoutesApi[...][...] */
export const PaymentRoutesApi = {
  GET: {
    BONUSES: '/bonus/levels',
    COUNTRIES: '/countries',
    PAYMENT: '/transaction/status',
    PROMO: '/promo/info',
  },
  POST: {
    APPLY: '/promo/apply',
    PAYMENT: '/transaction/start',
  },
} as const;
