import { useAuthStore } from '~/features/authentication/store/authorization';

export default defineNuxtRouteMiddleware(() => {
  const authStore = useAuthStore();
  const { isAuth } = storeToRefs(authStore);

  if (isAuth.value) return;

  return navigateTo(useLocaleRoute()(ROUTING.PROVABLY_FAIR.INSTRUCTIONS));
});
