export const languageIsoData = {
  az: 'az-AZ',
  en: 'en-EN',
  es: 'es-ES',
  hi: 'hi-HI',
  kk: 'kk-KK',
  ru: 'ru-RU',
  uz: 'uz-UZ',
} as const;

export const languageIsoDataForBk = Object.entries(languageIsoData)
  .filter(([key]) => !['es', 'uz'].includes(key))
  .reduce((acc: Record<string, string>, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
