export const OPEN_CASE_ROUTES = {
  DROPPAGE: {
    getDynamicPath: (caseName: string, gameId: string) => `/${caseName}/${gameId}`,
    name: 'drop-page',
    path: '/open/:caseName/:gameId',
  },
  OPENPAGE: {
    getDynamicPath: (caseName: string) => `/${caseName}`,
    name: 'open-page',
    path: '/open/:caseName',
  },
  DAILYCASES: {
    name: 'daily-cases',
    path: '/daily_cases',
  },
};
