import InventoryUtils from './inventory.utils';
import TextUtils from '~/features/bk/utils/text.utils';

const BkUtils = {
  ...TextUtils,
  ...InventoryUtils,
};

export const DEFAULT_INVENTORY = {
  countItems: 0,
  countPages: 0,
  items: [],
  totalAmount: 0,
  user: undefined,
};

export default BkUtils;
