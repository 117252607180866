export const FOOTER_PAGES_ROUTES = {
  EVENT_CONDITION: {
    name: 'event-condition',
    path: '/eventCondition',
  },
  PRIVACY_POLICY: {
    name: 'privacy-policy',
    path: '/privacy-policy',
  },
  COOKIES: {
    name: 'cookies',
    path: '/cookies',
  },
  CONTACTS: {
    name: 'contacts',
    path: '/contacts',
  },
  USER_AGREEMENT: {
    name: 'user-agreement',
    path: '/userAgreement',
  },
};
