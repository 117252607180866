<template>
  <div class="instruction">
    <h2 class="instruction__title">
      {{ $t('generalAuth.instruction.title') }}
    </h2>
    <p class="instruction__description">
      {{ $t(textKey) }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import type { IInstructionProps } from './Instruction.types';

const props = defineProps<IInstructionProps>();
const { buttonsAmount } = toRefs(props);

const textKey = computed(() =>
  buttonsAmount.value === 1 ? 'generalAuth.instruction.one' : 'generalAuth.instruction.many',
);
</script>

<style scoped lang="scss" src="./Instruction.scss" />
