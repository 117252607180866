export const BattlesPlayersAmount = {
  max: 4,
  min: 2,
};

export const BattlesSpinnerTimeouts = {
  // время на анимацию от остановки в рандомном месте до выравнивания по центру
  afterStopInRandomPlace: 500,
  // время от выравнивания по центру до конца показа дропа (с учетом съезжания вниз)
  animationDropShow: 2000,
  // время анимации кружочка раунда, бесконечное вращение
  animationRoundCircle: 4000,
  // время анимации прокрута цифры внутри кружочка раунда
  animationRoundCircleNumber: 400,
  // анимация исчезновения кейсов в линии кейсов при раунде
  changeCaseAnimation: 500,
  debounceTime: 500,
  // дефолтная задержка, чтобы например чуть раньше запустить анимацию
  defaultDelay: 100,

  defaultSpinner: 2000,

  dropShow: 0,

  // время на увеличение в размере блока с дропами
  growDropsGroup: 300,

  // время анимации увеличения уменьшения кружка в конце раунда
  growShrinkEndRoundCircle: 400,

  // время, в течение которого не изменяется кружок в конце последнего раунда
  noChangeLastRoundCircle: 1200,

  // время, в течение которого не изменяется кружок в конце раунда
  noChangeRoundCircle: 600,

  roundEndAnimation: 1000,

  // сдвиг между стартом соседних рулеток для рассинхрона
  timeShiftCarousels: 200,
};

export const BattleDropsTimeouts = {
  dropDownInInventory: 4400,
};

export const BattlesMulticastTimeouts = {
  allTime: 5700, // время для всего цикла анимации (колесо + веер)
  circleTime: 3000, // время анимации колеса фортуны
  differenceForLastRoundMulti: 300, // небольшая разность в последнем раунде мультикаста и инвентаря
  fanTime: 3300, // время анимации веера
  fanTimeAll: 2000, // полный веер
  timeForDropInventory: 5300, // хз ваще
};

export const BattlesBattleEndTimeouts = {
  // время анимации увеличения уменьшения кружка в конце раунда
  growShrinkEndRoundCircle: 400,

  // время, в течение которого не изменяется кружок в конце раунда
  noChangeRoundCircle: 600,
};

export const EmodjiDelays = {
  asyncSend: 750,
  syncClient: 250,
};
