import { TIMES } from '../constants/times.constants';

const getDiffInMinutes = (date: number) => {
  const timeDif = Math.abs(new Date(date).getTime() - new Date().getTime());
  return Math.round(timeDif / TIMES.MINUTE);
};

export default {
  Date: {
    getDiffInMinutes,
  },
};
