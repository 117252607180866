import { storeToRefs } from 'pinia';
import type { TPossibleNull } from '~/types/Shared.types';
import { useUserStore } from '~/store/user/user.store';
import type { IBattlesCasesSectionEntity } from '~/repository/modules/battles';
import { EBattlesStoreNames } from '~/features/battles/constants/general';
import { useBattlesSectionsStore } from '~/features/battles/store/sections.store';

/* Хранилище фильтров кейсов при создании батла */
export const useBattlesCasesFilterStore = defineStore(EBattlesStoreNames.CASES_FILTER, () => {
  /* -- Imports -- */
  const userStore = useUserStore();
  const sectionsStore = useBattlesSectionsStore();

  /* -- Initialisation -- */
  /* Границы цен кейсов (минимальная и максимальная цены) */
  const priceLimits = reactive({
    maxCasePrice: 10000,
    minCasePrice: 0,
  });
  /* Состояние фильтра */
  const state = reactive({
    onlyChosenCases: false,
    onlyEnoughMoneyCases: false,
    priceFrom: priceLimits.minCasePrice,
    priceTo: priceLimits.maxCasePrice,
    searchQuery: '',
    selectedSection: null as TPossibleNull<IBattlesCasesSectionEntity>,
  });
  /* Объект данных польтзователя */
  const { user } = storeToRefs(userStore);

  /* -- Methods -- */
  /* Функция обновления выбора в селекторе (по нажатию на ту же опцию - убирается селект) */
  const updateSelection = (item: IBattlesCasesSectionEntity) => {
    state.selectedSection = state.selectedSection?.id === item.id ? null : item;
  };

  /* Функция очистки состояния фильтра */
  const clearState = () => {
    state.onlyChosenCases = false;
    state.onlyEnoughMoneyCases = false;
    state.priceFrom = priceLimits.minCasePrice;
    state.priceTo = priceLimits.maxCasePrice;
    state.searchQuery = '';
    state.selectedSection = null;
  };

  /* Функция очистки выбора секции */
  const clearSection = () => {
    state.selectedSection = null;
  };

  /* -- Side Effects -- */
  /* При получении секций выставляем нижнюю и верхнюю цены */
  watch(
    () => sectionsStore.sectionsCases,
    () => {
      if (!sectionsStore.sectionsCases.size) return;

      let firstWritten = false;
      sectionsStore.sectionsCases.forEach((singleCase) => {
        if (!firstWritten) {
          priceLimits.minCasePrice = priceLimits.maxCasePrice = singleCase.price;
          state.priceFrom = state.priceTo = singleCase.price;
          firstWritten = true;
        }
        if (singleCase.price < priceLimits.minCasePrice) {
          priceLimits.minCasePrice = state.priceFrom = singleCase.price;
        }
        if (singleCase.price > priceLimits.maxCasePrice) {
          priceLimits.maxCasePrice = state.priceTo = singleCase.price;
        }
      });
    },
    { immediate: true },
  );

  /* Если выбрана фильтрация по доступным по балансу кейсам - установить priceTo на уровень баланса */
  watch(
    () => state.onlyEnoughMoneyCases,
    (isChecked) => {
      state.priceTo = isChecked ? +(user.value?.finance.balance ?? 0) : priceLimits.maxCasePrice;
    },
  );

  /* -- Returns -- */
  return {
    clearSection,
    clearState,
    priceLimits,
    state,
    updateSelection,
  };
});
