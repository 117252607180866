import { EInventoryTakeStates, type TPagesInventory } from '~/repository/modules/bk/bk.types';
import type { TStatuses } from '~/types/cases/status';

/**
 * Возвращает дефолтное значение для объекта с информацией о страницах инвентаря
 */
function setInventoryPagesData(): TPagesInventory {
  return { base: { current: 0, total: 0 }, second: { current: 0, total: 0 } };
}

function getTakeItemStateSetByStatus(status: TStatuses): EInventoryTakeStates {
  switch (status) {
    case 'send':
      return EInventoryTakeStates.AWAITING_SELLER; // Ждем продавца
    case 'completed':
      return EInventoryTakeStates.AWAITING_TAKING; // Получить
    default:
      return EInventoryTakeStates.PREPARING_ITEM; // Готовим предмет
  }
}

export default {
  Inventory: {
    getTakeItemStateSetByStatus,
    setInventoryPagesData,
  },
};
