<template>
  <span v-if="$slots.currency" :class="additionalClasses">
    <slot name="currency" :currency="currentCurrency"></slot>
  </span>
  <span v-else :class="additionalClasses">{{ currentCurrency }}</span>
</template>

<script setup lang="ts">
import { Currency } from '~/constants/currency.constants';
import type { TCurrencyType } from '~/types/Shared.types';
import type {
  ISharedPrettyCurrencyProps,
  ISharedPrettyCurrencySlots,
} from '~/components/SharedPrettyCurrency/SharedPrettyCurrency.types';

const props = defineProps<ISharedPrettyCurrencyProps>();
defineSlots<ISharedPrettyCurrencySlots>();

/** Получаем ключ для определиня валюты
 * Возвращаем символ
 * P.S при наличии уникального символа отображаем его
 * */
const currentCurrency = computed<(typeof Currency)[TCurrencyType] | string>(() => {
  if (props.isFake) return useDefaultCurrency(props.isFake);
  if (typeof props.customCurrency === 'string' && props.customCurrency) return props.customCurrency;
  if (!props.currency) return useDefaultCurrency(props.isFake);
  else return Currency[props.currency];
});

/** Добавить класс для символа
 * Например, символ DIAMOND распалагется ниже, чем сумма, для этого модифицируем высоту классом
 * */

const additionalClasses = computed<Record<string, boolean>>(() => {
  const isMacintosh =
    import.meta.client && (navigator.platform?.indexOf('Mac') === 0 || navigator.platform === 'iPhone');
  return {
    diamond: currentCurrency.value === Currency.DIAMOND && !isMacintosh,
  };
});
</script>

<style src="./SharedPrettyCurrency.scss" lang="scss" scoped></style>
