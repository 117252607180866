import { AuthApiSample } from '~/api/global/samples/AuthApi';
import type { TApi } from '~/api/global/api.types';
import { ApiSample } from '~/api/global/samples/ApiSample';
import { OldApiSample } from '~/api/global/samples/OldApiSample';
import QueryService from '~/api/global/query/QueryService';
import { DevApiSample } from '~/api/global/samples/DevApiSample';
import { BkApiSample } from '~/api/global/samples/BkApiSample';

/* Апи-провайдер класс, для манипуляции различными сущностями Апи модулей со своими хедерами */
export default class ApiHttp {
  protected readonly $api: TApi;
  protected readonly $authApi: TApi;
  protected readonly $oldApi: TApi;
  protected readonly $devApi: TApi;
  protected readonly $bkApi: TApi;
  protected readonly $bkSecondApi: TApi;
  protected readonly query: QueryService;

  constructor() {
    this.$api = new ApiSample().$api;
    this.$authApi = new AuthApiSample().$authApi;
    this.$oldApi = new OldApiSample().$oldApi;
    this.$devApi = new DevApiSample().$devApi;
    this.$bkApi = new BkApiSample().$bkApi;
    this.$bkSecondApi = new BkApiSample().$bkSecondApi;
    this.query = new QueryService();
  }
}
