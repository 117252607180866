import { defineStore } from 'pinia';
import type { IGlobalErrorData } from '~/types/interface';

export const useGlobalErrors = defineStore('global/error', () => {
  const errorData = ref<IGlobalErrorData | null>();

  const setErrorData = (data: IGlobalErrorData | null) => {
    errorData.value = data;
  };

  return {
    errorData,
    setErrorData,
  };
});
