import type { TStateWorkType, TTheme, TBackgroundStyle } from '~/types/SharedButton.types';
import type { TListTypes, TSizes } from '~/types/Shared.types';

export const COMPONENT_NAME = 'sharedButton';

export const stateWorkTypes: TListTypes<TStateWorkType> = {
  default: `${COMPONENT_NAME}--work-type--default`,
};

export const backgroundStyles: TListTypes<TBackgroundStyle> = {
  blue: `${COMPONENT_NAME}--blue`,
  gold: `${COMPONENT_NAME}--gold`,
  green: `${COMPONENT_NAME}--green`,
  purple: `${COMPONENT_NAME}--purple`,
};

export const themes: TListTypes<TTheme> = {
  disorded: 'disorded',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
};

export const sizes: TListTypes<TSizes> = {
  large: `${COMPONENT_NAME}--large`,
  medium: `${COMPONENT_NAME}--medium`,
  small: `${COMPONENT_NAME}--small`,
  xs: `${COMPONENT_NAME}--xs`,
};
