export enum EBattleEvents {
  BATTLE_COMPLETED = 'Battle - Battle Completed',
  BATTLE_CREATED = 'Battle - Battle Created',
  BATTLE_CREATOR_OPEN = 'Battle - Battle Creator Opened',
  EARLY_BOTS_GAME_STARTED = 'Battle - Early Bots Game Started',
  EARLY_BOTS_GAME_SUGGESTED = 'Battle - Early Bots Game Suggested',
  EARLY_GAME_STARTED = 'Battle - Early Game Started',
  EARLY_GAME_SUGGESTED = ' Battle - Early Game Suggested',
  PARTICIPANT_ENTERED = ' Battle - Participant Entered',
  PARTICIPANT_LEFT = 'Battle - Participant Left',
  SAME_BATTLE_CREATED = 'Battle - Same Battle Created',
  SUGGESTION_CANCELED = 'Battle - Suggestion Canceled',
  VIEWER_ENTERED = 'Battle - Viewer Entered',
}

export interface IBattleCompletedEvent {
  'Battle Price': number;
  'Battle Size': string;
  'Bot Count': string;
  'Is Host': 'yes' | 'no';
  'Is Private': 'yes' | 'no';
  'Is Profit'?: 'yes' | 'no';
  'Is Won': 'yes' | 'no';
  'Players Count': string;
  'Rounds': number;
}

export interface IBattleCreatedEvent {
  'Battle Price': number;
  'Battle Size': string;
  'Button Place': string;
  'Is Bot': 'yes' | 'no';
  'Is Private': 'yes' | 'no';
  'Rounds': number;
}

export interface IBattleParticipantEnteredEvent {
  'Battle Price': number;
  'Rounds': number;
}

export interface IBattleParticipantLeftEvent {
  'Battle Price': number;
}

export interface IBattleSameBattleCreatedEvent {
  'Button Place': string;
}
