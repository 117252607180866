import { $api } from '~/api/global/ApiProvider';
import type { ISeoApi } from '~/features/seo/types/api.types';

export default class SeoRepository {
  public static async getSeo(url: string) {
    const formData = new FormData();
    formData.append('url', url);

    const { data, status } = await $api.post<ISeoApi>('/v1/seo/get', {
      body: formData,
    });

    if (!data || !status) return null;

    return 'id' in data ? data : null;
  }
}
