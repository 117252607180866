<template>
  <section class="shared-kit-price">
    <div v-if="variant.type === ESharedKitPriceTypes.MONEY" class="shared-kit-price__current">
      <template v-if="variant.price">
        {{ variant.price }}
        <UserController>
          <template #default="{ isFake }">
            <SharedPrettyCurrency :is-fake="isFake" :currency="variant.currency" />
          </template>
        </UserController>
      </template>
      <template v-else> {{ $t('forFree') }}</template>
    </div>
    <div v-if="variant.type === ESharedKitPriceTypes.MONEY && variant.oldPrice" class="shared-kit-price__old">
      {{ variant.oldPrice }}
      <UserController>
        <template #default="{ isFake }">
          <SharedPrettyCurrency :is-fake="isFake" :currency="variant.currency" />
        </template>
      </UserController>
    </div>
    <div v-if="variant.type === ESharedKitPriceTypes.COINS" class="shared-kit-price__coins">
      <NuxtImg v-if="variant.icon" :src="variant.icon" :width="iconSize" :height="iconSize" />
      {{ variant.amount }}
    </div>
  </section>
</template>

<script setup lang="ts">
import { ESharedKitPriceTypes, type ISharedKitPriceProps } from './SharedKitPrice.types';

const props = defineProps<ISharedKitPriceProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const iconSize = computed(() => changeXL(20, 12));

const styleVariables = computed(() => ({
  color:
    props.variant.type === ESharedKitPriceTypes.COINS ? GlobalUtils.CSS.getBackgroundColor(props.variant.color) : '',
}));
</script>

<style scoped lang="scss">
.shared-kit-price {
  --coin-color: v-bind('styleVariables.color');
}
</style>

<style scoped lang="scss" src="./SharedKitPrice.scss" />
