// eslint-disable-next-line
const throttleFn = <F extends (...args: any[]) => any>(func: F, waitFor: number = 250) => {
  let timeout: ReturnType<typeof setTimeout>;
  let resolved = false;

  return (...args: Parameters<F>) =>
    new Promise((resolve) => {
      if (timeout) {
        if (!resolved) return;
        if (resolved) {
          clearTimeout(timeout);
          resolved = false;
        }
      }

      func(...args);

      timeout = setTimeout(() => {
        resolved = true;
        resolve(resolved);
      }, waitFor);
    });
};

// eslint-disable-next-line
function debounceFn<F extends (...args: any[]) => any>(func: F, ms: number = 250) {
  let timeout: ReturnType<typeof setTimeout>;
  return function (...args: Parameters<F>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), ms);
  };
}

const delay = (ms: number = 5000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export default {
  Functions: {
    debounceFn,
    delay,
    throttleFn,
  },
};
