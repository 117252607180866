// Ключи из query/params
import type { IUrlOptions, TValidators } from '~/features/bk/types/integrator.types';
import { Currency } from '~/constants/currency.constants';
import { languageIsoDataForBk } from '~/repository/extensions/i18n/data';
import type { IBkInventory, IBkInventoryItem, IDemoItem, IInventoryItem } from '~/repository/modules/bk/bk.types';
import { useBkGame } from '~/features/bk/composables/useBkGame';
import type { TCurrencyType } from '~/types/Shared.types';

// Обязательные параметры в квери url
const requiredUrlPropertiesKey: (keyof IUrlOptions)[] = ['auth-token', 'currencyIso', 'isDemo', 'languageIso'];

// валидатор для ключей в query
const validatorsQuery: TValidators = {
  currencyIso: {
    handler: (val) => {
      return !!Currency[val as keyof typeof Currency];
    },
  },
  isDemo: {
    handler: (val) => {
      return val === 'true' || val === 'false';
    },
    transform: <T>(val: T) => val === 'true',
  },
  languageIso: {
    handler: () => true,
    transform: <T>(val: T) => languageIsoDataForBk[val as keyof typeof languageIsoDataForBk],
  },
};

// Обязательные параметры внутри пути урл
const requiredUrlPropertiesPathKey: (keyof IUrlOptions)[] = ['integratorUuid', 'caseName'];

const getBkHttp = (item: IInventoryItem): 'bk' | 'bkSecond' => {
  /** Является ли предмет кс **/
  return item.weapon ? 'bkSecond' : 'bk';
};

// В зависимости от игры и принадлежности предмета инвентаря к игре определяем куда будем отправлять запрос
const getBkHttpByCurrentGame = (item: IInventoryItem): 'bk' | 'bkSecond' => {
  const { isDotaGame, isCsGame } = useBkGame();

  const isDotaItem = getBkHttp(item) === 'bk';
  const isCsItem = getBkHttp(item) === 'bkSecond';

  return (isDotaGame && isDotaItem) || (isCsGame && isCsItem) ? 'bk' : 'bkSecond';
};

const transformTo = (
  data: IBkInventory<IBkInventoryItem>,
  isOld: boolean,
  currency: TCurrencyType,
  tradeOfferMap: Ref<Map<number, number>>,
): IBkInventory<IInventoryItem> => {
  const items: IDemoItem[] = [];

  data.items.forEach((item) => {
    if (item.offerId) tradeOfferMap.value.set(+item.id, item.offerId);
    const obj = {
      ...item,
      datetime: item.datetime || '',
      id: item.id,
      imageData: {
        image: item.image,
      },
      inactive: false,
      isOld,
      isUltraRare: item.isUltraRare,
      name: item.name,
      offerData: {
        countdown: item.countdown,
        currency,
        price: item.price,
      },
      offerId: item.offerId,
      priceData: {
        currency,
        marketPrice: String(item.marketPrice),
        price: item.price,
      },
      qualityEnum: {
        name: item.quality,
      },
      status: item.status || 'active',
      taken: true,
      tastyCoins: null,
      timeUntilSending: item.timeUntilSending || 0,
    };
    items.push(obj);
  });

  return { ...data, items } as IBkInventory<IInventoryItem>;
};

export {
  requiredUrlPropertiesPathKey,
  requiredUrlPropertiesKey,
  validatorsQuery,
  getBkHttp,
  getBkHttpByCurrentGame,
  transformTo,
};
