export const enum GapSizes {
  L = '24px',
  M = '20px',
  S = '16px',
  XL = '32px',
  XL2 = '40px',
  XL3 = '48px',
  XL4 = '64px',
  XL5 = '80px',
  XL6 = '88px',
  XS = '10px',
  XS2 = '8px',
  XS3 = '6px',
  XS4 = '4px',
  XS5 = '2px',
  XSS = '12px',
}
