export enum EPromoTypes {
  BLOGGER = 'blogger',
  COMMON = 'common',
  INSTANT = 'instant',
}

export interface IInstantPromo {
  sum: number;
  type: EPromoTypes.INSTANT;
}

export interface ICommonPromo {
  percent: number;
  type: EPromoTypes.COMMON;
}

export interface IBloggerOfferCase {
  image: string;
  name: string;
  price: number;
}

export interface IBloggerOffer {
  case?: IBloggerOfferCase;
  coins: number;
  from: number;
  percent: number;
}

export interface IBloggerPromo {
  offers: IBloggerOffer[];
  type: EPromoTypes.BLOGGER;
}

export interface IErrorPromo {
  message: string;
}

export type TPromo = ICommonPromo | IBloggerPromo | IInstantPromo;
