import type { LocationQuery } from '#vue-router';

export const useUTM = ({ query: externalQuery }: { query?: LocationQuery } = {}) => {
  const queryCookie = useCookie<Record<string, unknown>>('saved-query', {
    default: () => ({}),
  });

  const queryValue = computed<Record<string, unknown>>(() => {
    return typeof queryCookie.value === 'object' ? queryCookie.value : {};
  });

  const saveQuery = () => {
    const query = externalQuery ?? useRoute().query;

    if (Object.keys(query).length === 0) return;

    const parsedQuery = {} as Record<string, string>;
    for (const [key, value] of Object.entries(query)) {
      if (!value) continue;

      const uTmAuthIndex = value?.indexOf('?u_tm=');
      parsedQuery[key] = uTmAuthIndex !== -1 ? (value?.slice(0, uTmAuthIndex) as string) : (value as string);
    }

    queryCookie.value = {
      ...parsedQuery,
    };
  };

  const clearQuery = () => {
    queryCookie.value = {};
  };

  const clearOfferIdQuery = () => {
    delete queryCookie.value.offerId;
  };

  const clearRollIdQuery = () => {
    delete queryCookie.value.rollId;
  };

  return {
    queryValue,
    clearQuery,
    saveQuery,
    clearOfferIdQuery,
    clearRollIdQuery,
  };
};
