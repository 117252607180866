import {
  EBattleEvents,
  type IBattleSameBattleCreatedEvent,
  type IBattleParticipantEnteredEvent,
  type IBattleCompletedEvent,
  type IBattleCreatedEvent,
  type IBattleParticipantLeftEvent,
} from '../types/battle.types';

export class BattleEvents {
  static battleCompleted(event: IBattleCompletedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.BATTLE_COMPLETED,
      eventProperties: event,
    });
  }

  static battleCreated(event: IBattleCreatedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.BATTLE_CREATED,
      eventProperties: event,
    });
  }

  static battleCreatorOpened() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.BATTLE_CREATOR_OPEN,
    });
  }

  static sameBattleCreated(event: IBattleSameBattleCreatedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.SAME_BATTLE_CREATED,
      eventProperties: event,
    });
  }

  static earlyGameSuggested() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.EARLY_GAME_SUGGESTED,
    });
  }

  static earlyBotsGameSuggested() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.EARLY_BOTS_GAME_SUGGESTED,
    });
  }

  static participantEntered(event: IBattleParticipantEnteredEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.PARTICIPANT_ENTERED,
      eventProperties: event,
    });
  }

  static participantLeft(event: IBattleParticipantLeftEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.PARTICIPANT_LEFT,
      eventProperties: event,
    });
  }

  static earlyGameStarted() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.EARLY_GAME_STARTED,
    });
  }

  static earlyBotsGameStarted() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.EARLY_BOTS_GAME_STARTED,
    });
  }

  static suggestionCanceled() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.SUGGESTION_CANCELED,
    });
  }

  static viewerEntered() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EBattleEvents.VIEWER_ENTERED,
    });
  }
}
