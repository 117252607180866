import { Colors } from '../constants/colors.constants';
import type { TPossibleNull } from '~/types/Shared.types';

interface ILabelData {
  color: TPossibleNull<string>;
  freeOpens: TPossibleNull<number>;
  name: TPossibleNull<string>;
  type: TPossibleNull<string>;
}

const availableTypes = {
  bestseller: {
    background: Colors.ADDITIONAL.LIGHT_GREEN,
    color: Colors.NEUTRAL.WHITE,
  },
  cashback: {
    background: Colors.ADDITIONAL.LIGHT_PINK,
    color: Colors.NEUTRAL.WHITE,
  },
  fifty_fifty: {
    background: Colors.ADDITIONAL.GREY,
    color: Colors.NEUTRAL.WHITE,
  },
  free: {
    background: Colors.MAIN.GOLD,
    color: Colors.NEUTRAL.BLACK,
  },
  new: {
    background: Colors.ADDITIONAL.DARK_ORANGE,
    color: Colors.NEUTRAL.WHITE,
  },
  update: {
    background: Colors.ADDITIONAL.LIGHT_BLUE,
    color: Colors.NEUTRAL.WHITE,
  },
};

const getLabelData = (
  label: TPossibleNull<ILabelData>,
  multifix: TPossibleNull<number>,
  multicast: TPossibleNull<number[]>,
) => {
  const { t } = useI18n();

  if (multifix && multifix > 1) {
    return {
      background: Colors.GRADIENTS.PURPLE_PINK,
      color: Colors.NEUTRAL.WHITE,
      text: `Multifix x${multifix}`,
    };
  }

  if (multicast && multicast.length === 2) {
    return {
      background: Colors.GRADIENTS.ORGANGE_RED,
      color: Colors.NEUTRAL.WHITE,
      text: `Multicast x${multicast[0]}-x${multicast[1]}`,
    };
  }

  // удалить,  когда сделают в админке
  if (label?.name === '50/50') {
    return {
      background: Colors.ADDITIONAL.GREY,
      color: Colors.NEUTRAL.WHITE,
      text: '50 / 50',
    };
  }

  // раскомментировать, когда сделают в админке
  // if (label?.type === 'fifty_fifty') {
  //   return {
  //     background: `#${label.color}` ?? Colors.ADDITIONAL.GREY,
  //     color: Colors.NEUTRAL.WHITE,
  //     text: '50 / 50',
  //   };
  // }

  if (!label || !label.type) return null;

  if (!Object.keys(availableTypes).includes(label.type)) return null;

  if (label.freeOpens) {
    return {
      ...availableTypes[label.type as keyof typeof availableTypes],
      text: t(`casesLabels.free`, { count: label.freeOpens }) ?? '',
    };
  }

  const names = {
    Бестселлер: t('casesLabels.bestseller'),
    Закончился: t('casesLabels.ended'),
    Кешбек: t('casesLabels.cashback'),
    Новинка: t('casesLabels.new'),
    Обновлен: t('casesLabels.update'),
    Обновлён: t('casesLabels.update'),
    Эксклюзив: t('casesLabels.exclusive'),
  };

  if (label.name && label.name in names)
    return {
      ...availableTypes[label.type as keyof typeof availableTypes],
      text: t(names[label.name as keyof typeof names]),
    };

  return {
    ...availableTypes[label.type as keyof typeof availableTypes],
    text: label.name ?? '',
  };
};

export default {
  Cases: {
    getLabelData,
  },
};
