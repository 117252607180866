// В данном файле мы создаём сущности, а так-же экспортирует их для дальнешей работы
// Каждая сущность должна быть инициализирована один раз
import ApiBuilder from '~/api/global/ApiBuilder';
import type { TApi } from '~/api/global/api.types';

const apiBuilder: ApiBuilder = new ApiBuilder();

// Сущность апи без заголовкой авторизации
const $api: TApi = apiBuilder.createApi({ keyApiUrlProject: 'baseApiUrl', type: 'default' });

// Сущность апи с заголовками для авторизации
const $authApi: TApi = apiBuilder.createApi({ keyApiUrlProject: 'baseApiUrl', type: 'auth' });

// Сущность апи для работы с запросами на старый бэк
const $oldApi: TApi = apiBuilder.createApi({ keyApiUrlProject: 'oldApiUrl', type: 'old' });

// Сущность с BasicAuth
const $devApi: TApi = apiBuilder.createApi({ keyApiUrlProject: 'baseApiUrl', type: 'dev' });

// Сущность BkDota с токеном для кейса БК-DOTA
const $bkDotaApi: TApi = apiBuilder.createApi({ keyApiUrlProject: 'bkDotaApiUrl', type: 'bkDota' });

// Сущность BkCs с токеном для дополнительного кейса БК-CS
const $bkCsApi: TApi = apiBuilder.createApi({ keyApiUrlProject: 'bkCsApiUrl', type: 'bkCs' });

export { $api, $authApi, $oldApi, $devApi, $bkDotaApi, $bkCsApi };
//
