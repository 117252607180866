import type { TPaymentApplyInstPromoResponseBody, TStartPaymentPostResponseBody } from './PaymentApi.types';
import type { IPaymentApplyPromoEntity, IPaymentStartInfoEntity } from './PaymentEntities.types';
import type { IPaymentApplyInstPromoBody, IPaymentStartPostBody } from './PaymentQueries.types';

import PaymentHttp from './PaymentHttp';

import type { IApiCountry } from '~/features/payment/types/country/api.types';
import type { ICountry } from '~/features/payment/types/country/client.types';
import { transform as countryTransform } from '~/features/payment/types/country/adapter';

import type { IApiCountriesItem } from '~/features/payment/types/countries/api.types';
import type { TCountriesItem } from '~/features/payment/types/countries/client.types';
import { transform as countriesTransform } from '~/features/payment/types/countries/adapter';

import type { IBonusItem } from '~/features/payment/types/bonuses/client.types';
import type { IApiBonusItem } from '~/features/payment/types/bonuses/api.types';
import { transform as bonusesTransform } from '~/features/payment/types/bonuses/adapter';

import type { IErrorPromo, TPromo } from '~/features/payment/types/promo/client.types';
import type { IApiErrorPromo, TApiPromo } from '~/features/payment/types/promo/api.types';
import { transform as promoTransform } from '~/features/payment/types/promo/adapter';

import type { TResult } from '~/features/payment/types/result/client.types';
import type { TApiResult } from '~/features/payment/types/result/api.types';
import { transform as resultTransform } from '~/features/payment/types/result/adapter';

export default class PaymentService {
  private readonly paymentHttp: PaymentHttp;

  constructor() {
    this.paymentHttp = new PaymentHttp();
  }

  public async getBonuses(): Promise<IBonusItem[]> {
    const response = await this.paymentHttp.getSSRData<IApiBonusItem[]>('BONUSES');
    if (!response) throw new Error('Error');

    return bonusesTransform(response);
  }

  public async getCountries(): Promise<TCountriesItem[]> {
    const response = await this.paymentHttp.getSSRData<IApiCountriesItem[]>('COUNTRIES');
    if (!response) throw new Error('Error');
    return countriesTransform(response);
  }

  public async getCountry(code: string): Promise<ICountry> {
    if (!code) throw new Error('Error');
    const response = await this.paymentHttp.getSSRDataWithSlug<IApiCountry>('COUNTRIES', code);
    if (!response) throw new Error('Error');

    return countryTransform(response);
  }

  public async getPromo(promoCode: string): Promise<TPromo | IErrorPromo> {
    if (!promoCode) throw new Error('Error');
    const response = await this.paymentHttp.getSSRDataWithSlug<TApiPromo | IApiErrorPromo>('PROMO', promoCode);
    if (!response) throw new Error('Error');

    return promoTransform(response);
  }

  public async startPayment(body: IPaymentStartPostBody): Promise<IPaymentStartInfoEntity> {
    const response = await this.paymentHttp.makePostRequest<IPaymentStartPostBody, TStartPaymentPostResponseBody>(
      'PAYMENT',
      body,
    );
    if (!response) throw new Error('Error while starting payment');
    return response;
  }

  public async applyInstantPromo(body: IPaymentApplyInstPromoBody): Promise<IPaymentApplyPromoEntity> {
    const response = await this.paymentHttp.makePostRequest<
      IPaymentApplyInstPromoBody,
      TPaymentApplyInstPromoResponseBody
    >('APPLY', body);
    if (!response) throw new Error('Error while applying promocode');
    return response;
  }

  public async getPayment(orderId: string): Promise<TResult> {
    const response = await this.paymentHttp.getSSRData<TApiResult>('PAYMENT', { orderId });
    if (!response) throw new Error('Error while receiving payment info');

    return resultTransform(response);
  }
}
