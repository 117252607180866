export enum EResultTypes {
  FAIL = 'fail',
  SUCCESS = 'success',
  WAIT = 'wait',
}

export enum EResultSuccessTypes {
  BLOGGER = 'blogger',
  COMMON = 'common',
}

export interface IResultSuccessBonusCase {
  image: string;
  name: string;
  price: number;
}

export interface TResultSuccessBonuses {
  caseData?: IResultSuccessBonusCase;
  coins?: number;
}

export interface IResultSuccessBlogger {
  bonuses: TResultSuccessBonuses;
  status: EResultTypes.SUCCESS;
  sum: number;
  type: EResultSuccessTypes.BLOGGER;
}

export interface IResultSuccess {
  status: EResultTypes.SUCCESS;
  sum: number;
  type: EResultSuccessTypes.COMMON;
}

export interface IResultWait {
  status: EResultTypes.WAIT;
}

export interface IResultFail {
  status: EResultTypes.FAIL;
}

export type TResult = IResultSuccessBlogger | IResultSuccess | IResultWait | IResultFail;
