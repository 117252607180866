export const Gap = {
  L: 24,
  M: 20,
  S: 16,
  XL: 32,
  XL2: 40,
  XL3: 48,
  XL4: 64,
  XL5: 80,
  XL6: 88,
  XS: 10,
  XS2: 8,
  XS3: 6,
  XS4: 4,
  XS5: 2,
  XSS: 12,
};

export const BorderRadius = {
  L: 32,
  M: 24,
  S: 16,
  SS: 12,
  XL: 40,
  XL2: 48,
  XL3: 64,
  XL4: 100,
  XS: 8,
  XS2: 6,
  XS3: 4,
  XS4: 2,
};

export const SELL_UPDATE_DEBOUNCE = 1000;
