import type { IHeaderStoreApiResponse, IHeaderStoreApiUserOffersCount, TMenuBadges } from '~/store/header/header.types';
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';
import { EHeaderMenuItems } from '~/config/apps/index.types';

export const useHeaderStore = defineStore('global/header', () => {
  const { $api } = useNuxtApp();

  const eventStore = useEventStore();
  const { coinColor, coinIcon } = storeToRefs(eventStore);

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const menuBadges = ref<TMenuBadges>({
    [EHeaderMenuItems.BATTLES]: {
      type: 'default',
      value: '',
    },
    [EHeaderMenuItems.SECRETSHOP]: {
      type: 'default',
      value: '', // Из API
    },
    [EHeaderMenuItems.BONUS_PAGE]: {
      type: 'default',
      value: '', // Из API
    },
    [EHeaderMenuItems.FREE_CASES]: {
      type: 'default',
      value: '', // Из API
    },
  });
  const isLoaded = ref(false);

  const freeCaseTime = ref(0);
  const freeCaseTimer = useTimer(freeCaseTime);

  const viewedSecret = usePersistedRef<number[]>('viewedSecret', []);
  const secretShopItems = ref<Record<string, number>[]>([]);

  const profileData = computed(() => {
    if (!user.value) return null;
    const { avatar, finance, nickname, userLevel, percentToNextLevel } = user.value;

    return {
      event: {
        color: coinColor.value,
        icon: coinIcon.value,
      },
      user: {
        avatar,
        balance: {
          coins: +finance.coinBalance,
          money: +finance.balance,
          skins: +finance.totalAmountItems,
        },
        level: +userLevel < 0 ? 0 : +userLevel,
        nickname,
        progress: +percentToNextLevel,
      },
    };
  });

  const getData = async () => {
    if (!user.value) {
      isLoaded.value = true;
      return;
    }

    try {
      const { data, status } = (await $api.user.getUserOffers(user.value.userId)) as IHeaderStoreApiResponse;
      if (!status) return;

      const { offers: secretItems, userOffersCount: bonusCount } = data;

      handleSecretShop(secretItems);
      handleBonuses(bonusCount);
      handleFreeCases();

      secretShopItems.value = secretItems;
    } catch {
    } finally {
      isLoaded.value = true;
    }
  };

  const handleBattles = (count: number) => {
    menuBadges.value[EHeaderMenuItems.BATTLES] = {
      type: 'default',
      value: `${count || ''}`,
    };
  };

  const handleSecretShop = (data: Record<string, number>[]) => {
    secretShopItems.value = data;
    const notViewedOffers = data.filter((item) => !viewedSecret.value.includes(item.id)) || [];

    menuBadges.value[EHeaderMenuItems.SECRETSHOP] = {
      type: 'default',
      value: `${notViewedOffers.length || ''}`,
    };
  };

  const handleBonuses = (data: IHeaderStoreApiUserOffersCount) => {
    const count = data.promoCodesCount + data.userSocialsCount;
    menuBadges.value[EHeaderMenuItems.BONUS_PAGE] = {
      type: 'default',
      value: `${count || ''}`,
    };
  };

  const handleFreeCases = () => {
    if (!user.value) return;

    const { availableCases, nearestCaseTime } = user.value;

    if (nearestCaseTime) {
      menuBadges.value[EHeaderMenuItems.FREE_CASES] = {
        type: 'timer',
        value: '',
      };

      const { timerData, start } = useTimer(toRef(nearestCaseTime * 1000));
      start();

      const unwatch = watch(
        timerData,
        ({ hours, minutes }) => {
          const isOver = !+hours && !+minutes;
          const value = isOver ? '' : `${hours}:${minutes}`;

          menuBadges.value[EHeaderMenuItems.FREE_CASES] = {
            type: 'timer',
            value,
          };

          if (!isOver) return;
          unwatch();
        },
        { deep: true, immediate: true },
      );

      return;
    }

    menuBadges.value[EHeaderMenuItems.FREE_CASES] = {
      type: 'default',
      value: `${availableCases.length || ''}`,
    };
  };

  const saveViewedSecretOffers = () => {
    const uncheckedKeys = secretShopItems.value
      .filter((item) => !viewedSecret.value.includes(item.id))
      .map((item) => item.id);

    viewedSecret.value.push(...uncheckedKeys);
  };

  watch(
    user,
    (newUser) => {
      if (!newUser) return;

      const { availableCases, nearestCaseTime } = newUser;

      if (availableCases.length) {
        menuBadges.value[EHeaderMenuItems.FREE_CASES] = {
          type: 'default',
          value: `${availableCases.length || ''}`,
        };
        return;
      }

      const parsedTime = (nearestCaseTime ?? 0) * 1000;

      if (freeCaseTime.value && freeCaseTime.value === parsedTime) return;
      freeCaseTime.value = parsedTime;
      freeCaseTimer.start();
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch(
    freeCaseTimer.timerData,
    ({ hours, minutes }) => {
      const isOver = !+hours && !+minutes;
      const value = isOver ? '' : `${hours}:${minutes}`;

      menuBadges.value[EHeaderMenuItems.FREE_CASES] = {
        type: 'timer',
        value,
      };

      if (!isOver) return;
      freeCaseTimer.stop();
    },
    { deep: true, immediate: true },
  );

  return {
    getData,
    handleBattles,
    isLoaded,
    menuBadges,
    profileData,
    saveViewedSecretOffers,
  };
});
