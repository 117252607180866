import { $authApi } from '~/api/global/ApiProvider';
import type { ITechWorkStatusApi } from '~/features/tech-work/types/api.types';

export default class TechWorkRepository {
  public static async getTechWorkStatus(): Promise<boolean> {
    const response = await $authApi.get<ITechWorkStatusApi>(`/v1/monitoring/status`);
    if (!response.status) return false;

    return response.data.isOffline;
  }
}
