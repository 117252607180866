import TechWorkRepository from '~/features/tech-work/api/repository';

export const useSiteStatusStore = defineStore('tech-work/status', () => {
  const isOffline = ref(false);

  const getStatus = async () => {
    try {
      isOffline.value = await TechWorkRepository.getTechWorkStatus();
    } catch {
      isOffline.value = false;
    }
  };

  return { getStatus, isOffline };
});
