import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import slash_45trimmer_45global from "/app/middleware/SlashTrimmer.global.ts";
import oauth_45global from "/app/middleware/oauth.global.ts";
import offline_45global from "/app/middleware/offline.global.ts";
import provably_45fair_45global from "/app/middleware/provablyFair.global.ts";
import seo_45global from "/app/middleware/seo.global.ts";
import sockets_45global from "/app/middleware/sockets.global.ts";
import techies_45global from "/app/middleware/techies.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  slash_45trimmer_45global,
  oauth_45global,
  offline_45global,
  provably_45fair_45global,
  seo_45global,
  sockets_45global,
  techies_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/features/battles/middleware/auth.ts")
}