import ApiQueryHttp from '~/api/global/http/ApiQueryHttp';
import type { TPossibleNull, TPathParam, TQueryParams } from '~/types/Shared.types';
import { MainPageApi } from '~/repository/modules/mainPage/MainPage.api';

type TGetMainPageKeys = keyof typeof MainPageApi.GET;
type TPostMainPageKeys = keyof typeof MainPageApi.POST;

export default class MainPageHttp extends ApiQueryHttp {
  private mainPageApi: string;

  constructor() {
    super();
    this.mainPageApi = '/v1';
  }

  public async getSSRData<TResponse>(
    route: TGetMainPageKeys,
    queries: TQueryParams = {},
    pathParams: TPathParam[] = [],
  ): Promise<TPossibleNull<TResponse>> {
    const queryString = this.query.serialise(queries);

    const pathParamsString = pathParams.join('/');

    const { data } = await this.$authApi.get<{ data: TResponse }>(
      this.route(MainPageApi.GET[route]) + pathParamsString + queryString,
    );

    return (data as TResponse) || null;
  }

  public async getSSRDataWithSlug<TResponse>(route: TGetMainPageKeys, slug: string): Promise<TPossibleNull<TResponse>> {
    const codePart = `${slug}`;

    const { data } = await this.$authApi.get<{ data: TResponse }>(this.route(MainPageApi.GET[route]) + codePart);

    return (data as TResponse) || null;
  }

  public async makePostRequest<TRequest = object, TResponse = object>(
    route: TPostMainPageKeys,
    body: TRequest,
  ): Promise<TPossibleNull<TResponse>> {
    const { data }: { data: TResponse } = await this.$authApi.post(this.route(MainPageApi.POST[route]), {
      body: body || {},
      isMapped: true,
      mapBeforeSend: true,
    });
    return data;
  }

  public async makePostRequestWithSlug<TRequest = object, TResponse = object>(
    route: TPostMainPageKeys,
    slug: string | number,
    body: TRequest,
  ): Promise<TPossibleNull<TResponse>> {
    const { data }: { data: TResponse } = await this.$authApi.post(this.route(MainPageApi.POST[route]) + slug, {
      body: body || {},
      isMapped: true,
      mapBeforeSend: true,
    });
    return data;
  }

  private route = (route: string) => this.mainPageApi + route;
}
