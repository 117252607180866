//  Ключи с бэка связаны с ключам с фронта

export enum ErrorCodes {
  AUTHENTICATION_EXCEPTION = 'authentication',
  BALANCE_ERROR = 'balance_error',
  CASE_NOT_FOUND = 'caseNotFound',
  DATA_NOT_FOUND = 'dataNotFound',
  INVALID_ITEM = 'invalidItem',
  INVALID_USER = 'invalidUser',
  INVENTORY_ERROR = 'inventory',
  ITEM_ALREADY_IN_WITHDRAWAL_PROCESS = 'inWithdrawalProcess',
  TOO_MANY_STEAM_LOGIN_USES = 'steamLogin',
  TOO_MANY_WITHDRAWALS = 'tooManyWithdrawals',
  UNPREDICTED_EXCEPTION = 'unpredicted',
  USER_NOT_FOUND = 'userNotFound',
  WAIT_FOR_CASE_TO_OPEN_ERROR = 'fastOpen',
  CASE_UNAVAILABLE = 'case_not_available',
}
