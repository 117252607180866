import type { TApi } from '~/api/global/api.types';
import { $bkDotaApi, $bkCsApi } from '~/api/global/ApiProvider';

const getGameType = (): string => {
  const config = useRuntimeConfig();
  return typeof config.public.tastyProject === 'string' ? config.public.tastyProject : '';
};

export const isTastyDrop = () => getGameType() === 'dota';

export class BkApiSample {
  public readonly $bkApi: TApi = isTastyDrop() ? $bkDotaApi : $bkCsApi;
  public readonly $bkSecondApi: TApi = isTastyDrop() ? $bkCsApi : $bkDotaApi;
}
