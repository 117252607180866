import type { TCurrencyType } from '~/types/Shared.types';

export const Currency = {
  AZN: '₼',
  BRL: 'R$',
  COIN: 'COIN',
  DIAMOND: '💎',
  DM: 'DM',
  EUR: '€',
  INR: 'INR',
  KZT: 'KZT',
  RUB: '₽',
  TASTY_COIN: 'TASTY_COIN',
  USD: '$',
} as const;

export const CurrencyName: Record<TCurrencyType, TCurrencyType> = {
  AZN: 'AZN',
  BRL: 'BRL',
  COIN: 'COIN',
  DIAMOND: 'DIAMOND',
  DM: 'DM',
  EUR: 'EUR',
  INR: 'INR',
  KZT: 'KZT',
  RUB: 'RUB',
  TASTY_COIN: 'TASTY_COIN',
  USD: 'USD',
} as const;
