import type { LocationQuery, RouteParams } from 'vue-router';
import type { IUrlOptions, TValidators } from '~/features/bk/types/integrator.types';

export default class BkGuardUrlService {
  /** Валидация пути запроса
   * Метод помощник, позволяет избавиться от дублирования аргумента response при вызове
   * **/
  public checkUrl(response: Ref<IUrlOptions>) {
    return (listKeys: (keyof IUrlOptions)[], objValues: LocationQuery | RouteParams, validators?: TValidators) =>
      this.validateUrl(listKeys, objValues, response, validators);
  }

  /** Валидация пути запроса
   * @param {(keyof IUrlOptions)[]} listKeys - массив необходимых ключей
   * @param {LocationQuery | RouteParams} objValues - объект где содержатся необходимые ключи
   * @param {IUrlOptions} response - объект для вывода данных
   * @param validators - набор валидаторов под каждый ключ
   **/
  private validateUrl(
    listKeys: (keyof IUrlOptions)[],
    objValues: LocationQuery | RouteParams,
    response: Ref<IUrlOptions>,
    validators?: TValidators,
  ) {
    for (let i = 0; i < listKeys.length; i++) {
      const key: keyof IUrlOptions = listKeys[i];
      const currentOption = objValues[key];
      // Проверить ключ на наличие
      // Язык мы можем подставить дефолтный, поэтому не кидаем ошибку
      if (!currentOption && key !== 'languageIso') {
        this.rejectError(key);
        break;
      }

      // Проверить ключ на валидность, если такой есть
      if (validators && this.hasItemInValidator(validators, key, currentOption)) {
        this.rejectError(key);
        break;
      }

      if (typeof currentOption === 'string')
        this.setResponseValue<IUrlOptions>(response, key, currentOption, validators);
    }
  }

  /** Метод для заполнения объёкта query ключами
   * @param {Object} response - исходный объект
   * @param {string} key - ключ объёкта
   * @param {string} currentQuery - значение объекта
   * @param validators
   **/
  private setResponseValue<T extends object, K = keyof T>(
    response: Ref<T>,
    key: K,
    currentQuery: string,
    validators?: TValidators,
  ) {
    if (typeof key === 'string') {
      let newObj: object;

      // При наличии преобразователя данных - преобразовать в нужный формат
      if (validators && validators[key] && validators[key].transform) {
        newObj = {
          [key]: validators[key].transform?.(currentQuery),
        };
      } else {
        newObj = {
          [key]: currentQuery,
        };
      }
      Object.assign(response.value, newObj);
    }
  }

  /** Обработка ошибки при отсутствии в пути запроса необходимых query/params
   * @param {string} key - ключ объёкта
   **/
  private rejectError(key: string) {
    throw new Error('Ключ в запросе не найден: ' + key);
  }

  // Есть ли для элемента валидация
  private hasItemInValidator<T>(validators: TValidators, key: keyof IUrlOptions, currentOption: T) {
    return validators[key] && !validators[key].handler<typeof currentOption>(currentOption);
  }
}
