/* /battles + BattlesRoutesApi[...][...] */
export const BattlesRoutesApi = {
  GET: {
    ALL_BATTLES: '/battles',
    ALL_CASES: '/cases',
    BATTLE_STATISTICS: '/statistics',
    ONE_BATTLE: '/battle',
    STATUS: '/status',
  },
  POST: {
    CANCEL_START_OFFER: '/cancel_start',
    CLOSE_NOTIFY: '/close',
    CREATE_BATTLE: '/battles',
    JOIN_BATTLE: '/join',
    LEAVE_BATTLE: '/leave',
    OFFER_AND_START_BATTLE: '/start',
    OPEN_NOTIFY: '/open',
    SELL_ALL_WINNINGS: '/sell',
    SEND_EMOJI: '/emoji',
  },
} as const;
