/*
  Sockets
*/

export enum EBattlesStoreNames {
  ALL_STATE = 'battles/state',
  BATTLE = 'battles/battle',
  BATTLES_LIST = 'battles/list',
  BATTLES_LIST_FILTERS = 'battles/list/filters',
  BATTLE_PLAYERS = 'battles/battle/players',
  CASES_FILTER = 'battles/cases/filter',
  CASES_SELECTION = 'battles/cases/selection',
  CREATE_BATTLE_OPTIONS = 'battles/create/options',
  EMOJIES = 'battles/emojies',
  SECTIONS = 'battles/sections/cases',
  STATISTICS = 'battles/statistics',
}

export enum EBattlesSocketEvents {
  BATTLE_CHANGED = 'battles_battle_updated',
  EMOJI_RECEIVED = 'battles_emoji_sent',
  SELL_DONE = 'sell_done',
  STATECHANGE = 'statechange',
}

/*
  Battles List Filters/Sorting
*/

export const enum EBattlesListSortingVariants {
  DATE = 'date',
  PRICE = 'price',
}

export const enum EBattlesListSortingDirections {
  FROM_DOWN_TO_UP,
  FROM_UP_TO_DOWN,
}
