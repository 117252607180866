<template>
  <SharedModal v-model="isShowOffer" class="io-modal">
    <InnerOfferContent
      :label-data="labelData"
      :image="image"
      :title="title"
      :description="description"
      :trigger-id="triggerId"
      :case-data="caseData"
      :price-with-coins="priceWithCoins"
      :offer-name="offerName"
      :offer-id="offerId"
      :price-data="priceData"
      @pressed-buy="handlerBuy"
    />
  </SharedModal>
</template>

<script setup lang="ts">
import InnerOfferContent from '../../components/InnerOfferContent/InnerOfferContent.vue';
import { useInnerOfferStore } from '~/store/innerOffer/innerOffer.store';
import { InnerOfferEvents } from '~/repository/amplitude/events/innerOffer';
import type { IInnerOfferPopupShownEvent } from '~/repository/amplitude/types/innerOffer.types';

const innerOfferStore = useInnerOfferStore();

const {
  title,
  description,
  triggerId,
  caseData,
  priceWithCoins,
  isShowOffer,
  image,
  offerName,
  priceData,
  labelData,
  triggerType,
  offerId,
  showModal,
} = storeToRefs(innerOfferStore);

const amplitudeData = computed<IInnerOfferPopupShownEvent>(() => ({
  'Offer Name': offerName.value ?? '',
  'Offer Price': priceData.value.price ?? 0,
  'Trigger Type': triggerType.value ?? '',
}));

const handlerBuy = () => {
  InnerOfferEvents.buttonClicked(amplitudeData.value);
};
const showOffer = () => {
  innerOfferStore.setIsShowOfferStatus(true);
  InnerOfferEvents.popupShown(amplitudeData.value);
};

// слежу за появлением айди триггера
watch([triggerId, showModal], ([newId, newShowModal]) => {
  if (newId && newShowModal) showOffer();
});

watch(
  isShowOffer,
  (isOpened) => {
    if (!document) return;
    if (isOpened) {
      document.body.classList.add('shared-modal-opened');
      document.body.style.overflow = 'hidden';
    } else {
      document.body.classList.remove('shared-modal-opened');
      document.body.style.overflow = '';
    }
  },
  { immediate: true },
);
</script>
