const volumesList = {
  techiesClick: async () => (await import('~/project-layers/cs/assets/volume/techies-click.mp3')).default || '',
  techiesLose: async () => (await import('~/project-layers/cs/assets/volume/techies-lose.mp3')).default || '',
  techiesWin: async () => (await import('~/project-layers/cs/assets/volume/techies-win.mp3')).default || '',
};

const volumesKeys: Record<string, string> = {};
Object.keys(volumesList).forEach((key) => (volumesKeys[key] = key));

export { volumesKeys, volumesList };
