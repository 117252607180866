/**
 * Преобразует ключ вида snake_case в ключ вида camelCase
 * @param str - Ключ для преобразования вида snake_case
 * @returns - Ключ вида camelCase
 */
const snake2Camel = (str: string): string => {
  return str.replace(/_([a-z])/g, (_, letter) => {
    return letter.toUpperCase();
  });
};

/**
 * Преобразует ключ вида camelCase в ключ вида snake_case
 * @param str - Ключ для преобразования вида camelCase
 * @returns - Ключ вида snake_case
 */
const camel2Snake = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

/**
 * Возвращает новую строку, у которой первый символ в верхнем регистре
 * @param str {T extends string} - Строка для преобразования
 * @returns {Capitalize<T>} - Строка с первым м символом в верхнем регистре
 */
const capitalize = <T extends string>(str: T): Capitalize<T> => {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
};

/**
 * Возвращает незаконченную строку подходящей длины
 * @param {String} str - Строка для преобразования
 * @param {Number} length - Кол-во символов до ...
 * @return {String} - Строка с ... на конце
 */
const shortenText = (str: string, length: number): string => {
  return str.length >= length ? str.slice(0, length) + '...' : str;
};

/**
 * Удалить слэш с конца строка
 * @param {String} string - Строка для преобразования
 * @return {String} - Строка без последнего символа
 */
export const sliceLastSlash = (string: string): string => {
  if (!string) return '';

  if (string[string.length - 1] === '/') {
    return string.slice(0, -1);
  }
  return string;
};

/**
 * Форматирует число в строку, добавляя пробелы как разделители разрядов для чисел больше 4-х цифр.
 * @param {Number | undefined | null} number - Число для форматирования
 * @param {Object} options - Дополнительные опции
 * @param {Boolean} options.dashIfZero - Если true, то возвращает "-" вместо "0", выключено по умолчанию
 * @param {Boolean} options.separateThousands - Разделять ли тысячи пробелами, включено по умолчанию
 * @returns {String} - Отформатированное число
 * @example
 * ```typescript
 * formatNumber() // '-'
 * formatNumber(0) // '0'
 * formatNumber(0, {dashIfZero: true}) // '-'
 * formatNumber(1234) // '1234'
 * formatNumber(12345) // '12 345'
 * formatNumber(12345, {separateThousands: false}) // '12345'
 * ```
 */
export const formatNumber = (
  number?: number | null,
  { dashIfZero = false, separateThousands = true }: { dashIfZero?: boolean; separateThousands?: boolean } = {
    dashIfZero: false,
    separateThousands: true,
  },
): string => {
  if (number === undefined || number === null) return '-';
  if (number === 0) return dashIfZero ? '-' : '0';
  if (number > 9999 && separateThousands) {
    // французы разделяют разряды пробелом
    return number.toLocaleString('fr-FR');
  }
  return number.toString();
};

export default {
  Text: {
    camel2Snake,
    capitalize,
    shortenText,
    sliceLastSlash,
    snake2Camel,
  },
};
