<template>
  <div class="badge-wrapper">{{ text }}</div>
</template>

<script setup lang="ts">
import type { IBadgeProps } from './Badge.types';
import { Colors } from '~/constants/colors.constants';

const props = withDefaults(defineProps<IBadgeProps>(), { text: '' });
const badgeBgColor = computed(() => (props.bgColor === 'default' ? Colors.ADDITIONAL.DARK_ORANGE : props.bgColor));
</script>

<style lang="scss" scoped>
.badge-wrapper {
  --badge-bg-color: v-bind(badgeBgColor);
}
</style>
<style src="./Badge.style.scss" lang="scss" scoped></style>
