import DropFeedHttp from '~/repository/modules/dropFeed/DropFeedHttp';
import type { ITopLiveDropApiData } from '~/project-layers/dota/store/topLiveDrop/topLiveDrop.types';

export default class DropFeedService {
  private dropFeedHttp: DropFeedHttp;

  constructor() {
    this.dropFeedHttp = new DropFeedHttp();
  }

  getTopDrop(isTop: boolean): Promise<ITopLiveDropApiData[]> {
    return this.dropFeedHttp.getTopDrop(isTop);
  }
}
