import type { TKeys, TRegistrationResponseTransformData, TTypesOAuthKey } from './auth.types';
import AuthHttp from '~/repository/modules/auth/AuthHttp';
import type { LoginRequestDto, LoginResponseDto } from '~/repository/modules/auth/auth.dto';

export default class AuthService {
  private readonly authHttp: AuthHttp;

  constructor() {
    this.authHttp = new AuthHttp();
  }

  public login(formData: LoginRequestDto): Promise<LoginResponseDto> {
    return this.authHttp.login(formData);
  }

  public authByExternalToken(token: string): Promise<LoginResponseDto> {
    return this.authHttp.authByExternalToken(token);
  }

  public async quickRegistration(): Promise<TRegistrationResponseTransformData> {
    const data = await this.authHttp.quickRegistration();
    const showKeysForRegistrationInfo: TKeys = ['login', 'password'];
    return GlobalUtils.Objects.getObjectWithOnlySelectedKeys(data, showKeysForRegistrationInfo);
  }

  public async oauth(type: TTypesOAuthKey): Promise<void> {
    const { target } = await this.authHttp.oauth(type);
    document.location.href = target;
  }

  public revokeToken() {
    return this.authHttp.revokeToken();
  }
}
