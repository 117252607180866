import DailyCasesHttp from '~/repository/modules/cases/DailyCasesHttp';
import type { SubscriptionsRequestDto } from '~/repository/modules/cases/cases.dto';
import type { TUserSubscriptions } from '~/store/user/user.types';

export class DailyCasesService {
  private readonly dailyCasesHttp: DailyCasesHttp;

  constructor() {
    this.dailyCasesHttp = new DailyCasesHttp();
  }

  async getCases() {
    const data = await this.dailyCasesHttp.getCases();
    return data ?? null;
  }

  async getTimerInfo() {
    const response = await this.dailyCasesHttp.getTimerInfo();
    return response ?? null;
  }

  async updateSubscriptions(requestData: SubscriptionsRequestDto): Promise<TUserSubscriptions> {
    const response = await this.dailyCasesHttp.updateSubscriptions(requestData);
    return response ?? null;
  }
}
