/**
 * Получить координаты дом элемента
 * @param {HTMLElement} item - дом элемент, чьи координаты мы получаем
 * @return {DOMRect} - объект координат элемента
 */
const getCoords = (item: HTMLElement | Element): DOMRect => {
  if ('getBoundingClientRect' in item) {
    return item.getBoundingClientRect();
  }
  return {} as DOMRect;
};

export default {
  DOM: {
    getCoords,
  },
};
