import ROUTING_GLOBAL from '../repository/navigation';
import ApiUtils from './api.utils';
import CurrencyUtils from './currency.utils';
import DomUtils from './dom.utils';
import FunctionsUtils from './functions.utils';
import MathUtils from './math.utils';
import ObjectUtils from './object.utils';
import SteamUtils from './steam.utils';
import TextUtils from './text.utils';
import CasesUtils from './cases.utils';
import RoutingUtils from './routing.utils';
import CssUtils from './css.utils';
import ColorsUtils from './colors.utils';
import ImageUtils from './image.utils';
import NumbersUtils from './number.utils';
import PriceUtils from './price.utils';
import DateUtils from './date.utils';

/* Экспорт всех утилит через автоимпорт по средстам общего объекта */
const AllUtils = {
  ...ApiUtils,
  ...ColorsUtils,
  ...CssUtils,
  ...CurrencyUtils,
  ...DomUtils,
  ...FunctionsUtils,
  ...MathUtils,
  ...ObjectUtils,
  ...SteamUtils,
  ...TextUtils,
  ...CasesUtils,
  ...RoutingUtils,
  ...ImageUtils,
  ...NumbersUtils,
  ...PriceUtils,
  ...DateUtils,
};

/* Экспортируем для автоимпорта */
export const ROUTING = ROUTING_GLOBAL as DeepReadonly<typeof ROUTING_GLOBAL>;
export const GlobalUtils = AllUtils as DeepReadonly<typeof AllUtils>;

type DeepReadonly<T> = {
  readonly [Key in keyof T]: T[Key] extends unknown[] | Record<string, unknown> ? DeepReadonly<T[Key]> : T[Key];
};
