import { useAuthStore } from '~/features/authentication/store/authorization';

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const { isAuth } = storeToRefs(authStore);

  const localeRoute = useLocaleRoute();

  const isStatisticPage = to.name === ROUTING.BATTLES.STATISTICS.name;
  const isCreatePage = to.name === ROUTING.BATTLES.CREATE.name;

  if (isStatisticPage && !isAuth.value) {
    return navigateTo(localeRoute(ROUTING.BATTLES.MAIN));
  }

  if (isCreatePage && !isAuth.value) {
    return navigateTo(localeRoute(ROUTING.BATTLES.MAIN));
  }
});
