import { useAuthStore } from '~/features/authentication/store/authorization';
import { useInnerOfferStore } from '~/store/innerOffer/innerOffer.store';

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();

  const authStore = useAuthStore();
  const innerOfferStore = useInnerOfferStore();
  const { isAuth } = storeToRefs(authStore);

  const getRouteBaseName = useRouteBaseName();
  const baseRouteName = getRouteBaseName(route);
  const toDropPage = baseRouteName === 'drop-page';

  nuxtApp.hook('page:finish', () => {
    if (!toDropPage && isAuth) {
      innerOfferStore.getOffer();
    }
  });
});
