export const Media = {
  md: 768,
  sm: 360,
  xl: 1280,
  xxl: 1920,
};

type TBreakPoint = keyof typeof Media;
type TBreakPointsObject = Record<TBreakPoint, TBreakPoint>;

export const Breakpoints: TBreakPointsObject = (Object.keys(Media) as Array<TBreakPoint>).reduce(
  (acc: TBreakPointsObject, item: TBreakPoint) => {
    acc[item] = item;
    return acc;
  },
  {} as TBreakPointsObject,
);
