import {
  EAuthEvents,
  type IAuthLoginEvent,
  type IAuthLoginUser,
  type IAuthButtonClickedEvent,
  type IAuthAccountCreatedEvent,
  type IAuthAccountCreatedUser,
  type IAuthEntryClickedEvent,
} from '../types/auth.types';

export class AuthEvents {
  static accountCreated(event: IAuthAccountCreatedEvent, user: IAuthAccountCreatedUser) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EAuthEvents.ACCOUNT_CREATED,
      eventProperties: event,
      userProperties: user,
    });
  }

  static buttonClicked(event: IAuthButtonClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EAuthEvents.BUTTON_CLICKED,
      eventProperties: event,
    });
  }

  static entryClicked(event: IAuthEntryClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EAuthEvents.ENTRY_CLICKED,
      eventProperties: event,
    });
  }

  static login(event: IAuthLoginEvent, user: IAuthLoginUser) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EAuthEvents.LOGIN,
      eventProperties: event,
      userProperties: user,
    });
  }
}
