<template>
  <Teleport to="#teleports">
    <Transition name="fade" mode="out-in" @after-enter="handleClickOutside">
      <div v-if="isOpen" :class="$attrs.class">
        <section ref="popupRef" class="base-modal">
          <div class="base-modal__close" @click="close">
            <SvgoCloseIconBig class="base-modal__close-icon" fill="var(--neutral-white)" />
          </div>
          <div class="base-modal__content">
            <slot></slot>
          </div>
        </section>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
const popupRef = ref<HTMLElement>();
const removeClickOutside = ref<() => void>();

const isOpen = defineModel<boolean>();

const close = () => {
  isOpen.value = false;
};

const handleClickOutside = () => {
  if (!popupRef.value) return;
  const { remove } = useClickOutside(popupRef.value, close);
  removeClickOutside.value = remove;
};

watch(isOpen, (newValue) => {
  if (newValue || !removeClickOutside.value) return;
  removeClickOutside.value();
});
</script>

<style scoped lang="scss" src="./BaseModal.scss" />
