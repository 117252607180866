<template>
  <NuxtLinkLocale class="case" target="_blank" :to="link">
    <NuxtImg class="case-image" :src="caseData.image" />
    <div class="case-content">
      <h2 class="case-title">
        <span v-if="caseData.count > 1" class="case-badge">{{ caseData.count }}x</span>
        <div class="case-text">
          {{ caseData.dispName }}
        </div>
      </h2>
      <div class="case-price">
        <OldPrice :font-size="changeXL(12, 10)" :price="caseData.price" />
        {{ $t('forFree') }}
      </div>
    </div>
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import OldPrice from '../OldPrice/OldPrice.vue';
import type { ICaseComponentProps } from './CaseComponent.types';
const props = defineProps<ICaseComponentProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const links = useLinks();
const link = computed(() => links.CASE + props.caseData.name);
</script>

<style src="./CaseComponent.style.scss" lang="scss" scoped></style>
