import type { IAppRequest, TPossibleNull } from '~/types/Shared.types';
import ApiQueryHttp from '~/api/global/http/ApiQueryHttp';
import type { IResponseOfferDTO } from '~/repository/modules/innerOffer/InnerOfferService.types';

export default class InnerOfferHttp extends ApiQueryHttp {
  protected readonly offerApi: string;
  protected readonly triggerPopupState: string;

  constructor() {
    super();
    this.offerApi = '/v1/triggers';
    this.triggerPopupState = '/v1/triggers/dailycasepay';
  }

  /* GET */

  public async getOffer<T>(): Promise<TPossibleNull<T>> {
    const { data } = await this.$authApi.get<IAppRequest<T>>(this.offerApi);
    return data || null;
  }

  public async getTriggerPopupState(id: number): Promise<IResponseOfferDTO | undefined> {
    const { data } = await useAsyncData<IAppRequest<IResponseOfferDTO>>(() =>
      this.$authApi.post(this.triggerPopupState, {
        body: { case_id: id },
      }),
    );
    return data.value?.data;
  }
}
