import type {
  IWebsocketInstance,
  IWebsocketResponse,
  ISubscriberWebsocket,
} from '~/api/global/webosket/websocket.types';

import DataMapper from '~/api/global/DataMapper';

const MAX_RETRIES = 5;
const RETRY_DELAY = 1000;

/**
 * Класс для работы с нативным вебсокет
 */
export default class PureWebsocket implements IWebsocketInstance {
  private socket?: WebSocket;
  private readonly mapper: DataMapper = new DataMapper();
  private readonly subscribers: Map<string, ISubscriberWebsocket> = new Map();

  private retry = 0;

  /**
   * Устанавливает сокет соединение и возвращает инстанс класса
   * @param {string} url - Целевой объект
   * @returns {this} - инстанас класса
   */
  public connect(url: string, isReconnecting = false): this {
    if (!isReconnecting) {
      this.retry = 0;
    }

    this.socket = new WebSocket(url);
    this.errorMessage(url);
    this.connectMessage();
    return this;
  }

  /**
   * Удаляет сокет соединение
   */
  public disconnect(): void {
    if (!this.socket) return;
    this.socket.close();
  }

  /**
   * Добавить подписчика в массив подписчиков
   * @param {ISubscriberWebsocket} sub - Конфиг подписчика
   */
  public subscribe(sub: ISubscriberWebsocket) {
    if (this.subscribers.has(sub.uniqueKey)) {
      return () => this.subscribers.delete(sub.uniqueKey);
    }

    this.subscribers.set(sub.uniqueKey, sub);

    return () => this.subscribers.delete(sub.uniqueKey);
  }

  /**
   * Отправить сообщение по каналу веб-сокетов
   * @param event - Событие в сообщении
   * @param payload - Полезная нагрузка сообщения помимо event
   */
  public sendMessage(event: string, payload: Record<string, unknown>) {
    if (!this.socket) return;
    this.socket.send(JSON.stringify({ event, ...payload }));
  }

  /**
   * Отслеживает данные сокет соединения, если тип события совпадает с ожидаемым эвентом(аргумент event), то
   * отправляет в cb. Cb в свою очередь
   * принимает аргумент(parseData)
   */
  private connectMessage() {
    if (!this.socket) return;
    this.socket.onmessage = (data: { data: string }) => {
      const parseData = this.mapper.mapDataKeys(JSON.parse(data.data));

      this.subscribers.forEach((sub) => {
        const { event, cb, passAllData, fieldsToParse } = sub;
        if (Array.isArray(event) && event.includes(parseData.event)) {
          const outputData = this.prepareData(parseData, fieldsToParse);
          cb(outputData);
          return;
        }

        if (parseData.event === event) {
          const outputData = this.prepareData(parseData, fieldsToParse);
          cb(outputData);
          return;
        }

        if (passAllData) {
          const outputData = this.prepareData(parseData, fieldsToParse);
          cb(outputData);
        }
      });
    };
  }

  /**
   * Парсим данные в тех полях, которые мы прописали для авто-парсинга с помощью JSON.parse
   */
  private prepareData(data: IWebsocketResponse, fieldsToParse: string[] = []): IWebsocketResponse {
    const newData = { ...data };
    for (const field of fieldsToParse) {
      if (field in newData) {
        const json = JSON.parse(newData[field as keyof IWebsocketResponse]);
        newData[field as keyof typeof newData] = this.mapper.mapDataKeys(json);
      }
    }
    return newData;
  }

  private handleReconnect(url: string) {
    this.retry++;
    if (this.retry === MAX_RETRIES) return;

    this.connect(url, true);
  }

  /* метод для обработки ошибки соеднинения веб-сокетов */
  private errorMessage(url: string) {
    if (!this.socket) return;
    this.socket.onerror = () => {
      setTimeout(() => this.handleReconnect(url), RETRY_DELAY);
    };
  }
}
