import type { TPossibleNull } from '~/types/Shared.types';

export interface ITimerData {
  days: string;
  hours: string;
  minutes: string;
  rawTime: number;
  seconds: string;
}
interface ITimer {
  start: () => void;
  stop: () => void;
  timerData: ComputedRef<ITimerData>;
}

const getParsedValue = (expression: number) => `${Math.floor(expression)}`.padStart(2, '0');

export const useTimer = (dateTime: Ref<string | number | undefined>, isUnix = false): ITimer => {
  const time = ref(0);

  const timeDiff = computed(() => {
    if (!dateTime.value) return 0;

    const parsedDate = isUnix ? new Date(+dateTime.value * 1000) : new Date(dateTime.value);
    return +Math.floor((+parsedDate - Date.now()) / 1000);
  });

  const timerData = computed<ITimerData>(() => ({
    days: getParsedValue(formatList.days(time.value)),
    hours: getParsedValue(formatList.hours(time.value)),
    minutes: getParsedValue(formatList.minutes(time.value)),
    rawTime: time.value * 1000,
    seconds: getParsedValue(formatList.seconds(time.value)),
  }));

  const interval = ref<TPossibleNull<ReturnType<typeof setInterval>>>(null);

  const setTimeValue = () => {
    if (!interval.value) return;

    if (time.value <= 0) {
      stop();
      return;
    }

    time.value--;
  };

  const start = () => {
    if (time.value <= 0) return;
    if (interval.value) stop();

    setTimeValue();
    interval.value = setInterval(setTimeValue, 1000);
  };

  const stop = () => {
    if (!interval.value) return;

    clearInterval(interval.value);
    interval.value = null;
  };

  onUnmounted(stop);

  watch(
    timeDiff,
    (value) => {
      time.value = Math.max(0, value);
      if (time.value === 0) return;

      start();
    },
    { immediate: true },
  );

  return { start, stop, timerData };
};
