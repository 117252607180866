import { AuthService } from '~/repository/modules/auth';
import { AudioService } from '~/repository/modules/audio';
import CasesService from '~/repository/modules/cases/CasesService';
import { DropFeedService } from '~/repository/modules/dropFeed';
import { UserService } from '~/repository/modules/user';
import BattlesService from '~/repository/modules/battles/BattlesService';
import InventoryService from '~/repository/modules/inventory/inventoryService';
import type WebsocketService from '~/repository/extensions/websocket/WebsocketService';
import { websocketService } from '~/repository/extensions/websocket';
import { BkService, BkSecondService } from '~/repository/modules/bk';
import MainPageService from '~/repository/modules/mainPage/MainPageService';
import { PaymentService } from '~/repository/modules/payment';
import StatisticService from '~/repository/modules/statistic/StatisticService';
import { DailyCasesService } from '~/repository/modules/cases/DailyCasesService';
import { InnerOfferService } from '~/repository/modules/innerOffer';
import { FaqService } from '~/repository/modules/faq';
import LocalizationService from '~/repository/modules/localization/localizationService';
import { ProfileService } from '~/repository/modules/profile/profile.service';

export interface IApiInstance {
  audio: AudioService;
  auth: AuthService;
  battles: BattlesService;
  bk: BkService;
  bkSecond: BkSecondService;
  cases: CasesService;
  dailyCases: DailyCasesService;
  dropFeed: DropFeedService;
  faq: FaqService;
  innerOffer: InnerOfferService;
  inventory: InventoryService;
  localization: LocalizationService;
  mainPage: MainPageService;
  payment: PaymentService;
  profile: ProfileService;
  statistic: StatisticService;
  user: UserService;
  websocket: WebsocketService;
}

export default defineNuxtPlugin(() => {
  const modules: IApiInstance = {
    audio: new AudioService(),
    auth: new AuthService(),
    battles: new BattlesService(),
    bk: new BkService(),
    bkSecond: new BkSecondService(),
    cases: new CasesService(),
    dailyCases: new DailyCasesService(),
    dropFeed: new DropFeedService(),
    faq: new FaqService(),
    innerOffer: new InnerOfferService(),
    inventory: new InventoryService(),
    localization: new LocalizationService(),
    mainPage: new MainPageService(),
    payment: new PaymentService(),
    profile: new ProfileService(),
    statistic: new StatisticService(),
    user: new UserService(),
    websocket: websocketService,
  };

  return {
    provide: {
      api: modules,
    },
  };
});
