/** Метод fixed в обёртке
 * Позволяет обрабатывать ошибки, при отсуствующем значении
 * @param {Number} value - число
 * @param {Number} count - кол-во символов после , | .
 * @return {String} - обрезанная строка
 * **/
const toFixed = (value: number | undefined, count?: number): string => (!value ? '0' : value.toFixed(count));

/** Метод rounded в обёртке
 * Позволяет обрабатывать ошибки, при отсуствующем значении
 * @param {Number} value - число, округленное по условию (0.5 и больше - в большую сторону, меньше - в меньшую)
 * @return {String} - обрезанная строка без десятичных
 * **/
const toRounded = (value: number | undefined): string => (!value ? '0' : Math.round(value).toString());

export default {
  Numbers: {
    toFixed,
    toRounded,
  },
};
