import NumbersUtils from './number.utils';

/** Метод сокращает дробное значение до общепринятых на проекте
 * @param {Number} value - число
 * @return {String} - обрезанная строка
 * **/
const toFixedDefault = (value: number | undefined): string => NumbersUtils.Numbers.toFixed(value, 2);

/** Метод rounded
 * @param {Number} value - число, округленное по условию (0.5 и больше - в большую сторону, меньше - в меньшую)
 * @return {String} - обрезанная строка без десятичных значений
 * **/
const toRoundedDefault = (value: number | undefined): string => NumbersUtils.Numbers.toRounded(value);

/**
 * Форматирует переданную цену, добавляя разделители тысяч для больших чисел
 * и округляя десятичные значения для меньших чисел.
 *
 * Правила форматирования:
 * - Если длина целой части больше 5 символов, число округляется до целого
 *   и добавляются разделители тысяч (например, '1000.856' → '1 000').
 * - Если длина целой части меньше или равна 5 символам, добавляется до двух десятичных знаков.
 *   Если длина целой части ровно 5 символов, сохраняется один десятичный знак.
 *
 * @param {string | number} price - Цена для форматирования. Может быть строкой или числом.
 * @returns {string} - Отформатированная цена в виде строки с разделителями тысяч и ограничением на количество десятичных знаков.
 */
const formatPrice = (price: string | number): string => {
  if (Number.isNaN(Number(price))) return String(price);
  const priceStr = String(price);
  const [integerPart, decimalPart] = priceStr.split('.');

  const formattedInteger = Number(integerPart).toLocaleString();
  const formattedIntegerLength = integerPart.length;

  const isPriceLengthCapReached = formattedIntegerLength > 4;

  if (isPriceLengthCapReached) {
    return formattedInteger;
  }

  if (decimalPart) {
    const truncatedDecimal = decimalPart.slice(0, formattedIntegerLength === 4 ? 1 : 2);
    return `${formattedInteger}.${truncatedDecimal}`;
  }

  return formattedInteger;
};

export default {
  Prices: {
    toFixedDefault,
    toRoundedDefault,
    formatPrice,
  },
};
