import type { Property } from 'csstype';
import type { LinearGradientConfiguration } from '~/types/deprecated.types';

export type DefaultType = 'default';

export type TFontCase = 'upper' | 'lower' | 'capitalize';

export const enum LineHeightSizes {
  EXTRA_LARGE = 'x',
  LARGE = 'l',
  MEDIUM = 'm',
}

export const enum FontSizes {
  // 16px
  LARGE = 'l',
  // 24px
  LARGE_2XL = '2xl',
  // 32px
  LARGE_3XL = '3xl',
  // 20px
  LARGE_XL = 'xl',
  // 14px
  MEDIUM = 'm',
  // 12px
  SMALL = 's',
  // 8px
  SMALL_2XS = '2xs',
  // 10px
  SMALL_XS = 'xs',
}

export const enum FontFamily {
  QUANTICO = 'Quantico',
  RADIANCE = 'Radiance',
  REAVER = 'Reaver',
  ROBOTO = 'Roboto',
}

export const enum FontSizesNumber {
  // 16px
  LARGE = 16,
  // 24px
  LARGE_2XL = 24,
  // 32px
  LARGE_3XL = 32,
  // 20px
  LARGE_XL = 20,
  // 14px
  MEDIUM = 14,
  // 12px
  SMALL = 12,
  // 8px
  SMALL_2XS = 8,
  // 10px
  SMALL_XS = 10,
}

export type HeadingTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type CommonTextTags = 'div' | 'span' | 'p';

export const enum FontWeights {
  THIN = 100,
  EXTRA_LIGHT = 200,
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  SEMIBOLD = 600,
  BOLD = 700,
  EXTRA_BOLD = 800,
  BLACK = 900,
}

export type TTextFontSize = FontSizes | Property.FontSize | DefaultType | number;
export type TTextWeight = FontWeights | Property.FontWeight | number;

export interface ITextProps {
  align?: Property.TextAlign;
  alignItems?: Property.AlignItems;
  as?: HeadingTags | CommonTextTags;
  color?: Property.Color;
  cursor?: Property.Cursor;
  display?: Property.Display;
  fontCase?: TFontCase;
  fontFamily?: Property.FontFamily;
  fontStyle?: Property.FontStyle;
  gap?: Property.Gap | number;
  gradient?: LinearGradientConfiguration | string;
  hoverColor?: Property.Color;
  // плавная анимация, on/off transition
  isSmoothAnimation?: boolean;
  justifyContent?: Property.JustifyContent;
  letterSpacing?: Property.LetterSpacing | number;
  lineHeight?: LineHeightSizes | Property.LineHeight | number;
  maxWidth?: Property.MaxWidth | number;
  minWidth?: Property.MinWidth | number;
  opacity?: number;
  overflow?: Property.Overflow;
  // automatic text-shadow on text according to text color
  shadowOnHover?: boolean;
  size?: TTextFontSize;
  textOverflow?: Property.TextOverflow;
  textShadow?: Property.TextShadow;
  weight?: TTextWeight;
  wordWrap?: Property.WordWrap;
  wrap?: Property.WhiteSpace;
}
