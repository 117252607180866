import ApiHttp from '~/api/global/http/ApiHttp';
import type { TPossibleNull } from '~/types/Shared.types';
import type {
  TGetInventoryRequestDTO,
  TGetInventoryResponseDTO,
  TReplaceInventoryItemResponseDTO,
  TInventorySendItemResponseDTO,
  IReplaceInventoryItemRequestDTO,
  TGetReplacementInventoryItemsRequestDTO,
  TGetReplacementInventoryItemsResponseDTO,
  IUnpackItemsResponseDTO,
  IUnpackItemsRequestDTO,
} from '~/repository/modules/inventory/inventory.types';

type TQueryParams = Record<string, string | number>;

export default class InventoryHttp extends ApiHttp {
  private readonly inventoryApi: string = '/v1/inventory';

  // Обработка GET запросов с query параметрами
  private async getWithParams<T>(endpoint: string, params: TQueryParams): Promise<TPossibleNull<T>> {
    const queryString = this.query.serialise(params);
    const data = await this.$authApi.get<{ data: T }>(`${this.inventoryApi}${endpoint}${queryString}`);
    return (data as T) || null;
  }

  private async postWithBody<T>(endpoint: string, body: Record<string, unknown>, mapBeforeSend = false): Promise<T> {
    return await this.$authApi.post<T>(`${this.inventoryApi}${endpoint}`, {
      body,
      mapBeforeSend,
    });
  }

  // Получаем инвентарь
  public async getInventory(params: TGetInventoryRequestDTO): Promise<TPossibleNull<TGetInventoryResponseDTO>> {
    return await this.getWithParams<TGetInventoryResponseDTO>('/get', { ...params });
  }

  // Продаем выбранные предметы (предметы с параметром isSelected: true;)
  public async sellManyInventory(ids: number[]) {
    await this.postWithBody('/sell/many', { ids });
  }

  // Продаем конкретный предемет
  public sellItem = () => {};

  // Продаем все предметы
  public async sellAllItems() {
    await this.postWithBody('/sell/all', {});
  }

  // Продаем drop
  public async sellDrop(id: string) {
    await this.postWithBody('/sell', { id });
  }

  // Обмениваем предмет
  public async tradeItem(id: number) {
    return await this.$authApi.get(`${this.inventoryApi}/${id}/trade_id`);
  }

  // Выводим предмет
  public async sendItem(ids: number[], method?: string): Promise<TInventorySendItemResponseDTO> {
    return await this.postWithBody<TInventorySendItemResponseDTO>('/senditem', { ids, method });
  }

  // Получить список предметов под замену
  public getReplacementItems(
    params: TGetReplacementInventoryItemsRequestDTO,
  ): Promise<TPossibleNull<TGetReplacementInventoryItemsResponseDTO>> {
    return this.getWithParams<TGetReplacementInventoryItemsResponseDTO>('/get/replacements', {
      ...GlobalUtils.Objects.snakeCaseParams(params),
    });
  }

  // Заменяем предмет
  public async replaceItem(params: IReplaceInventoryItemRequestDTO): Promise<TReplaceInventoryItemResponseDTO> {
    return await this.postWithBody<TReplaceInventoryItemResponseDTO>('/replacement', {
      ...GlobalUtils.Objects.snakeCaseParams(params),
    });
  }

  // Распаковка набора
  public unpackItems(params: IUnpackItemsRequestDTO): Promise<IUnpackItemsResponseDTO> {
    return this.$authApi.post(this.inventoryApi + '/packs/open', {
      body: GlobalUtils.Objects.snakeCaseParams(params),
    });
  }
}
