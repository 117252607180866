<template>
  <div class="content">
    <div class="content-top">
      <Badge v-if="isShowBadge" class="content-badge" :bg-color="labelColor" :text="labelText" />
      <NuxtImg v-if="image" class="content-img" :src="image" />
    </div>
    <div class="content-middle info">
      <div class="info-top">
        <div class="info-top__text">
          <h2 class="content-title">{{ title }}</h2>
          <p class="content-description">{{ description }}</p>
        </div>
        <div class="info-top__price">
          <Price :price="priceData.price" :old-price="priceData.oldPrice" :discount="priceData.sale" />
          <div class="offer-name">
            {{ offerName }}
          </div>
        </div>
      </div>
      <div class="info-middle">
        <div class="offer-get">
          <div class="offer-get_title">{{ $t('lostLocales.youOffer') }}</div>
          <div class="offer-get_line"></div>
        </div>
        <div class="you-get">
          <PriceComponent :price-with-coins="priceWithCoins" />
          <CaseComponent v-for="item in caseData" :key="item.id" :case-data="item" />
        </div>
      </div>

      <SharedKitButton
        :color-preset="ColorPresets.PRIMARY"
        :size-preset="actionButtonsSize"
        :type-preset="TypePresets.RECTANGLE"
        :text="buttonText"
        class="info-button"
        :link-props="{ target: '_blank', to: ROUTING.SECRETSHOP.MAINPAGE.path }"
        @click="emits('pressed-buy')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Badge from '../Badge/Badge.vue';
import CaseComponent from '../CaseComponent/CaseComponent.vue';
import Price from '../Price/Price.vue';
import PriceComponent from '../PriceComponent/PriceComponent.vue';
import { colorStyle } from './InnerOfferContent.data';
import type { IInnerOfferEmits, IInnerOfferProps } from './InnerOfferContent.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

const props = defineProps<IInnerOfferProps>();
const emits = defineEmits<IInnerOfferEmits>();

const { t } = useI18n();

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const actionButtonsSize = computed(() => changeXL(SizePresets.L, SizePresets.M));

const isShowBadge = computed(() => props.labelData && props.labelData.label !== '0' && labelText.value);

const labelText = computed(() => {
  if (props.labelData.label === 'Другое') return props.labelData.labelName;

  const localeText = t(`innerOfferLabel.${props.labelData.label}`);
  // если есть ключ, то будет значение подставляться, иначе строка останется нетронутой
  return !localeText.includes('innerOfferLabel') ? t(`innerOfferLabel.${props.labelData.label}`) : '';
});

const labelColor = computed(() =>
  !!props.labelData.labelColor && props.labelData.labelColor !== '0' ? props.labelData.labelColor : 'default',
);

const buttonText = t('innerOfferBuy');
</script>

<style src="./InnerOfferContent.scss" scoped lang="scss"></style>

<style scoped lang="scss">
.content {
  --bg-color: v-bind('colorStyle.bgColor');
}
</style>
