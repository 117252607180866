import WebsocketHttp from '~/repository/extensions/websocket/WebsocketHttp';
import type { ISubscriberWebsocket, IWebsocketSample } from '~/api/global/webosket/websocket.types';

export default class WebsocketService {
  private readonly websocketHttp: WebsocketHttp;

  constructor() {
    this.websocketHttp = new WebsocketHttp();
  }

  public connect(url: string, key: string, Sample: IWebsocketSample): void {
    if (this.isHasKeyInActualConnect(key)) return this.errorLog();
    const sample = new Sample();
    this.websocketHttp.connect(url, key, sample);
  }

  public disconnect(key: string): void {
    if (!this.isHasKeyInActualConnect(key)) return this.errorLog();
    this.websocketHttp.disconnect(key);
  }

  public subscribe(key: string, sub: ISubscriberWebsocket) {
    if (!this.isHasKeyInActualConnect(key)) {
      this.errorLog();
      return (() => {}) as () => boolean;
    }
    return this.websocketHttp.subscribe(key, sub);
  }

  public sendMessage(key: string, event: string, payload: Record<string, unknown>) {
    if (!this.isHasKeyInActualConnect(key)) return this.errorLog();
    return this.websocketHttp.sendMessage(key, event, payload);
  }

  public checkConnection(key: string) {
    return this.isHasKeyInActualConnect(key);
  }

  // Проверка наличия соединения
  private isHasKeyInActualConnect(key: string): boolean {
    return !!this.websocketHttp.connections[key];
  }

  private errorLog() {}
}
