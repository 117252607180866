import BkSecondHttp from '~/repository/modules/bk/BkSecondHttp';
import type {
  ICreateAuthUrlArg,
  ISendItemArgs,
  IReplaceItemArgs,
  ISellItemArgs,
  IGetUserArgs,
} from '~/repository/modules/bk/bk.types';

export default class BkSecondService {
  private readonly bkSecondHttp: BkSecondHttp;

  constructor() {
    this.bkSecondHttp = new BkSecondHttp();
  }

  public getUser<T>(args: IGetUserArgs) {
    return this.bkSecondHttp.getUser<T>(args);
  }

  public async setSteamLogin<T>(steamLogin: string, token: string, args: Partial<IGetUserArgs>) {
    return await this.bkSecondHttp.setSteamLogin<T>(steamLogin, token, args);
  }

  public async setSteamTradeLink<T>(steamTradeUrl: string, token: string, args: Partial<IGetUserArgs>) {
    return await this.bkSecondHttp.setSteamTradeLink<T>(steamTradeUrl, token, args);
  }

  public sellItemInventory<T>(args: ISellItemArgs) {
    return this.bkSecondHttp.sellItemInventory<T>(args);
  }

  public sellAllItemInventory<T>(args: ICreateAuthUrlArg) {
    return this.bkSecondHttp.sellAllItemInventory<T>(args);
  }

  // Замена предмета
  public replaceItem<T>(args: IReplaceItemArgs) {
    return this.bkSecondHttp.replaceItem<T>(args);
  }

  // inventory
  public getNewInventory<T>(arg: ICreateAuthUrlArg, page: number) {
    return this.bkSecondHttp.getNewInventory<T>(arg, page);
  }

  public sendItem<T>(args: ISendItemArgs) {
    return this.bkSecondHttp.sendItem<T>(args);
  }

  public getOldInventory<T>(arg: ICreateAuthUrlArg, page: number) {
    return this.bkSecondHttp.getOldInventory<T>(arg, page);
  }
}
