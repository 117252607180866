const volumesList = {
  caseEnlarge: async () => (await import('~/assets/volume/case_final_drop.mp3')).default || '',
  caseFireblastTarget: async () => (await import('~/assets/volume/case_fireblast_target.mp3')).default || '',
  caseFireblastX2: async () => (await import('~/assets/volume/case_fireblast_x2.mp3')).default || '',
  caseFireblastX3: async () => (await import('~/assets/volume/case_fireblast_x3.mp3')).default || '',
  caseFireblastX4: async () => (await import('~/assets/volume/case_fireblast_x4.mp3')).default || '',
  caseMulticast1: async () => (await import('~/assets/volume/case_multicast1.mp3')).default || '',
  caseMulticast2: async () => (await import('~/assets/volume/case_multicast2.mp3')).default || '',
  caseMulticast3: async () => (await import('~/assets/volume/case_multicast3.mp3')).default || '',
  caseMulticastX2: async () => (await import('~/assets/volume/case_multicast_x2.mp3')).default || '',
  caseMulticastX3: async () => (await import('~/assets/volume/case_multicast_x3.mp3')).default || '',
  caseMulticastX4: async () => (await import('~/assets/volume/case_multicast_x4.mp3')).default || '',
  caseOpen: async () => (await import('~/assets/volume/case_open.mp3')).default || '',
  casePopUp: async () => (await import('~/assets/volume/case_final_pop_up_open.mp3')).default || '',
  caseReveal: async () => (await import('~/assets/volume/case_reveal.mp3')).default || '',
  caseScroll: async () => (await import('~/assets/volume/case_scroll.mp3')).default || '',
  dropDown: async () => (await import('~/assets/volume/drop-down.mp3')).default || '',
  dropShow: async () => (await import('~/assets/volume/drop-show.mp3')).default || '',
  fireShield: async () => (await import('~/assets/volume/fire_shield.mp3')).default || '',
  multicastBoom: async () => (await import('~/assets/volume/multicast/boom.mp3')).default || '',
  multicastDrop: async () => (await import('~/assets/volume/multicast-drop.mp3')).default || '',
  multicastFan: async () => (await import('~/assets/volume/multicast-fan.mp3')).default || '',
  multicastFireBlast: async () => (await import('~/assets/volume/multicast/fireblast_cast.mp3')).default || '',
  multicastLaugh: async () => (await import('~/assets/volume/multicast/ogmag_laugh_01.mp3')).default || '',
  multicastOops: async () => (await import('~/assets/volume/multicast/oops.mp3')).default || '',
  multicastTreasureCount: async () => (await import('~/assets/volume/multicast/treasure_count.mp3')).default || '',
  multicastX2Win: async () => (await import('~/assets/volume/multicast/x2-win.mp3')).default || '',
  multicastX3Lose: async () => (await import('~/assets/volume/multicast/x3-lose.mp3')).default || '',
  multicastX3Win: async () => (await import('~/assets/volume/multicast/x3-win.mp3')).default || '',
  multicastX4Lose: async () => (await import('~/assets/volume/multicast/x4-lose.mp3')).default || '',
  techiesClick: async () => (await import('~/assets/volume/techies-click.mp3')).default || '',
  techiesLose: async () => (await import('~/assets/volume/techies-lose.mp3')).default || '',
  techiesWin: async () => (await import('~/assets/volume/techies-win.mp3')).default || '',
};

const volumesKeys: Record<string, string> = {};
Object.keys(volumesList).forEach((key) => (volumesKeys[key] = key));

export { volumesKeys, volumesList };
