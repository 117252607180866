import type { ILocalizationNitroResponse } from '~/server/libs/localization/localization.types';
import type { TPossibleNull } from '~/types/Shared.types';

export default class LocalizationRepository {
  static async getLocalization(): Promise<TPossibleNull<ILocalizationNitroResponse>> {
    try {
      return await $fetch<ILocalizationNitroResponse>('/nitro-api/localization');
    } catch {
      return null;
    }
  }
}
