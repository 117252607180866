import type { ISharedKitButtonColorPreset } from '~/components/SharedKitButton/SharedKitButton.types';

type TColorPresetsKeys = 'DEFAULT';

export const GeneralAuthButtonPreset: Record<TColorPresetsKeys, ISharedKitButtonColorPreset> = {
  DEFAULT: {
    defaultBackground: 'rgb(242 242 242)',
    hoverBackground: 'rgba(205 203 207)',
    loadingColor: 'rgba(31 31 31 / 0.3)',
    textColor: 'rgb(31 31 31)',
  },
};
