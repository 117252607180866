export enum EInnerOfferEvents {
  BUTTON_CLICKED = 'Trigger - Buy Button Clicked',
  POPUP_SHOWN = 'Trigger - Pop-up Shown',
}

export interface IInnerOfferButtonClickedEvent {
  'Offer Name': string;
  'Offer Price': number;
  'Trigger Type': string;
}

export interface IInnerOfferPopupShownEvent {
  'Offer Name': string;
  'Offer Price': number;
  'Trigger Type': string;
}

export interface IAmplitudeData {
  'Offer Name': string;
  'Offer Price': number;
  'Trigger Type': string;
}
