import { skipHydrate } from 'pinia';
import type { IRoundInBattle, ISkinItemInBattle } from '~/repository/modules/battles/BattlesGeneral.types';
import { EBattleStatuses } from '~/repository/modules/battles/BattlesGeneral.types';
import type { IBattlesBattleFullInfoEntity } from '~/repository/modules/battles';
import type { TPossibleNull } from '~/types/Shared.types';
import { useAlertStore } from '~/store/alert/alert.store';
import { BattlesSpinnerTimeouts } from '~/features/battles/constants/rules';
import { useVolumeStore } from '~/store/volume/volume';
import type { IBattlesCaseItemInShortBattle, IRoundSkin } from '~/features/battles/types/battlesStore.types';
import { useUserStore } from '~/store/user/user.store';
import { useBattlesDataStateStore } from '~/features/battles/store/dataState.store';
import { EBattlesStoreNames } from '~/features/battles/constants/general';
import { volumesKeys } from '~/store/volume/volumes.data';
import type { IBattleUpdateSocket } from '~/features/battles/types/webSocketData';
import { BattleEvents } from '~/repository/amplitude/events/battle';

/* Хранилище одного батла, который пользователь открыл по id */
export const useSingleBattleStore = defineStore(EBattlesStoreNames.BATTLE, () => {
  /* -- Imports -- */

  /* Импорт апи модуля */
  const {
    $api: { battles: BattlesApiService },
    $i18n: { t },
  } = useNuxtApp();

  /* Импорт сторов */
  const userStore = useUserStore();
  const volumeStore = useVolumeStore();
  const alertStore = useAlertStore();
  const dataStateStore = useBattlesDataStateStore();

  /* -- Initialisation -- */
  /* Полная информация о текущем батле */
  const battle = ref<IBattlesBattleFullInfoEntity>();
  /* Кейсы, участвующие в батле, с их подробной информацией (могут повторяться) */
  const battleCases = computed<IBattlesCaseItemInShortBattle[]>(() => parseShortCases());
  /* Ошибка при запросе */
  const hasFetchError = ref<boolean>(false);
  /* Первый ли запрос на получения батла */
  const isFromCreation = ref(false);
  /* Подключение из таблицы */
  const joiningFromTable = ref(false);
  /* Сущности батла при разных статусах */
  const entities: Record<
    EBattleStatuses.CREATED | EBattleStatuses.STARTED | EBattleStatuses.FINISHED | EBattleStatuses.CANCELLED,
    TPossibleNull<IBattlesBattleFullInfoEntity>
  > = reactive(createEntities());
  /* Состояние процесса батла */
  const processState = reactive(createBattleState());
  /* Аниматор */
  const animator = reactive(createAnimatorState());
  /* Тип батла, для работы с сокетами */
  const type = ref<keyof typeof entities>(EBattleStatuses.CREATED);
  /* Колбеки на разных этапах процесса батла */
  const callbacks = reactive(createCallbacks());
  /* Был ли батл отменен хостом */
  const cancelByHost = ref(false);

  /* -- Getters -- */

  /* Состояние батла  */
  const battleState = computed<Record<keyof typeof entities, boolean>>(() => ({
    cancelled: battle.value?.status === EBattleStatuses.CANCELLED,
    created: battle.value?.status === EBattleStatuses.CREATED,
    finished: battle.value?.status === EBattleStatuses.FINISHED,
    started: battle.value?.status === EBattleStatuses.STARTED,
  }));

  /* Полная стоимость батла */
  const battlePriceSummary = computed(() => battleCases.value.reduce((acc, item) => acc + item.price, 0));

  /* Все предметы, участвующие в текущем батле */
  const battleAllItems = computed<Map<number, ISkinItemInBattle>>(() => {
    return (battle.value?.items || []).reduce((acc: Map<number, ISkinItemInBattle>, item) => {
      acc.set(item.id, item);
      return acc;
    }, new Map());
  });

  /* Раунды в текущем батле с дополнительной информацией */
  const battleRounds = computed<IRoundInBattle[]>(() => battle.value?.rounds || []);

  /* Текущий раунд начавшегося батла */
  const battleActiveRound = computed<TPossibleNull<IRoundInBattle>>(
    () => battleRounds.value.find((round) => round.number === animator.round) || null,
  );

  /* Текущий раунд начавшегося батла */
  const battleActiveMultifix = computed(() => {
    const activeCase = battleCases.value.find((findCase) => findCase.id === battleActiveRound.value?.caseId);
    if (!activeCase || (!activeCase.multicastRange && !activeCase.multifix)) return null;
    return activeCase.multicastRange ? 'multicast' : 'multifix';
  });

  /* Массив предметов, участвующих в текущем раунде, с полной информацией о них */
  const activeRoundItems = computed<IRoundSkin[]>(() => {
    const activeRound = battleActiveRound.value;
    if (!activeRound || !activeRound?.items) return [];

    return activeRound.items.map(({ itemId, price }) => ({
      ...(battleAllItems!.value.get(itemId) as IRoundSkin),
      price,
    }));
  });
  // Сумма наград раунда для расчета цветных полос итога раунда
  const activeRoundPrizesSum = computed(() => {
    const activeRound = battleActiveRound.value;

    if (activeRound && activeRound.prizes) {
      let roundDataMaxSum = 0;
      activeRound.prizes.forEach((player) => {
        if (!player.item) return;
        roundDataMaxSum += player.item.price;
      });

      return roundDataMaxSum;
    }
  });

  /* Возвращает интервал возможных коинов */
  const battleCoinsSummary = computed(() => {
    let firstCoinNumber = 0;
    let secondCoinNumber = 0;

    battleCases.value.forEach((oneCase) => {
      if (oneCase.coins && typeof oneCase.coins[0] === 'number') {
        firstCoinNumber += oneCase.coins[0];
        secondCoinNumber += oneCase.coins[1];
      }
    });
    return [firstCoinNumber, secondCoinNumber];
  });

  /* -- Actions -- */
  /* Преобразуем кейсы, содержащие краткую информацию, в кейсы с полной информацией */
  const parseShortCases = () => {
    if (!battle.value || !battle.value?.cases || !battle.value?.cases?.length) return [];

    const casesResult: IBattlesCaseItemInShortBattle[] = [];

    battle.value!.cases.forEach(({ amount, data }) => {
      for (let i = 0; i < (amount || 0); i++) {
        casesResult.push({ ...data, amount: 1 } as IBattlesCaseItemInShortBattle);
      }
    });

    return casesResult;
  };

  /* Функция для оповещения о выходе */
  const closePage = async () => {
    if (!battle.value) return;
    try {
      const { spectators } = await BattlesApiService.closeBattle({
        battleUlid: battle.value!.ulid,
      });

      battle.value && (battle.value!.spectators = spectators);
    } catch {}
  };

  /* Функция для оповещения о входе */
  const openPage = async () => {
    if (!battle.value) return;
    try {
      const { spectators } = await BattlesApiService.openBattle({
        battleUlid: battle.value!.ulid,
      });

      battle.value && (battle.value!.spectators = spectators);

      BattleEvents.viewerEntered();
    } catch {}
  };

  /* Функция для выхода игрока ( обрабатываем возможные ошибки, где используем данную функцию ) */
  const leaveBattle = async (battleId: string, battlePrice: number) => {
    try {
      const result = await BattlesApiService.leaveBattle({
        battleUlid: battleId,
      });

      BattleEvents.participantLeft({ 'Battle Price': battlePrice });

      if (result.balance) {
        userStore.setNewBalance(result.balance);
      }

      dataStateStore.myState = {
        activeBattleUlid: null,
        fetched: true,
        inBattle: false,
      };
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: t('profile.errors.battlesCancel'),
      });
    }
  };

  /* Функция получения первого доступного раунда относительно текущей временной метки */
  const getFirstAvailableRoundNumber = (now: number, result: IBattlesBattleFullInfoEntity) => {
    if (joiningFromTable.value) {
      joiningFromTable.value = false;
      return 1;
    }

    if (processState.isStartedFromVeryBeginning) return 0;

    const roundByTime = result.rounds.find(
      (round) => round.finishedAt && Math.abs(round.finishedAt - now) <= BattlesSpinnerTimeouts.defaultSpinner,
    );
    if (roundByTime) return roundByTime.number;

    const firstRound = result.rounds.find((round) => !round.finishedAt);
    return firstRound?.number ?? 1;
  };

  const isFetching = ref(false);

  const fetchBattle = async (battleId: string) => {
    battle.value = undefined;
    isFetching.value = true;

    try {
      const result = await BattlesApiService.getFullBattleInfo({ battleUlid: battleId });

      if (!result) throw new Error(t('alerts.battleWasNotFound'));

      if (result.cancellationReason) {
        return alertStore.showError({
          title: result.cancellationReason,
        });
      }
      battle.value = result;
      type.value = result.status;
      if (result.status === EBattleStatuses.CREATED) {
        processState.isBattleAtStartPoint = true;
        joiningFromTable.value = false;
      }

      if (result.status === EBattleStatuses.STARTED) {
        const firstShowRound = getFirstAvailableRoundNumber(Date.now(), result);
        animator.round = firstShowRound - 1;
        run();
      }

      if (result.status === EBattleStatuses.FINISHED) {
        animator.round = result.rounds.length;
        animator.last = true;
        animator.played = true;
      }
    } catch {
      hasFetchError.value = true;
    } finally {
      isFetching.value = false;
    }
  };

  /* Функция для обработки сокета для батла */
  function handleBattleListener(data: IBattleUpdateSocket) {
    if (!battle.value) return;
    if (battleState.value.finished) return;

    // Проверка на валидность батла
    if (!data || !data.battle) return;
    // Проверка на нужный батл
    if (battle.value.ulid !== data.battle.ulid) return;

    // Имеется ли хоть 1 подсчитанный раунд
    const emptyResults = checkNextRoundResultsExistence(data.battle, animator.round + 1);

    if (type.value === EBattleStatuses.CREATED) {
      battle.value = data.battle;
      processState.isValidToStart = !emptyResults;
    }

    if (emptyResults && data.battle.status === EBattleStatuses.STARTED && !processState.isValidToStart) {
      // processState.isValidToStart = true;
    }

    if (data.battle.status === EBattleStatuses.CANCELLED) {
      const localeRoute = useLocaleRoute();
      navigateTo(localeRoute(ROUTING.BATTLES.CREATE));

      if (!cancelByHost.value) {
        alertStore.showError({
          message: data.battle.cancellationReason || t('alerts.battleCanceledByAdmin'),
          title: t('alerts.reasonOfBattlesCancellation'),
        });
      }

      cancelByHost.value = false;
    }

    if (data.battle.status === EBattleStatuses.FINISHED) {
      entities.started = {
        ...data.battle,
        status: EBattleStatuses.STARTED,
      };
    }

    entities[data.battle.status] = data.battle;
    if (processState.isValidToStart || (processState.isInterruptedByEmptyNextRound && !emptyResults)) {
      run();
    }
  }

  const checkNextRoundResultsExistence = (battleEntity: IBattlesBattleFullInfoEntity, whatRoundToCheck: number) => {
    return !!battleEntity.rounds.find((round) => !round.finishedAt && round.number === whatRoundToCheck);
  };

  const run = () => {
    if (!battle.value) return;

    let battleValue = battle.value!;
    const currentRoundNumber = animator.round;

    if (currentRoundNumber === 0 && processState.isValidToStart && entities.started) {
      if (currentRoundNumber + 1 === battleValue.rounds.length) {
        return runBattle(true);
      }
      return runBattle();
    }
    if (currentRoundNumber === battleValue.rounds.length) {
      if (!entities.finished && entities.started) {
        entities.finished = { ...entities.started, status: EBattleStatuses.FINISHED };
      }
      return endBattle();
    }

    battleValue = assignNewBattleValue() as IBattlesBattleFullInfoEntity;

    const nextRound = battleValue.rounds.find((round) => round.finishedAt && round.number === currentRoundNumber + 1);
    if (nextRound) {
      processState.isInterruptedByEmptyNextRound = false;
      return runRound(nextRound.number, nextRound.number === battleValue.rounds.length);
    }

    if (!nextRound && currentRoundNumber !== battleValue.rounds.length) {
      processState.isInterruptedByEmptyNextRound = true;
    }
  };

  const runBattle = (isLast = false) => {
    executeCallbacks(callbacks.beforeBattleStart);
    type.value = EBattleStatuses.STARTED;

    assignNewBattleValue();

    processState.isStartedFromVeryBeginning = true;
    processState.isBattleAtStartPoint = false;
    processState.isRunning = true;
    processState.isValidToStart = false;
    processState.isInterruptedByEmptyNextRound = false;

    executeCallbacks(callbacks.afterBattleStart);

    runRound(1, isLast);
  };

  const endBattle = () => {
    executeCallbacks(callbacks.beforeBattleEnd);

    type.value = EBattleStatuses.FINISHED;

    assignNewBattleValue();

    processState.isPlayed = true;
    processState.isRunning = false;

    executeCallbacks(callbacks.afterBattleEnd);
  };

  const runRound = (nextRound: number, isLast = false) => {
    executeCallbacks(callbacks.beforeRoundStart);

    nextTick(() => {
      animator.round = nextRound;
      animator.played = false;
      animator.last = isLast;
      animator.duration = BattlesSpinnerTimeouts.defaultSpinner;

      if (!volumesKeys.caseOpen) return;
      volumeStore.playVolume(volumesKeys.caseOpen);
    });
  };

  const endRoundAnimator = () => {
    animator.played = true;
    animator.duration = 0;
  };

  const loadNext = () => {
    endRoundAnimator();
    run();
  };

  const executeCallbacks = (callbacks: (() => void)[] = []) => {
    callbacks.forEach((cb) => {
      if (typeof cb === 'function') cb();
    });
  };

  const assignNewBattleValue = () => {
    if (entities[type.value]) {
      battle.value = entities[type.value] as IBattlesBattleFullInfoEntity;
      return entities[type.value];
    }
    return battle.value;
  };

  const memberInLastRound = () => {
    if (!battle.value?.draw) return;

    const lastRoundMembersId: number[] = [];
    const lastRound = battleRounds.value.slice(-1);

    lastRound[0].results.forEach((result) => lastRoundMembersId.push(result.memberId));
    return lastRoundMembersId;
  };

  /* Продажа дропа */
  const sellWinnings = async () => {
    if (!battle.value) return;

    await BattlesApiService.sellBattleWinnings({
      battleUlid: battle.value!.ulid,
    });
  };

  const $reset = () => {
    battle.value = undefined;
    hasFetchError.value = false;
    processState.isStartedFromVeryBeginning = false;

    type.value = EBattleStatuses.CREATED;

    Object.assign(entities, createEntities());
    Object.assign(animator, createAnimatorState());
    Object.assign(processState, createBattleState());
    Object.assign(callbacks, createCallbacks());
  };

  /* -- Returns -- */
  return {
    $reset,
    activeRoundItems,
    activeRoundPrizesSum,
    battle: skipHydrate(battle),
    battleActiveMultifix,
    battleActiveRound,
    battleAllItems,
    battleAnimator: animator,
    battleCases: skipHydrate(battleCases),
    battleCoinsSummary,
    battlePriceSummary,
    battleRounds,
    battleState,
    callbacks,
    cancelByHost,
    closePage,
    entities,
    fetchBattle,
    handleBattleListener,
    hasFetchError,
    isFromCreation,
    joiningFromTable,
    leaveBattle,
    loadNext,
    memberInLastRound,
    openPage,
    processState,
    sellWinnings,
  };
});

function createBattleState() {
  return {
    // Батл в начальной точке (0ой раунд)
    isBattleAtStartPoint: false,
    // Батл начинается с первого раунда и вот-вот начнется, ждем первого подсчитанного раунда
    isBattleToBeStarted: false,
    // Был ли батл прерван недостатком подсчитанных раундов
    isInterruptedByEmptyNextRound: false,
    // Сыгран ли батл
    isPlayed: false,
    // Играется ли батл сейчас
    isRunning: false,
    // Начат ли бытл с самого начала
    isStartedFromVeryBeginning: false,
    // Валиден ли батл, чтобы начать 1ый раунд
    isValidToStart: false,
  };
}

function createAnimatorState() {
  return {
    // Длительность раунда (без установки этого параметра, раунд не запустится)
    duration: 0,
    // Крутится ли последний раунд
    last: false,
    // Сыгран ли текущий раунд
    played: true,
    // Номер текущего раунда (начинается с 1 (не с 0) и так до === battle.value.rounds.length)
    round: 0,
  };
}

function createEntities() {
  return {
    [EBattleStatuses.CREATED]: null,
    [EBattleStatuses.STARTED]: null,
    [EBattleStatuses.FINISHED]: null,
    [EBattleStatuses.CANCELLED]: null,
  };
}

function createCallbacks() {
  return {
    // Массив функций, выполняющийся после конца батла
    afterBattleEnd: [],
    // Массив функций, выполняющийся после начала батла
    afterBattleStart: [],
    // Массив функций, выполняющийся до конца батла
    beforeBattleEnd: [],
    // Массив функций, выполняющийся до начала батла
    beforeBattleStart: [],
    // Массив функций, выполняющийся перед началом КАЖДОГО раунда
    beforeRoundStart: [],
  } as {
    afterBattleEnd: (() => void)[];
    afterBattleStart: (() => void)[];
    beforeBattleEnd: (() => void)[];
    beforeBattleStart: (() => void)[];
    beforeRoundStart: (() => void)[];
  };
}
