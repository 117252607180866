import { ProfileHttp } from './profile.http';
import type {
  IGetSatDashboardParams,
  IGetStatBattlesParams,
  IBaseStatRequestParams,
} from '~/repository/modules/profile/types/statistics';
import type {
  IGetProfileBattlesParams,
  IGetProfileBaseParams,
  IGetProfileWinrateParams,
} from '~/repository/modules/profile/types/battles';
import type { TGetProfileUpgradesRequestDto } from '~/repository/modules/profile/types/upgrades';
import type { IStatGeneralState } from '~/features/profile/store/profileStatGeneral/profileStatGeneral.types';

export class ProfileService {
  private readonly profileHttp = new ProfileHttp();

  public generateLevel() {
    return this.profileHttp.generateLevel();
  }

  public getStatBattles(params: IGetStatBattlesParams) {
    return this.profileHttp.getStatBattles(params);
  }

  public async getStatDashboard(params: IGetSatDashboardParams): Promise<IStatGeneralState | undefined> {
    const response = await this.profileHttp.getStatDashboard(params);
    if (!response.status) return;

    return {
      ...response.data,
      battlesCount: formatNumber(response.data.battlesCount, { dashIfZero: true }),
      inventoryCount: formatNumber(response.data.inventoryCount, { dashIfZero: true }),
      inventoryActiveCount: formatNumber(response.data.inventoryActiveCount, { dashIfZero: true }),
      sendItemsCount: formatNumber(response.data.sendItemsCount, { dashIfZero: true }),
      upgradesCount: formatNumber(response.data.upgradesCount, { dashIfZero: true }),
    };
  }

  public getStatUpgrades(params: IBaseStatRequestParams) {
    return this.profileHttp.getStatUpgrades(params);
  }

  public getBattles(params: IGetProfileBattlesParams) {
    return this.profileHttp.getBattles(params);
  }

  public getBestBattle(params: IGetProfileBaseParams) {
    return this.profileHttp.getBestBattle(params);
  }

  public getUpgrades(params: TGetProfileUpgradesRequestDto) {
    return this.profileHttp.getUpgrades(params);
  }

  public getWinrate(params: IGetProfileWinrateParams) {
    return this.profileHttp.getWinrate(params);
  }
}
