<template>
  <div class="checkboxes">
    <SharedCheckbox
      v-model="agreementChecked"
      class="checkboxes__item"
      :active-background-color="CHECKBOX_COLOR"
      :background-color="CHECKBOX_COLOR"
      @click.prevent="agreementChecked = !agreementChecked"
    >
      <template #appendText>
        <span class="checkboxes__item-content">
          <I18nT keypath="generalAuth.checkboxes.agreement.firstLine" tag="p">
            <template #link>
              <NuxtLinkLocale :to="ROUTING.FOOTER_PAGES.USER_AGREEMENT" @click="closePopup">
                {{ $t('generalAuth.checkboxes.agreement.link') }}
              </NuxtLinkLocale>
            </template>
          </I18nT>
          <p>
            <NuxtLinkLocale :to="rulesLink" @click="closePopup">
              {{ $t('generalAuth.checkboxes.agreement.secondLine') }}
            </NuxtLinkLocale>
          </p>
        </span>
      </template>
    </SharedCheckbox>
    <SharedCheckbox
      v-model="ageChecked"
      class="checkboxes__item"
      :active-background-color="CHECKBOX_COLOR"
      :background-color="CHECKBOX_COLOR"
      @click.prevent="ageChecked = !ageChecked"
    >
      <template #appendText>
        <span class="checkboxes__item-content">{{ $t('generalAuth.checkboxes.age') }}</span>
      </template>
    </SharedCheckbox>
  </div>
</template>

<script lang="ts" setup>
import type { ICheckboxesProps, ICheckboxesEmits } from './Checkboxes.types';

const { project } = useProjectSettings();

const CHECKBOX_COLOR = 'var(--main-color-100)';

const props = defineProps<ICheckboxesProps>();
const { isSelectedByDefault } = toRefs(props);

const emit = defineEmits<ICheckboxesEmits>();

const closePopup = () => emit('close-popup');

const agreementChecked = ref(false);
const ageChecked = ref(false);

const rulesLink = computed(() => {
  const rules = {
    'dota-global': ROUTING.FOOTER_PAGES.PRIVACY_POLICY,
  };

  return rules[project as keyof typeof rules] ?? ROUTING.FOOTER_PAGES.EVENT_CONDITION;
});

watch(
  isSelectedByDefault,
  (value) => {
    agreementChecked.value = value;
    ageChecked.value = value;
  },
  { immediate: true },
);

watch(
  [ageChecked, agreementChecked],
  ([ageValue, agreementValue]) => {
    emit('isAllCheckboxesChecked', ageValue && agreementValue);
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped src="./Checkboxes.scss" />
