import type {
  LoginRequestDto,
  LoginResponseDto,
  OAuthResponseDto,
  RegistrationResponseDto,
} from '~/repository/modules/auth/auth.dto';
import ApiHttp from '~/api/global/http/ApiHttp';
import type { TTypesOAuthKey } from '~/repository/modules/auth/auth.types';

export default class AuthHttp extends ApiHttp {
  protected readonly authUrl: string;

  constructor() {
    super();
    this.authUrl = '/v1/auth/';
  }

  public async login(formData: LoginRequestDto): Promise<LoginResponseDto> {
    const { data } = await this.$api.post<{ data: LoginResponseDto }>(this.authUrl + 'login', { body: formData });
    return data;
  }

  public async authByExternalToken(token: string): Promise<LoginResponseDto> {
    const { data } = await this.$api.post<{ data: LoginResponseDto }>(this.authUrl + 'login', {
      body: {
        token,
      },
    });
    return data;
  }

  public async quickRegistration(): Promise<RegistrationResponseDto> {
    const { data } = await this.$api.post<{ data: RegistrationResponseDto }>(this.authUrl + 'registration/quick');
    return data;
  }

  public async oauth(type: TTypesOAuthKey): Promise<OAuthResponseDto> {
    const { data } = await this.$api.get<{ data: OAuthResponseDto }>(this.authUrl + type);
    return data;
  }

  public revokeToken() {
    return this.$authApi.post(this.authUrl + 'revoke');
  }
}
