import MainPageHttp from '~/repository/modules/mainPage/MainPageHttp';
import type {
  ITopLadderResponseBody,
  ITopLadderHistoryResponseBody,
  ITopEventLadderHistoryResponseBody,
  ITopEventLadderResponseBody,
  ICurrentEventSettingsResponseBody,
  IRaffleInfoResponseBody,
  IRaffleResultInfoResponseBody,
} from '~/repository/modules/mainPage/MainPageApi.types';
import type {
  ITopLadderEntity,
  ITopLadderHistoryEntity,
  ITopEventLadderEntity,
  ITopEventLadderHistoryEntity,
  ICurrentEventSettingsEntity,
  IRaffleInfoEntity,
  IRaffleResultInfoEntity,
} from '~/repository/modules/mainPage/MainPageEntities.types';
import {
  EventLadderAdapter,
  EventLadderHistoryAdapter,
  TopLadderAdapter,
  TopLadderHistoryAdapter,
  CurrentEventSettingsAdapter,
  RaffleResultAdapter,
} from '~/repository/modules/mainPage/MainPage.Adapters';
import type { IBattlepassApiResponse } from '~/features/mainPage/Types/battlepass/api.types';
import type { TFreeCasesApiResponse } from '~/features/mainPage/Types/freeCases/api.types';
import type { IRewardPopupAPIData } from '~/features/mainPage/Types/rewardPopup/api.types';
import type { IAllCasesAPIItem } from '~/features/mainPage/Types/allCases/api.types';
import type { TPathParam, TQueryParams } from '~/types/Shared.types';

export default class MainPageService {
  private readonly mainPageHttp: MainPageHttp;

  constructor() {
    this.mainPageHttp = new MainPageHttp();
  }

  public async getAllCases(): Promise<IAllCasesAPIItem[]> {
    const response = await this.mainPageHttp.getSSRData<IAllCasesAPIItem[]>('ALL_SECTIONS');
    if (!response) throw new Error('Error');

    return response;
  }

  public async getBattlepass(): Promise<IBattlepassApiResponse> {
    const response = await this.mainPageHttp.getSSRData<IBattlepassApiResponse>('BATTLEPASS');
    if (!response) throw new Error('Error');

    return response;
  }

  public async claimPrize(prizeId: number) {
    const response = await this.mainPageHttp.makePostRequestWithSlug('CLAIM_PRIZE', prizeId, null);
    if (!response) throw new Error('Error');

    return response;
  }

  public async getFreeCases(): Promise<TFreeCasesApiResponse> {
    const response = await this.mainPageHttp.getSSRData<TFreeCasesApiResponse>('FREE_CASES');
    if (!response) throw new Error('Error');

    return response ?? [];
  }

  public async getInfoPopup(): Promise<IRewardPopupAPIData> {
    const response = await this.mainPageHttp.getSSRData<IRewardPopupAPIData>('INFO_POPUP');
    if (!response) throw new Error('Error');

    return response;
  }

  public async getCurrentTopUsers(): Promise<ITopLadderEntity> {
    const response = await this.mainPageHttp.getSSRData<ITopLadderResponseBody>('CURRENT_TOP_USERS');
    if (!response) throw new Error('Error');

    return TopLadderAdapter(response);
  }

  public async getTopLadderHistoryId(): Promise<ITopLadderHistoryEntity> {
    const response = await this.mainPageHttp.getSSRData<ITopLadderHistoryResponseBody>('TOP_USERS_HISTORY');
    if (!response) throw new Error('Error');

    return TopLadderHistoryAdapter(response);
  }

  public async getTopLadderByLadderId(ladderId: number): Promise<ITopLadderEntity> {
    const response = await this.mainPageHttp.getSSRDataWithSlug<ITopLadderResponseBody>(
      'TOP_USERS_BY_ID',
      ladderId.toString(),
    );
    if (!response) throw new Error('Error');

    return TopLadderAdapter(response);
  }

  public async getCurrentEventSettings(): Promise<ICurrentEventSettingsEntity> {
    const response = await this.mainPageHttp.getSSRData<ICurrentEventSettingsResponseBody>('CURRENT_EVENT_SETTINGS');
    if (!response) throw new Error('Error');

    return CurrentEventSettingsAdapter(response);
  }

  public async getCurrentTopEvent(): Promise<ITopEventLadderEntity> {
    const response = await this.mainPageHttp.getSSRData<ITopEventLadderResponseBody>('CURRENT_TOP_EVENT');
    if (!response) throw new Error('Error');
    return EventLadderAdapter(response);
  }

  public async getTopEventHistory(): Promise<ITopEventLadderHistoryEntity> {
    const response = await this.mainPageHttp.getSSRData<ITopEventLadderHistoryResponseBody>('TOP_EVENT_HISTORY');
    if (!response) throw new Error('Error');
    return EventLadderHistoryAdapter(response);
  }

  public async getTopEventByEventId(eventId: number): Promise<ITopEventLadderEntity> {
    const response = await this.mainPageHttp.getSSRDataWithSlug<ITopEventLadderResponseBody>(
      'TOP_EVENT_BY_ID',
      eventId.toString(),
    );
    if (!response) throw new Error('Error');
    return EventLadderAdapter(response);
  }

  public async generateUserLevel(): Promise<boolean> {
    const response = await this.mainPageHttp.makePostRequest<null, { success: boolean }>('GENERATE_USER_LEVEL', null);
    if (!response) throw new Error('Error while starting payment');

    return response.success;
  }

  public async getRaffleInfo(): Promise<IRaffleInfoEntity> {
    const response = await this.mainPageHttp.getSSRData<IRaffleInfoResponseBody>('ARCANES_COUNT');
    if (!response) throw new Error('Error');

    return response;
  }

  public async getRaffleResultInfo(
    queryParams: TQueryParams,
    pathParams: TPathParam[],
  ): Promise<IRaffleResultInfoEntity> {
    const response = await this.mainPageHttp.getSSRData<IRaffleResultInfoResponseBody>(
      'RAFFLE_RESULT',
      queryParams,
      pathParams,
    );
    if (!response) throw new Error('Error');

    return RaffleResultAdapter(response);
  }
}
