import { Currency } from '../constants/currency.constants';
import type { TCurrencyType } from '~/components/SharedPrice/SharedPrice.types';

/**
 * Возвращает значение с переданной валютой.
 * Если валюта не передана, то вернет просто число
 * @param {TBreakpoints} value - Денежное значение
 * @param {TCurrencyType} currency - Требуемая валюта
 * @param {boolean} withFloat - Нужно ли возвращать значение в рублях как float значение
 * @returns {string} - Отформатированное денежное значение с валютным знаком
 */
export const formatCurrency = (value: number | string, currency?: TCurrencyType, withFloat?: boolean): string => {
  const { defaultCurrency } = useProjectSettings();
  const usingCurrency = currency || (defaultCurrency as TCurrencyType);
  const formattedValue = getValueRoundedByCurrency(value, usingCurrency, withFloat) ?? 0;
  return `${formattedValue}\u00A0${Currency[usingCurrency]}`;
};

/**
 * Возвращает строковый диапазон двух монет
 * @param data - Денежное значение
 * @param divider - Требуемый разделитель
 * @returns - Строковый диапазон через заданный разделитель
 */
const formatDataRange = <T = number | string>(data: [T, T], divider = '-'): string => {
  return data.join(divider);
};

const spaceInThousand = (price: number, locale = 'ru-RU') => price.toLocaleString(locale);

/** Внутренние функции / типы */
type TCurrencyRoundings = {
  [key in TCurrencyType]?: (value: number | string) => number;
};

// Возвращает округленное значение с переданной валютой.
export const getValueRoundedByCurrency = (
  value: number | string,
  currency: TCurrencyType,
  withFloat = false,
): number | undefined => {
  const currencyRoundings: TCurrencyRoundings = {
    DIAMOND: (value: number | string) => Number(value),
    RUB: (value: number | string) => (withFloat ? Number(Number(value).toFixed(2)) : parseInt(String(value))),
  };
  if (!currencyRoundings[currency]) {
    currencyRoundings[currency] = (value: number | string) => Number(Number(value).toFixed(2));
  }
  return currencyRoundings[currency]?.(value);
};

/**
 * Возвращает приведенное к строке значение валюты по обменному курсу, либо целое, либо 2 знака после запятой
 * @param price - Денежное значение в текущей валюте
 * @param exchangeRate - Курс валют
 * @returns - Приведенное к строке целое число, либо с 2 знаками после запятой
 */
const convertToCurrency = (price: number, exchangeRate: number) => {
  return Number.isInteger(price / exchangeRate) ? String(price / exchangeRate) : (price / exchangeRate)?.toFixed(2);
};

export default {
  Currency: {
    convertToCurrency,
    formatCurrency,
    formatDataRange,
    spaceInThousand,
  },
};
