import type { IOptionsPresetAlert, IPresetAlert, IStylesOptionsAlert } from '~/store/alert/alert.types';
import { Colors } from '~/constants/colors.constants';
import { GapSizes } from '~/types/SharedGap.types';

const defaultAlertText: IStylesOptionsAlert = {
  color: Colors.NEUTRAL.WHITE,
  fontSize: '14px',
};

const mobileAlertText: IStylesOptionsAlert = {
  color: Colors.NEUTRAL.WHITE,
  fontSize: '10px',
};

const createPresets = (icon: string, color: string): IPresetAlert => {
  return {
    // Иконка и её конфиг
    icon: {
      component: icon,
      height: '17px',
      key: Symbol(icon),
      width: '17px',
    },
    styles: {
      // Фон плашки
      background: color,
      // Отступ от иконки слева до текста и до иконки справа
      gap: GapSizes.XS2,
      // Описание
      message: defaultAlertText,
      // Заголовок
      title: {
        ...defaultAlertText,
        fontSize: '16px',
        gap: GapSizes.XS3,
      },
      // Размер плашки
      width: '360px',
    },
  };
};

const createMobilePresets = (icon: string, color: string): IPresetAlert => {
  return {
    icon: {
      component: icon,
      height: '12px',
      key: Symbol(icon),
      width: '12px',
    },
    styles: {
      background: color,
      gap: GapSizes.XS3,
      message: mobileAlertText,
      title: {
        // @ts-expect-error TODO remove or refactor
        fontSize: '12px',
        gap: GapSizes.XS4,
        ...mobileAlertText,
      },
      width: '320px',
    },
  };
};

export const alertDefaultPresets: IOptionsPresetAlert = {
  error: createPresets('SvgoAlertErrorIcon', Colors.ALERT.red),
  success: createPresets('SvgoAlertSuccessIcon', Colors.ALERT.green),
};

export const alertMobilePresets: IOptionsPresetAlert = {
  error: createMobilePresets('SvgoAlertErrorIcon', Colors.ALERT.red),
  success: createMobilePresets('SvgoAlertSuccessIcon', Colors.ALERT.green),
};
