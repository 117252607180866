import { EHeaderMenuItems, type IAppsConfig } from '~/config/apps/index.types';

export const useProjectSettings = () =>
  <IAppsConfig>{
    project: 'dota',
    game: 'dota',
    theme: 'purple',
    defaultCurrency: 'RUB',
    auth: {
      isCheckboxesSelectedByDefault: true,
      variants: ['steam', 'google', 'vk'],
    },
    cases: {
      showButtons: true,
    },
    contacts: {
      adMail: 'media_td@tastyteam.cc',
      supportMail: 'support@tastyteam.cc',
      partnerMail: 'partner@tastyteam.cc',
      hideVk: false,
    },
    drop: {
      hideShare: false,
    },
    faq: {
      type3: {
        categoryId: 5,
        questionId: 19,
      },
    },
    footer: {
      copyright: 'TastyDROP',
      paymentMethods: ['visa', 'mastercard', 'qiwi', 'yoomoney', 'webmoney', 'hotskins'],
      hideSocials: false,
    },
    legalData: {
      beneficiary: 'OmegaWalls OÜ',
      registrationNumber: 16473723,
      address: 'Vesivärava tn 50-201 Tallinn Harjumaa 10152',
    },
    isBattlesDisabled: false,
    isChallengesDisabled: false,
    isProvablyFairDisabled: true,
    isUpgradesDisabled: false,
    isTechiesDisabled: false,
    hideBattlesBanner: false,
    hideSecretShop: false,
    hideFreeCasesBanner: false,
    hideHeaderMenuItems: [EHeaderMenuItems.LEADERBOARD, EHeaderMenuItems.PF],
    hideProjectSwitcher: false,
    howItWorks: {
      hideStep6: false,
    },
    supportKey: 'sePmTTSmEQ',
    seo: {
      favicon: '/img/favicon/dota.ico',
      title: 'Tastydrop - Кейсы Dota 2',
      description:
        'TastyDrop — это самый крупный сайт с кейсами по Дота 2. Покупай кейсы, получай арканы и имморталки! Вывод на Steam в течение 1 минуты.',
    },
    payment: {
      project: 'Tastydrop',
    },
    statistics: {
      hideElements: [],
    },
    techWork: {
      logoClass: 'logo--dota',
    },
    redirects: {
      newCases: '/new-cases',
    },
    subscriptions: {
      variants: ['email', 'steam', 'telegram', 'vk'],
      show: true,
    },
  };
