import type { ISubscriberWebsocket, IWebsocketInstance } from '~/api/global/webosket/websocket.types';

export default class WebsocketHttp {
  public connections: Record<string, IWebsocketInstance>;

  constructor() {
    // хранилище активных сокет соединений
    this.connections = {};
  }

  /**
   * Устанавливает сокет соединение и записывает в массив активных
   * @param {string} url - Адрес соединения
   * @param {string} key - Уникальный ключ соединения
   * @param {Class} sample - Модуль сокета
   * @returns {void}
   */
  public connect(url: string, key: string, sample: IWebsocketInstance): void {
    this.connections[key] = sample.connect(url);
  }

  /**
   * Разрывает соединение и удаляет из актинвых
   * @param {string} key - Уникальный ключ соединения
   * @returns {void}
   */
  public disconnect(key: string): void {
    const sample = this.connections[key];
    sample.disconnect();
    delete this.connections[key];
  }

  /**
   * Устанавливает подписку на соединение
   * @param {string} key - Адрес соединения
   * @param {ISubscriberWebsocket} sub - Объект конфигурации подписчика (метод, уникальный ключ, подписка на всё)
   * @returns Возвращает фцнкцию удаляюшию созданного подписчика
   */
  public subscribe(key: string, sub: ISubscriberWebsocket) {
    return this.connections[key].subscribe(sub);
  }

  /**
   * Устанавливает подписку на соединение
   * @param {string} key - Адрес соединения
   * @param {string} event - Событие, по которому отправляем сообщение и на какое нужно подписаться для его получения
   * @param {Record<string, unknown>} payload - Тело сообщения, которое отправляем
   */
  public sendMessage(key: string, event: string, payload: Record<string, unknown>) {
    this.connections[key].sendMessage(event, payload);
  }
}
