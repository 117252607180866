import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';

type TPageHandlers = (data: never) => Promise<void> | void;
type TUnsubscribeCallback = () => boolean;

interface IPageHandlerInfo {
  cb: TPageHandlers;
  event: string;
  key: string;
}

const DEFAULT_PAGE_KEY = '_default';

export const useSocketsStore = defineStore('global/sockets', () => {
  const {
    $api: { websocket: WebsocketService },
  } = useNuxtApp();

  const pagesSockets = ref<Map<string, IPageHandlerInfo[]>>(new Map());
  const activeHandlers = ref<Map<string, TUnsubscribeCallback>>(new Map());

  const addHandler = (routeNames: string[], event: string, handler: TPageHandlers) => {
    // Если не указаны страницы, то добавляем обработчик на все страницы
    if (!routeNames.length) {
      pushHandlerToPage(DEFAULT_PAGE_KEY, event, handler);
      return;
    }

    for (const routeName of routeNames) {
      pushHandlerToPage(routeName, event, handler);
    }
  };

  const removeHandler = (routeNames: string[], event: string) => {
    if (!routeNames.length) {
      removeHandlerFromPage(DEFAULT_PAGE_KEY, event);
      return;
    }

    for (const routeName of routeNames) {
      removeHandlerFromPage(routeName, event);
    }
  };

  const subscribePageHandlers = (page: string) => {
    const defaultHandlers = pagesSockets.value.get(DEFAULT_PAGE_KEY) || [];
    const pageHandlers = pagesSockets.value.get(page) || [];

    subscribeHandlers(defaultHandlers);
    subscribeHandlers(pageHandlers);
  };

  const unsubscribePageHandlers = (page: string) => {
    const pageHandlers = pagesSockets.value.get(page) || [];

    unsubscribeHandlers(pageHandlers);
  };

  const unsubscribeFromAll = () => {
    activeHandlers.value.forEach((unsub) => unsub());
    activeHandlers.value.clear();
  };

  /* --- Утилитарные методы --- */
  const pushHandlerToPage = (routeName: string, event: string, handler: TPageHandlers) => {
    const pageSockets = pagesSockets.value.get(routeName) || [];

    if (pageSockets.find((info) => info.event === event)) return;

    pageSockets.push({
      cb: handler,
      event,
      key: `${routeName}/${event}`,
    });

    pagesSockets.value.set(routeName, pageSockets);
  };

  const removeHandlerFromPage = (routeName: string, event: string) => {
    const pageSockets = pagesSockets.value.get(routeName) || [];
    const handlerIndex = pageSockets.findIndex((info) => info.event === event);

    // Если не нашли обработчик, то ничего не делаем
    if (handlerIndex === -1) return;

    const { key: removeSocketKey } = pageSockets.splice(handlerIndex, 1)[0];

    // Отключаем обработчик, если он запущен
    if (activeHandlers.value.has(removeSocketKey)) {
      activeHandlers.value.get(removeSocketKey)?.();
      activeHandlers.value.delete(removeSocketKey);
    }

    pagesSockets.value.set(routeName, pageSockets);
  };

  const subscribeHandlers = (handlers: IPageHandlerInfo[]) => {
    for (const handler of handlers) {
      if (activeHandlers.value.has(handler.key)) continue;

      const unsub = WebsocketService.subscribe(WEBSOCKET_FIRST_CHANNEL, {
        cb: (data) => handler.cb(data.msg as never),
        event: handler.event,
        fieldsToParse: ['msg'],
        uniqueKey: handler.key,
      });

      activeHandlers.value.set(handler.key, unsub);
    }
  };

  const unsubscribeHandlers = (handlers: IPageHandlerInfo[]) => {
    for (const handler of handlers) {
      const callback = activeHandlers.value.get(handler.key);
      if (!callback) continue;

      callback();
      activeHandlers.value.delete(handler.key);
    }
  };

  return {
    activeHandlers,
    addHandler,
    pagesSockets,
    removeHandler,
    subscribePageHandlers,
    unsubscribeFromAll,
    unsubscribePageHandlers,
  };
});
