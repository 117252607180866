import type { Ref } from 'vue';

export enum EStorageTypes {
  LOCAL = 'localStorage',
  SESSION = 'sessionStorage',
}

/**
 * Реактивная переменная с возможностью сохранения в localStorage или sessionStorage
 *
 * @param {string} key - ключ переменной в storage
 * @param {T} initialValue - начальное значение
 * @param {EStorageTypes} [storageType = EStorageTypes.LOCAL] - тип хранилища (localStorage по умолчанию)
 * @param {number} [lifetime] - время жизни в миллисекундах (1 неделя по умолчанию)
 * @return {Ref<T>} - реактивная переменная
 */
export function usePersistedRef<T>(
  key: string,
  initialValue: T,
  storageType = EStorageTypes.LOCAL,
  lifetime?: number,
): Ref<T> {
  const value = ref<T>(initialValue);

  onMounted(() => {
    if (typeof window === 'undefined') return;

    const persisted = window[storageType].getItem(key);
    if (!persisted) return;

    try {
      const parsed = JSON.parse(persisted);
      if (!parsed || typeof parsed.value !== typeof initialValue) {
        throw new Error('Неверные данные');
      }

      if (parsed.expiry && Date.now() > parsed.expiry) {
        throw new Error('Значение устарело');
      }

      value.value = parsed.value;
    } catch {
      window[storageType].removeItem(key);
    }
  });

  watch(
    value,
    (newValue) => {
      if (typeof window === 'undefined') return;
      const expiry = lifetime ? Date.now() + lifetime : undefined;

      try {
        window[storageType].setItem(
          key,
          JSON.stringify({
            expiry,
            value: newValue,
          }),
        );
      } catch {}
    },
    { deep: true },
  );

  return value as Ref<T>;
}
