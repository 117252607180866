import type { INuxtError } from '~/api/global/api.types';
import type { IDropItem } from '~/repository/modules/cases/cases.types';
import type { TCsQuality, TCsQualityShort } from '~/store/dropItem/dropItem.data';
import type { TCurrencyType } from '~/types/Shared.types';
import type { TStatuses, TTypeStatuses } from '~/types/cases/status';
import type { TDropItemType } from '~/types/cases/dropItem';
import type { languageIsoData } from '~/repository/extensions/i18n/data';
import type { IAuthUrlArgs } from '~/features/bk/types/integrator.types';

export const enum UserSteamDataRequests {
  UPDATE_LOGIN = 'update_login',
  UPDATE_TRADE_URL = 'update_trade_url',
}

interface ISteam {
  link: string;
  login: string;
}

export interface ILanguageData<T> {
  current: T;
  default: T;
}

export interface IBkGetUserData {
  avatar: string;
  // TODO временно для теста
  balance?: string;
  clientId: string;
  languages: ILanguageData<keyof typeof languageIsoData>;
  nameExternalIntegrator: string;
  steam: ISteam;
  userId: string;
}

export interface IBkUpdateUserData extends INuxtError {
  success: boolean;
}

export interface ICreateAuthUrlArg {
  createAuthUrl: (url: string) => IAuthUrlArgs;
}

export interface IGetCaseByNameArgs extends ICreateAuthUrlArg {
  caseName: string;
}

export type TOpenCaseArgs = IGetCaseByNameArgs;

export interface IGetUserArgs {
  authToken: string;
  bkPrefix: string;
  currency: string;
  language: string;
}

export interface ITimerDataBk {
  timeToExpire: number;
  timerSetTimeStamp: number;
}

export interface IInventoryItem extends IDemoItem {
  datetime?: string;
  offerId?: number | null;
  status?: TStatuses;
  takeItemState?: EInventoryTakeStates;
  timeUntilSending?: number;
  timerData?: ITimerDataBk;
  type?: TDropItemType;
}

export interface IDemoItem extends IDropItem {
  isOld?: boolean;
}

export interface IBkInventoryItem {
  countdown: number;
  // Дата добавления предмета в инвентарь
  datetime?: string;
  gameId: string;
  id: string;
  image: string;
  isStatTrak?: boolean;
  isUltraRare: boolean;
  marketPrice: number;
  name: string;
  offerId: number | null;
  price: string;
  quality: string;
  qualityFull?: TCsQuality;
  qualityShort?: TCsQualityShort;
  skin?: string;
  status: TStatuses;
  timeUntilSending?: number;
  type?: TDropItemType;
  weapon?: string;
}

export interface IBkInventory<T> {
  countItems: number;
  countPages: number;
  items: T[];
  totalAmount?: null | number;
  user?: IBkGetUserData;
}

export interface IReplaceItemArgs extends ICreateAuthUrlArg {
  itemId: number;
  replaceId: number;
}

export interface IReplaceItemResponse {
  itemIdInInventory?: string;
  msg: string;
  status: boolean;
}

export type TBkApi = 'base' | 'second';
export type TBkInventory = 'new' | 'old';

export type TPagesInventory = {
  [el in TBkApi]: { current: number; total: number };
};

export interface ISendItemArgs extends ICreateAuthUrlArg {
  id: number;
  method?: 'senditem' | 'withdrawal';
}

export interface ISellItemArgs extends ICreateAuthUrlArg {
  id: string;
}

export interface ISellBkItem {
  success: true;
}

export interface ISendingCurrencyExchange {
  currency: TCurrencyType;
  exchangeRate: Record<string, number>;
  status: boolean;
  timeUntilSending: number;
}

export interface IWSStateChangeEvent {
  item: string;
  offer: null | string;
  type: TTypeStatuses;
  user: string;
}

export enum EInventoryTakeStates {
  AWAITING_SELLER = 'waitingForSeller',
  AWAITING_TAKING = 'awaitingTaking',

  PREPARING_ITEM = 'preparingItem',
  WE_CHARGE_TO_THE_BALANCE = 'weChargeToTheBalance',
}
