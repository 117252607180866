export const MainPageApi = {
  GET: {
    ALL_SECTIONS: '/sections-main',
    ARCANES_COUNT: '/cases/arcane/count',
    BATTLEPASS: '/challenge',
    CURRENT_EVENT_SETTINGS: '/event/current',
    CURRENT_TOP_EVENT: '/event/top/current',
    CURRENT_TOP_USERS: '/users/top/current',
    FREE_CASES: '/cases/free',
    INFO_POPUP: '/popup/info',
    RAFFLE_RESULT: '/event/raffle-result/',
    TOP_EVENT_BY_ID: '/event/top/', // + добавляем ID таблицы ладдера
    TOP_EVENT_HISTORY: '/event/top/history',
    TOP_USERS_BY_ID: '/users/top/', // + добавляем ID таблицы ладдера
    TOP_USERS_HISTORY: '/users/top/history',
  },
  POST: {
    CLAIM_PRIZE: '/event/challenge/prize/', // + ID приза
    GENERATE_USER_LEVEL: '/users/level/generate',
  },
};
