import { useSocketsStore } from '~/store/sockets/sockets.store';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;

  const socketsStore = useSocketsStore();
  const getRouteBaseName = useRouteBaseName();

  const fromBaseName = getRouteBaseName(from);
  const toBaseName = getRouteBaseName(to);

  socketsStore.unsubscribePageHandlers(String(fromBaseName || ''));
  socketsStore.subscribePageHandlers(String(toBaseName || ''));
});
