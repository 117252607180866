export default class QueryWorker {
  public static decodeStringIntoParams(stringWithQueries: string) {
    const paramArr = stringWithQueries.slice(stringWithQueries.indexOf('?') + 1).split('&');
    const params: Record<string, string> = {};
    paramArr.forEach((param) => {
      const [key, val] = param.split('=');
      params[key] = decodeURIComponent(val);
    });
    return params;
  }

  public static encodeParamsIntoString(params: Record<string, unknown>, encode = true) {
    const objString = Object.keys(params)
      .map((key) => {
        return `${key}=${encode ? encodeURIComponent(params[key] as string) : params[key]}`;
      })
      .join('&');

    return objString.trim().length ? '?' + objString : '';
  }
}
