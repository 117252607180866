<template>
  <div class="shared-alert">
    <div v-if="icon">
      <component :is="icon.component" class="shared-alert__icon" v-bind="{ width: icon.width, height: icon.height }" />
    </div>
    <div class="shared-alert__body">
      <div class="shared-alert__title">{{ title }}</div>
      <div v-if="message" class="shared-alert__text">
        {{ message }}
      </div>
    </div>

    <div class="shared-alert__icon shared-alert__close">
      <LazySvgoAlertCloseIcon @click="emit('close')" />
    </div>

    <div v-if="isDelay" class="shared-alert__progress">
      <AlertProgressbar :delay="delay" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISharedAlertProps, ISharedAlertEmits } from '~/components/SharedAlert/SharedAlert.types';
import type { IAppComponent } from '~/types/interface';

import AlertProgressbar from '~/components/SharedAlert/AlertProgressbar/AlertProgressbar.vue';
import type { IStylesPresetAlert } from '~/store/alert/alert.types';

const emit = defineEmits<ISharedAlertEmits>();
const props = defineProps<ISharedAlertProps>();

const icon = computed<IAppComponent | undefined>(() => {
  return props.presets?.[props.type].icon;
});

const styles = computed<IStylesPresetAlert | undefined>(() => {
  if (!props.presets) return;
  if (!props.presets[props.type]) return;
  return props.presets[props.type].styles;
});
</script>

<style scoped lang="scss">
// Позволяет определить переменные на уровне vue файла, а использовать в отдельном файле scss
.shared-alert {
  --gap-alert: v-bind('styles?.gap');
  --width-alert: v-bind('styles?.width');
  --background-alert: v-bind('styles?.background');
  --margin-bottom-alert-title: v-bind('styles?.title?.gap');
  --font-size-alert-title: v-bind('styles?.title?.fontSize');
  --color-alert-title: v-bind('styles?.title?.color');
  --font-size-alert-text: v-bind('styles?.message?.fontSize');
  --color-alert-text: v-bind('styles?.message?.color');
}
</style>

<style scoped lang="scss" src="./SharedAlert.scss"></style>
