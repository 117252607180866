import { useLocalizationStore } from '~/features/localization/store/localization.store';

export default defineNuxtPlugin((nuxtApp) => {
  const { isNeedLocalization } = useRuntimeConfig().public;
  if (!isNeedLocalization) return;

  nuxtApp.hook('app:created', async () => {
    const localizationStore = useLocalizationStore();
    await localizationStore.fetchLocales();
  });
});
