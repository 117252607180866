export enum ESiteEvents {
  VIEW = 'Site - Page Viewed',
}

export interface ISiteViewEvent {
  'Page URL': string;
  'Referer': string;
  'utm_campaign': string;
  'utm_content': string;
  'utm_medium': string;
  'utm_source': string;
  'utm_term': string;
}
