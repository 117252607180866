<template>
  <div :class="['buttons-group', rootClasses]">
    <SharedKitButton
      v-for="(icon, key) in filteredIcons"
      :key="key"
      class="buttons-group__button"
      :text="getButtonText(key)"
      :color-preset="GeneralAuthButtonPreset.DEFAULT"
      :size-preset="SizePresets.M"
      :type-preset="TypePresets.ROUND"
      :is-disabled="isDisabled"
      @click="handleIconClick(key)"
    >
      <template #prepend>
        <component :is="icon" :key="icon" class="buttons-group__icon" />
      </template>
    </SharedKitButton>
  </div>
</template>

<script setup lang="ts">
import type { IAuthSocialIconsEmits, IAuthSocialIconsProps, TOAuthKey } from './ButtonsGroup.types';
import { TypePresets, SizePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { GeneralAuthButtonPreset } from '~/features/general-auth/presets/SharedKitButton.presets';

const props = defineProps<IAuthSocialIconsProps>();
const { variants } = toRefs(props);

const emit = defineEmits<IAuthSocialIconsEmits>();

const { t } = useI18n();

const getButtonText = (key: TOAuthKey) => {
  const resultText = t('generalAuth.buttons.authBy');
  const variant = t(`generalAuth.buttons.${key}`);

  return `${resultText} ${variant}`;
};

const icons: Record<TOAuthKey, string> = {
  google: 'SvgoAuthGoogle',
  steam: 'SvgoAuthSteam',
  vk: 'SvgoAuthVk',
};

const filteredIcons = computed(() => {
  const result: {
    [key in TOAuthKey]?: string;
  } = {};

  for (const key in icons) {
    if (variants.value.includes(key as TOAuthKey)) {
      result[key as TOAuthKey] = icons[key as TOAuthKey];
    }
  }

  return result;
});

const rootClasses = computed(() => ({
  [`buttons-group--horizontal`]: variants.value.length === 2,
}));

const handleIconClick = (key: TOAuthKey) => {
  emit('initAuth', key);
};
</script>

<style scoped lang="scss" src="./ButtonsGroup.scss" />

<style scoped>
:deep(.buttons-group__icon) {
  display: block;
  min-width: 20px;
}
</style>
