import type {
  ISharedKitButtonColorPreset,
  ISharedKitButtonSizePreset,
  ISharedKitButtonTypePreset,
} from './SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';

type TColorPresetsKeys = 'ACCENT' | 'PRIMARY' | 'SECONDARY' | 'BURGER' | 'QUICKSALE';
type TSizePresetsKeys = 'L' | 'M' | 'S' | 'XS';
type TTypePresetsKeys = 'RECTANGLE' | 'ROUND' | 'NONE';

type TColorPresets = {
  [key in TColorPresetsKeys]: ISharedKitButtonColorPreset;
};

export const ColorPresets: TColorPresets = {
  // Желтая
  ACCENT: {
    defaultBackground: Colors.BUTTON_PRESET.ACCENT.DEFAULT,
    hoverBackground: Colors.BUTTON_PRESET.ACCENT.HOVER,
    loadingColor: Colors.BUTTON_PRESET.ACCENT.LOADING,
    textColor: Colors.BUTTON_PRESET.ACCENT.TEXT,
  },
  // Цвет кнопок в бургер-меню (прозрачно-белый)
  BURGER: {
    defaultBackground: Colors.BUTTON_PRESET.BURGER.DEFAULT,
    hoverBackground: Colors.BUTTON_PRESET.BURGER.HOVER,
    loadingColor: Colors.BUTTON_PRESET.BURGER.LOADING,
    textColor: Colors.BUTTON_PRESET.BURGER.TEXT,
  },
  // Классическая фиолетовая
  PRIMARY: {
    defaultBackground: Colors.BUTTON_PRESET.PRIMARY.DEFAULT,
    hoverBackground: Colors.BUTTON_PRESET.PRIMARY.HOVER,
    loadingColor: Colors.BUTTON_PRESET.PRIMARY.LOADING,
    textColor: Colors.BUTTON_PRESET.PRIMARY.TEXT,
  },

  // В попапе быстрой продажи темно-фиолетовая
  QUICKSALE: {
    defaultBackground: Colors.BUTTON_PRESET.QUICKSALE.DEFAULT,
    hoverBackground: Colors.BUTTON_PRESET.QUICKSALE.HOVER,
    loadingColor: Colors.BUTTON_PRESET.QUICKSALE.LOADING,
    textColor: Colors.BUTTON_PRESET.QUICKSALE.TEXT,
  },

  // Дополнительная темно-фиолетовая
  SECONDARY: {
    defaultBackground: Colors.BUTTON_PRESET.SECONDARY.DEFAULT,
    hoverBackground: Colors.BUTTON_PRESET.SECONDARY.HOVER,
    loadingColor: Colors.BUTTON_PRESET.SECONDARY.LOADING,
    textColor: Colors.BUTTON_PRESET.SECONDARY.TEXT,
  },
};

export const SizePresets: Record<TSizePresetsKeys, ISharedKitButtonSizePreset> = {
  L: {
    fontSize: 16,
    height: 50,
    type: 'large',
  },
  M: {
    fontSize: 14,
    height: 40,
    type: 'medium',
  },
  S: {
    fontSize: 12,
    height: 32,
    type: 'default',
  },
  XS: {
    fontSize: 10,
    height: 28,
    type: 'default',
  },
};

export const TypePresets: Record<TTypePresetsKeys, ISharedKitButtonTypePreset> = {
  NONE: {
    default: 0,
    large: 0,
    medium: 0,
  },
  RECTANGLE: {
    default: 4,
    large: 12,
    medium: 8,
  },
  ROUND: {
    default: 80,
    large: 80,
    medium: 80,
  },
};
