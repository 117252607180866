import type { LocalizationGetResponse, LocalizationPostResponse } from './localization.types';
import ApiHttp from '~/api/global/http/ApiHttp';

export default class LocalizationHttp extends ApiHttp {
  private readonly localizationApi: string;

  constructor() {
    super();
    this.localizationApi = '/v1/localization/user';
  }

  public getUserLocale(): Promise<LocalizationGetResponse> {
    return this.$authApi.get(this.localizationApi);
  }

  public saveUserLocale(locale: string): Promise<LocalizationPostResponse> {
    return this.$authApi.post(this.localizationApi, {
      body: {
        language: locale,
      },
    });
  }
}
