import { getLinkWithoutDomain } from '~/utils/getLinkWithoutDomain';

type UseLinksReturnType = ReturnType<typeof useLinks>;

export function useLinks() {
  const { public: configPublic } = useRuntimeConfig();
  if (!configPublic.baseUrl) throw new Error('baseUrl is not defined');
  let domen: string = configPublic.baseUrl as string;
  domen = GlobalUtils.Text.sliceLastSlash(domen);
  return {
    AGREEMENT: `${domen}/userAgreement`,
    ARCANA: `${domen}/open/progress_spring23_level_10`,
    BALANCE_POPUP: `${domen}/offersshop/?shut_up_and_take_my_money=true`,
    REFILL: `${domen}/refill`,
    BATTLES: '/battles',
    BONUS_PAGE: `${domen}/bonuspage`,
    CASE: `${domen}/open/`,
    CONTRACT: `${domen}/contract/`,
    DAILY_CASES_XP_POPUP: `${domen}/daily_cases?show_xp_modal=true`,
    DC_HOOK: `${domen}/open/progress_spring23_level_50`,
    FAQ: `${domen}/faq`,
    FREE_CASES: `${domen}/daily_cases`,
    INSTAGRAM: 'https://www.instagram.com/tastydrop_co/',
    MAIN: '/',
    NEW_CASES: `${domen}/new-cases`,
    OPEN_CASE: `${domen}/open/`,
    POLICY: `${domen}/cookies`,
    PF_CHECK: `${domen}/provably-fair/check`,
    PROFILE: `${domen}/profile/`,
    RULES: `${domen}/eventCondition`,
    TELEGRAM: 'https://t.me/tastydropz',
    UPGRADES: `${domen}/upgrades/`,
    VK: 'https://vk.com/tastydrop',
    STEAM_PROFILE: 'https://steamcommunity.com/profiles',
    domen,
  };
}

export const useLinksWithoutDomains = (): UseLinksReturnType => {
  return Object.entries(useLinks()).reduce((acc, [key, value]) => {
    acc[key as keyof UseLinksReturnType] = getLinkWithoutDomain(value);
    return acc;
  }, {} as UseLinksReturnType);
};
