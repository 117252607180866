// Сервис позволяет буферизировать аудио дорожку и не аудио каждый раз при воспроизведении
export default class AudioProcessorService {
  private audioContext: AudioContext;
  private audioBuffer: AudioBuffer | null;
  private source: AudioBufferSourceNode | undefined;
  private gainNode: GainNode | undefined;
  private readonly defaultVolume: number;
  private isMute: boolean;

  // Контекстов в хроме может быть создано не более 6.
  // Получаем контекст аудио, звук = number, и состояние мута
  constructor(audioContext: AudioContext, defaultVolume: number, isMute: boolean) {
    this.audioContext = audioContext;
    this.audioBuffer = null;
    this.defaultVolume = defaultVolume;
    this.isMute = isMute;
  }

  // Декодируем аудио и создаём инстанс
  public async createAudio(buffer: ArrayBuffer) {
    this.audioBuffer = await this.audioContext.decodeAudioData(buffer);
    this.createSource();
  }

  // Создаёт инстанас и воспроивзодит аудио
  // Позволяет накладывать звук друг на друга
  public play() {
    if (!this.audioBuffer) return;
    this.createSource();
    this.playSource();
  }

  // Отключить звук и существующего буфера и последующих
  public toggleMute(isMute: boolean) {
    this.isMute = isMute;
    this.setVolume();
  }

  // Создаёт инстанс аудио ресурсы, путём использования буфера
  private createSource() {
    this.source = this.audioContext.createBufferSource();
    this.source.buffer = this.audioBuffer;
    this.createGain();
    if (!this.gainNode) return;
    this.source.connect(this.gainNode);
  }

  // Интегрирует механизм управление громкостью
  private createGain() {
    this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioContext.destination);
    this.setVolume();
  }

  // Воспроизводит буферизированный ресурс
  private playSource() {
    if (this.source) this.source.start();
  }

  // Установка громкости
  private setVolume() {
    if (!this.gainNode) return;
    this.gainNode.gain.value = this.isMute ? 0 : this.defaultVolume;
  }
}
