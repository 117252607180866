import { ESiteEvents, type ISiteViewEvent } from '../types/site.types';

export class SiteEvents {
  static view(event: ISiteViewEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: ESiteEvents.VIEW,
      eventProperties: event,
    });
  }
}
