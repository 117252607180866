export const SharedTextClassNames = {
  CAPITALIZE_CASE: 'capitalize-case',
  GRADIENT: 'gradient',
  INLINE: 'inline',
  LINE_HEIGHT_EXTRA_LARGE: 'line-height-extra-large',
  LINE_HEIGHT_LARGE: 'line-height-large',
  LINE_HEIGHT_MEDIUM: 'line-height-medium',
  LOWER_CASE: 'lower-case',
  SIZE_DEFAULT: 'size-default',
  SIZE_LARGE: 'size-large',
  SIZE_LARGE_2XL: 'size-large-2xl',
  SIZE_LARGE_3XL: 'size-large-3xl',
  SIZE_LARGE_XL: 'size-large-xl',
  SIZE_MEDIUM: 'size-medium',
  SIZE_SMALL: 'size-small',
  SIZE_SMALL_2XS: 'size-small-2xs',
  SIZE_SMALL_XS: 'size-small-xs',
  TEXT_SHADOW_ON_HOVER: 'text-shadow-on-hover',
  UPPER_CASE: 'upper-case',
  WEIGHT_BLACK: 'weight-black',
  WEIGHT_BOLD: 'weight-bold',
  WEIGHT_EXTRA_BOLD: 'weight-extra-bold',
  WEIGHT_EXTRA_LIGHT: 'weight-extra-light',
  WEIGHT_LIGHT: 'weight-light',
  WEIGHT_MEDIUM: 'weight-medium',
  WEIGHT_REGULAR: 'weight-regular',
  WEIGHT_SEMIBOLD: 'weight-semibold',
  WEIGHT_THIN: 'weight-thin',
};
