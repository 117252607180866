import { parsePhoneNumber } from 'awesome-phonenumber';
import type { IApiCountry } from './api.types';
import {
  ECountryMethodField,
  ECountryMethodType,
  type TValidationFunction,
  type ICountry,
  type ICountryMethod,
} from './client.types';

export const parsePaymentMethodType = (number: string): ECountryMethodType => {
  return (
    {
      1: ECountryMethodType.COMMON,
      2: ECountryMethodType.SKIN,
    }[number] ?? ECountryMethodType.COMMON
  );
};

export const transform = (apiData: IApiCountry): ICountry => {
  const adaptedCurrency = {
    code: apiData.currency.code,
    default: +apiData.currency.preliminaryAmount,
    rate: +apiData.currency.rate,
    symbol: apiData.currency.symbol,
  };

  const result: ICountry = {
    buttons: {
      desktop: apiData.buttons?.countrySumButtonData.desktop.map((button) => +button.sum) ?? [],
      mobile: apiData.buttons?.countrySumButtonData.mobile.map((button) => +button.sum) ?? [],
    },
    country: apiData.country,
    currency: adaptedCurrency,
    methods: [],
  };

  for (const method of apiData.directions.paymentDirectionData) {
    const methodFields = method.additionalInfo.map((field) => {
      let validator;
      if (field.name === ECountryMethodField.PHONE) {
        validator = parsePhone(apiData.country.iso);
      }
      if (field.name === ECountryMethodField.EMAIL) {
        validator = parseEmail;
      }
      if (field.name === ECountryMethodField.DOCUMENT_NUMBER) {
        validator = parseDocumentNumber;
      }

      return {
        default: field.default_value,
        field: field.name as ECountryMethodField,
        label: field.label,
        options: field.options?.map((option) => ({
          label: option.name,
          value: option.value,
        })),
        required: field.required,
        validator,
      };
    });

    const adaptedMethod: ICountryMethod = {
      fields: methodFields,
      icon: getImage(method.img),
      id: method.paymentDirectionId,
      min: method.minimalAmount ?? 0,
      name: method.name,
      payMethod: method.systemName,
      type: parsePaymentMethodType(String(method.type)),
    };

    result.methods.push(adaptedMethod);
  }

  return result;
};

export const getImage = (image: string): string => {
  if (!image) return '';
  if (!image.includes('https')) return `${useLinks().domen}${image}`;
  return image;
};

const parseEmail: TValidationFunction = (email: string) => {
  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  return {
    masked: email,
    valid: regex.test(email),
  };
};

const parsePhone = (code: string) => {
  const regionCode = code === '00' || !code ? 'RU' : code;

  return (phone: string) => {
    try {
      const pn = parsePhoneNumber(phone, { regionCode });

      return {
        masked: pn.number?.international ?? '',
        valid: pn.valid,
      };
    } catch {
      return {
        masked: phone,
        valid: false,
      };
    }
  };
};

const parseDocumentNumber = (documentNumber: string) => {
  const regex = /^[-,0-9]+$/;

  return {
    masked: documentNumber,
    valid: regex.test(documentNumber),
  };
};
