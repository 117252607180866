export const Events = {
  BUTTON_GRADIENT: 'linear-gradient(89deg, #00C7D3 0%, #8E16C7 100%)',
  BUTTON_GRADIENT_HOVER: 'linear-gradient(89deg, #68FFED 0%, #FFFFFF 100%)',
  BUTTON_TEXT_HOVER: '#000000',
  COINS_ICON_SHADOW: '0px 0px 14px 0px rgba(255, 125, 5, 0.50), 0px 0px 6px 0px #FF4853',
  COINS_TEXT_SHADOW: ' 0 0 6px #FF4853',
  COLORS: ['#3592E8', '#00C9B1'],
  ICON: 'SvgoNy23Coin',
  TEXT_AND_SHADOW_COLOR: '#FF4853',
};
