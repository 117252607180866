import type { TApi } from '~/api/global/api.types';
import { $api } from '~/api/global/ApiProvider';

export class ApiSample {
  public readonly $api: TApi;

  constructor() {
    this.$api = $api;
  }
}
