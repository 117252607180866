import type { IDefaultError } from '~/api/global/api.types';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { IError, IModuleErrorImplements } from '~/repository/extensions/error/error.types';

export default class ModuleError implements IModuleErrorImplements {
  /** Метод получения ошибки по ключу с бэка
   * @param {IDefaultError} e - объект ошибки
   * @returns {string} - отправляем сообщение об ошибки из списка ключей фронта
   * **/
  checkError(e: IDefaultError): IError | string {
    if (!e.data || !e.data.data) return this.defaultError();
    if (Object.values(ErrorCodes).includes(e.data.data.code as ErrorCodes))
      return this.sendError(e.data.data.code, e.data.data.code);
    return this.defaultError();
  }

  private sendError(msg: string, key: ErrorCodes): IError {
    return {
      key,
      msg,
    };
  }

  private defaultError(): IError {
    return this.sendError(ErrorCodes.UNPREDICTED_EXCEPTION, ErrorCodes.UNPREDICTED_EXCEPTION);
  }
}
