<template>
  <NuxtErrorBoundary v-if="!isOffline">
    <ClientOnly>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WPQRW3B"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe>
      </noscript>
    </ClientOnly>
    <NuxtLayout>
      <LazyNuxtPage />
      <GeneralAuthPopupController />
      <InnerOfferInnerOfferController />
    </NuxtLayout>
    <template #error>
      <ErrorPage />
    </template>
    <ClientOnly>
      <AlertController />
    </ClientOnly>
  </NuxtErrorBoundary>
  <TechWorkController v-else />
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';
import ErrorPage from '~/error.vue';
import AlertController from '~/controllers/AlertController/AlertController.vue';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useCasesSelectionStore } from '~/features/battles/store/casesSelection.store';
import { useBattlesDataStateStore } from '~/features/battles/store/dataState.store';
import type { IStateChangeSocket } from '~/features/battles/types/webSocketData';
import type { IBattlesShortInfoBattleEntity } from '~/repository/modules/battles';
import { EBattlesSocketEvents } from '~/features/battles/constants/general';
import { useSocketsStore } from '~/store/sockets/sockets.store';
import { SELL_UPDATE_DEBOUNCE } from '~/constants/common.constants';
import { useSiteStatusStore } from '~/features/tech-work/store/useSiteStatusStore';
import { useHeaderStore } from '~/project-layers/dota/store/header/header.store';

const {
  seo: { favicon },
} = useProjectSettings();

useHead({
  link: [{ href: favicon, rel: 'icon' }],
});

const route = useRoute();

const headerStore = useHeaderStore();
const { isLoaded: isHeaderLoaded } = storeToRefs(headerStore);

const userStore = useUserStore();
const authStore = useAuthStore();
const { accessToken } = storeToRefs(authStore);

const caseSelectionStore = useCasesSelectionStore();
const battlesDataStateStore = useBattlesDataStateStore();
const socketsStore = useSocketsStore();

const siteStatusStore = useSiteStatusStore();
const { isOffline } = storeToRefs(siteStatusStore);

const { saveQuery } = useUTM();

const initRequests = async () => {
  if (route.meta.isIntegratorModule) return;
  useEventStore().getCurrentEventSettings();

  if (userStore.userId || !accessToken.value) return;

  await userStore.getMe();
  headerStore.getData();

  await userStore.getUserSubscriptions();

  useZendesk();
};

function handleUserStatusOnBattlesUpdate(data: { battle: IBattlesShortInfoBattleEntity }) {
  battlesDataStateStore.handleUserStatusOnBattlesUpdate(data);
}

function handleSoldItem(data: IStateChangeSocket) {
  caseSelectionStore.handleSoldItem(data);
}

let itemSoldDebouncer: ReturnType<typeof setTimeout> | undefined;
function handleAllItemSold(data: { user: string }) {
  if (itemSoldDebouncer) {
    clearTimeout(itemSoldDebouncer);
  }

  itemSoldDebouncer = setTimeout(() => {
    caseSelectionStore.handleAllItemsSold(data);
    itemSoldDebouncer = undefined;
  }, SELL_UPDATE_DEBOUNCE);
}

saveQuery();

onBeforeMount(() => {
  if (isOffline.value) return;

  socketsStore.addHandler([], 'battles_active', headerStore.handleBattles);
  socketsStore.addHandler([], EBattlesSocketEvents.STATECHANGE, handleSoldItem);
  socketsStore.addHandler([], EBattlesSocketEvents.SELL_DONE, handleAllItemSold);
  socketsStore.addHandler([], EBattlesSocketEvents.BATTLE_CHANGED, handleUserStatusOnBattlesUpdate);
  initRequests();

  if (accessToken.value) return;
  userStore.setIsLoading(true);
});

onMounted(() => {
  if (isOffline.value) return;

  if (userStore.userId || !accessToken.value) {
    isHeaderLoaded.value = true;
  }

  watch(
    () => route.name,
    () => {
      const getRouteBaseName = useRouteBaseName();
      const baseRouteName = getRouteBaseName(route);

      socketsStore.subscribePageHandlers(String(baseRouteName || ''));
    },
    {
      immediate: true,
    },
  );
});

watch(
  () => userStore.user,
  async (newUser) => {
    if (!newUser) return;
    const { $amplitudeExperiment } = useNuxtApp();

    await $amplitudeExperiment.fetch();
    $amplitudeExperiment.variant('a-a-test');
  },
  {
    immediate: true,
    deep: true,
  },
);

useSignupAmplitude().watchToSendLoginAmplitude();
</script>

<style lang="scss">
iframe[allow='join-ad-interest-group'] {
  display: none;
}

.page-fade-enter-active,
.page-fade-leave-active {
  transition: var(--default-duration) opacity ease-in-out;
}

.page-fade-enter-from,
.page-fade-leave-to {
  opacity: 0;
}
</style>
