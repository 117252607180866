export default defineNuxtRouteMiddleware((to) => {
  const { isProvablyFairDisabled } = useProjectSettings();

  if (!isProvablyFairDisabled) return;

  const getRouteBaseName = useRouteBaseName();
  const baseRouteName = getRouteBaseName(to);

  const routes = [
    ROUTING.PROVABLY_FAIR.INSTRUCTIONS.name,
    ROUTING.PROVABLY_FAIR.CHECK.name,
    ROUTING.PROVABLY_FAIR.CASE_HISTORY.name,
    ROUTING.PROVABLY_FAIR.SETTINGS.name,
  ];

  if (!routes.includes(baseRouteName ?? '')) return;

  const localeRoute = useLocaleRoute();
  return navigateTo(localeRoute(ROUTING.MAINPAGE.MAINPAGE));
});
