import type { TApi } from '~/api/global/api.types';
import { $oldApi } from '~/api/global/ApiProvider';

export class OldApiSample {
  public readonly $oldApi: TApi;

  constructor() {
    this.$oldApi = $oldApi;
  }
}
