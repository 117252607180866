import LocalizationHttp from '~/repository/modules/localization/localizationHttp';

export default class LocalizationService {
  private readonly localizationHttp: LocalizationHttp;
  constructor() {
    this.localizationHttp = new LocalizationHttp();
  }

  public getUserLocale() {
    return this.localizationHttp.getUserLocale();
  }

  public saveUserLocale(locale: string) {
    this.localizationHttp.saveUserLocale(locale);
  }
}
