import { storeToRefs } from 'pinia';
import { useUserStore } from '~/store/user/user.store';
import { BattlesPlayersAmount } from '~/features/battles/constants/rules';
import type { TPossibleNull } from '~/types/Shared.types';
import { useBattlesSectionsStore } from '~/features/battles/store/sections.store';
import { EBattlesStoreNames } from '~/features/battles/constants/general';

interface ICreationOptions {
  /* Будет ли батл приватным */
  isBattlePrivate: boolean;
  /* ID батла, если он создается из уже существующего */
  previousBattleUlid: TPossibleNull<string>;
  /* Количество раундов в зависимости от выбранных кейсов */
  roundsAmount: number;
  /* Количество слотов */
  slotsAmount: number;
  /* Начнется ли батл с ботами */
  startWithBots: boolean;
  /* Общая итоговая стоимость батла */
  summaryCost: number;
}

/* Хранилище опции при создании батла */
export const useCreateBattleOptionsStore = defineStore(EBattlesStoreNames.CREATE_BATTLE_OPTIONS, () => {
  /* -- Initialisation -- */
  const { user } = storeToRefs(useUserStore());
  const { maxRoundsAvailable } = storeToRefs(useBattlesSectionsStore());

  /* Состояние при создании батлы */
  const optionsState = reactive<ICreationOptions>({
    isBattlePrivate: false,
    previousBattleUlid: null,
    roundsAmount: 0,
    slotsAmount: 2,
    startWithBots: false,
    summaryCost: 0,
  });

  /* Является ли общая стоимость батла больше баланса пользователя */
  const isBalanceFewerThanBattleCost = computed(() => optionsState.summaryCost > +(user.value?.finance?.balance || 0));
  /* Есть ли у юзера что-то в инвентаре */
  const isAnyItemsInInventory = computed(() => +(user.value?.finance?.totalAmountItems || 0) > 0);
  /* Разница между балансом и суммой батла */
  const costBalanceDifference = computed(() => optionsState.summaryCost - +(user.value?.finance?.balance || 0));
  /* Отслеживаем начались ли продаваться предметы из инвентаря */
  const isItemsSellingStarted = ref(false);

  /* -- Methods -- */

  /**
   * Перерасчет количество раундов на величину difference
   * @param difference - Разница в количестве раундов
   */
  const changeRoundsAmount = (difference: number) => {
    const newValue = optionsState.roundsAmount + difference;
    /* Обязательно нужно проверять на <= 0, чтобы избежать потенциальных непредвиденных багов */
    if (newValue <= 0) {
      optionsState.roundsAmount = 0;
    } else if (newValue >= maxRoundsAvailable.value) {
      optionsState.roundsAmount = maxRoundsAvailable.value;
    } else optionsState.roundsAmount = newValue;
  };

  /**
   * Перерасчет стоимости на величину difference
   * @param difference - Разница в стоимости
   */
  const changeResultSelectedCost = (difference: number) => {
    const newValue = optionsState.summaryCost + difference;
    /* Обязательно нужно проверять на <= 0, чтобы избежать потенциальных непредвиденных багов */
    if (newValue <= 0) optionsState.summaryCost = 0;
    else optionsState.summaryCost = newValue;
  };

  /**
   * Изменение количества слотов при создании батла
   * @param newValue - Новое значение количества слотов
   */
  const changeSlotsAmount = (newValue: number) => {
    optionsState.slotsAmount = newValue;
  };

  /* Сброс опций к дефолтным значениям */
  const resetOptions = () => {
    optionsState.isBattlePrivate = false;
    optionsState.startWithBots = false;
    optionsState.slotsAmount = BattlesPlayersAmount.min;
  };

  /* Обнуление количества раундов и окончательную стоимость */
  const resetRoundsAndSum = () => {
    optionsState.roundsAmount = 0;
    optionsState.summaryCost = 0;
  };

  /* -- Returns -- */
  return {
    changeResultSelectedCost,
    changeRoundsAmount,
    changeSlotsAmount,
    costBalanceDifference,
    isAnyItemsInInventory,
    isBalanceFewerThanBattleCost,
    isItemsSellingStarted,
    optionsState,
    resetOptions,
    resetRoundsAndSum,
  };
});
