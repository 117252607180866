import { Events } from './events.constants';
import type { IColors } from '~/types/Shared.types';

export const Colors = {
  // ADDITIONAL
  ADDITIONAL: {
    CS: '#db931c',
    D2: '#ce3434',
    DARK: '#e2701a',
    TD_YELLOW: '#766722',
    GOLD_DARK: '#7d4d05',
    LIGHT_GREEN: '#00B047',
    GREEN: '#05e800',
    GREEN_DARK: '#072b2a',
    ORANGE: '#ffb236',
    ORANGE_SEMI_DARK: '#FF7A30',
    DARK_ORANGE: '#FF3D00',
    PEACH: '#FFC773',
    RED: '#f7000b',
    RAFFLE_GOLD: '#ffeb37',
    PURPLE: '#FF9AD7',
    LIGHT_RED: '#E8202C',
    LIGHT_BLUE: '#1565FF',
    VIOLET: '#CE00E0',
    LIGHT_PINK: '#EE3F93',
    RED_SEMI_DARK: '#BA1125',
    DARK_PINK: '#900068',
    RED_DARK: '#96050f',
    TRANSPARENT: 'transparent',
    YELLOW: '#f0d454',
    MULTICAST: ['#FF0F00', '#EA9312'],
    MULTIFIX: ['#F332A6', '#940085', '#290049'],
    GREY: '#8589AE',
  },

  // TEXT COLOR ACCORDING TO EVENT
  EVENTS_TEXT_COLOR: {
    AFF23: Events.TEXT_AND_SHADOW_COLOR,
    GPT23: '#3AF6D5',
    S23: '#E99A00FF',
    TI23: '#224CB8',
  },

  // EVENTS
  EVENTS: {
    AFF23: Events.COLORS,
    GPT23: ['#009DE0FF', '#00B57EFF'],
    S23: ['#00C67EFF', '#E99A00FF'],
    SUM23: ['#DF0695', '#FAA21F'],
    BATTLEPASS_POINT: '#FF574D',
  },

  // LVL
  LVL: {
    FREE_CASES: ['#FFCA43', '#FF76FA'],
    LVL_1_4: ['#737785'],
    LVL_5_9: ['#573B93'],
    LVL_10_14: ['#7F11C2'],
    LVL_15_19: ['#6439DE'],
    LVL_20_29: ['#2F64ED'],
    LVL_30_39: ['#00ABAB'],
    LVL_40_49: ['#00B960'],
    LVL_50_59: ['#7DA608'],
    LVL_60_69: ['#CAA832'],
    LVL_70_79: ['#E18700'],
    LVL_80_89: ['#E76100'],
    LVL_90_99: ['#EF2222'],
    LVL_100_109: ['#F12393'],
    LVL_110_119: ['#DC68D0'],
    LVL_120_129: ['#DC68D0', '#2F64ED', '#8FA8E9'],
    LVL_130_139: ['#DC68D0', '#00ABAB', '#6CCBCB'],
    LVL_140_149: ['#DC68D0', '#00B960', '#71D1A3'],
    LVL_150_159: ['#DC68D0', '#7DA608', '#B3CE67'],
    LVL_160_169: ['#DC68D0', '#CAA832', '#D6C17A'],
    LVL_170_179: ['#DC68D0', '#E18700', '#E0B77A'],
    LVL_180_189: ['#DC68D0', '#E76100', '#FF9950'],
    LVL_190_199: ['#DC68D0', '#E76100', '#FF9950'],
    LVL_200: ['#DDC0DA', '#F12393', '#DDC0DA'],
  },

  // MAIN COLORS
  MAIN: {
    GOLD: 'var(--main-gold)',
    PURPLE_50: '#8e60ee',
    PURPLE_100: '#713dd1',
    PURPLE_200: '#512a96',
    PURPLE_300: '#3f1f78',
    PURPLE_400: '#331862',
    PURPLE_500: '#2a1051',
    PURPLE_600: '#200e3d',
    PURPLE_700: '#1a0a34',
    PURPLE_800: '#15072b',
    PURPLE_900: '#0F0422',
    BLUE_50: '#5e82f3',
    BLUE_100: '#3b5bc3',
    BLUE_200: '#2846a8',
    BLUE_300: '#1f3f8f',
    BLUE_400: '#182973',
    BLUE_500: '#122264',
    BLUE_600: '#101448',
    BLUE_700: '#0e103b',
    BLUE_800: '#050727',
    BLUE_900: '#03041c',
  },

  // NEUTRAL COLORS
  NEUTRAL: {
    BLACK: 'var(--neutral-black)',
    BLACK_30: 'var(--neutral-black-30)',
    BLACK_75: 'var(--neutral-black-75)',
    GREY: 'var(--neutral-grey)',
    GREY_BLACK: 'var(--neutral-black)',
    GREY_LIGHT: 'var(--neutral-grey)',
    WHITE: 'var(--neutral-white)',
    WHITE_15: 'var(--neutral-white-15)',
    WHITE_30: 'var(--neutral-white-30)',
    WHITE_50: 'var(--neutral-white-50)',
    WHITE_65: 'var(--neutral-white-65)',
    WHITE_75: '#FFFFFFBF',
    RED: '#E32263',
  },

  // Цвета для уведомлений
  ALERT: {
    green: '#04201F',
    red: '#36031F',
  },

  // RARITY
  RARITY: {
    ULTRA: '#00C487',
    ARCANA: '#8bcc47',
    COMMON: '#92a4b9',
    IMMORTAL: '#e8c03c',
    LEGENDARY: '#d32de6',
    MYTHICAL: '#8847ff',
    RARE: '#4b69ff',
    UNCOMMON: '#5b94d3',
    ANCIENT: '#F2754E',
  },

  // GRADIENTS
  GRADIENTS: {
    BATTLEBUTTON: 'linear-gradient(87deg, #CD6200 0%, #D40638 100%)',
    BATTLEMAINLIGHT: 'linear-gradient(90deg, #ff243180, #000000 100%), #000',
    GENERATE_BTN: 'linear-gradient(90deg, #FF76FA 0%, #FFCA43 100%)',
    GENERATE_BTN_HOVER: 'linear-gradient(90deg, #FFCA43 0%, #FF76FA 100%)',
    LIVEDROPCONTRACT: 'linear-gradient(139.23deg, #FF0F6C 0%, rgba(0, 0, 0, 0) 47.4%)',
    LIVEDROPUPGRADE: 'linear-gradient(139.23deg, #05E19C 0%, rgba(0, 0, 0, 0) 47.4%)',
    LIVEDROPBATTLES: 'linear-gradient(139.23deg, #FF4143 0%, rgba(0, 0, 0, 0) 47.4%)',
    POPULARCASESTITLE: '270deg,  rgba(5, 7, 39, 0.00) 0%, var(--main-gold) 100%',
    MULTIFIX: 'linear-gradient(225deg, #F332A6 0%, #940085 33.48%, #290049 100%)',
    MULTICAST: 'linear-gradient(225deg, #F46615 0%, #B50000 34.08%, #490023 100%)',
    ORGANGE_RED: 'linear-gradient(45deg, #FF0F00 10%, #EA9312 100%)',
    PURPLE_PINK: 'linear-gradient(45deg, #A133F8 0%, #E249FF 100%)',
    LIGHT_BLUE: 'linear-gradient(86.82deg, #3592E8 0%, #00C9B1 100%)',
    LIGHT_BLUE_HOVER: 'linear-gradient(86.79deg, #7BFFE7 0%, #FFFFFF 100%)',
    LIGHT_RED_ORANGE: 'linear-gradient(86.82deg, #df0695 0%, #faa21f 100%, #faa21f 100%)',
    GOLD: 'linear-gradient(86.79deg, #fff27b 0%, #fff 100%)',
    RARITY: {
      ULTRA: '#00C487',
      ARCANA: 'linear-gradient(246.36deg, #8BCC47 0%, #FFFFFF 56.25%, #8BCC47 100%)',
      COMMON: 'linear-gradient(246.36deg, #92A4B9 0%, #FFFFFF 56.25%, #5B94D3 100%, #92A4B9 100%)',
      IMMORTAL: 'linear-gradient(246.36deg, #E8C03C 0%, #FFFFFF 56.25%, #E8C03C 100%)',
      LEGENDARY: 'linear-gradient(246.36deg, #D32DE6 0%, #FFFFFF 56.25%, #D32DE6 100%)',
      MYTHICAL: 'linear-gradient(246.36deg, #8847FF 0%, #FFFFFF 56.25%, #8847FF 100%)',
      RARE: 'linear-gradient(246.36deg, #4B69FF 0%, #FFFFFF 56.25%, #4B69FF 100%)',
      UNCOMMON: 'linear-gradient(246.36deg, #5B94D3 0%, #FFFFFF 56.25%, #5B94D3 100%)',
    },
  },

  // BK INTEGRATION
  BK_INTEGRATION: {
    BALANCE_GREEN: '#C0FF04',
    TOP_PRICE_PURPLE: '#AA00FF',
    PRICE_PUNCH: '#5D1FCA',
  },

  // PROFILE
  PROFILE: {
    STATISTICS_RIGHT: 'var(--profile-statistic-right)',
    STATISTICS_LEFT: 'var(--profile-statistic-left)',
  },

  // TRIGGER OFFER
  TRIGGER_OFFER: {
    NEW: '#EC420F',
    TEMPORARY: '#D2042A',
    HIT: '#FF9900',
    RENEWAL: '#039411',
  },

  BUTTON_PRESET: {
    BATTLES: {
      TRANSPARENT: {
        DEFAULT_BACKGROUND: 'var(--btn-battles-transparent-default)',
        HOVER_BACKGROUND: 'var(--btn-battles-transparent-hover)',
        LOADING_COLOR: 'var(--btn-battles-transparent-loading)',
        TEXT_COLOR: 'var(--btn-battles-transparent-text)',
      },
    },
    ACCENT: {
      DEFAULT: 'var(--btn-accent-default)',
      HOVER: 'var(--btn-accent-hover)',
      LOADING: 'var(--btn-accent-loading)',
      TEXT: 'var(--btn-accent-text)',
    },
    PRIMARY: {
      DEFAULT: 'var(--btn-primary-default)',
      HOVER: 'var(--btn-primary-hover)',
      LOADING: 'var(--btn-primary-loading)',
      TEXT: 'var(--btn-primary-text)',
    },

    SECONDARY: {
      DEFAULT: 'var(--btn-secondary-default)',
      HOVER: 'var(--btn-secondary-hover)',
      LOADING: 'var(--btn-secondary-loading)',
      TEXT: 'var(--btn-secondary-text)',
    },

    QUICKSALE: {
      DEFAULT: 'var(--btn-quicksale-default)',
      HOVER: 'var(--btn-quicksale-hover)',
      LOADING: 'var(--btn-quicksale-loading)',
      TEXT: 'var(--btn-quicksale-text)',
    },

    BURGER: {
      DEFAULT: 'var(--btn-burger-default)',
      HOVER: 'var(--btn-burger-hover)',
      LOADING: 'var(--btn-burger-loading)',
      TEXT: 'var(--btn-burger-text)',
    },
  },
};

export const ColorsDota: IColors = {
  mainColor: function (color: string) {
    return `linear-gradient(0deg, ${this.rarity[color.toUpperCase()]}, transparent 140%)`;
  },
  rarity: {
    ARCANA: '#8bcc47',
    COMMON: '#92a4b9',
    IMMORTAL: '#e8c03c',
    LEGENDARY: '#d32de6',
    MYTHICAL: '#8847ff',
    RARE: '#4b69ff',
    ULTRA: '#00C487',
    UNCOMMON: '#5b94d3',
  },
  topLineColor: 'linear-gradient(90deg, var(--top-line-bg-color) 0%, rgb(186 17 37 / 0.2%) 90%)',
} as const;

export const ColorsCs: IColors = {
  borderImage: function (color: string) {
    return `linear-gradient(180deg, rgba(155, 168, 184, 0.00) 0%, ${this.rarity[color.toUpperCase()]} 100%)`;
  },
  mainColor: function (color: string) {
    return `linear-gradient(180deg, #0E1030 35%, ${this.rarity[color.toUpperCase()]} 100%)`;
  },
  overlayColor: function (color: string) {
    return `linear-gradient(180deg, rgba(24, 27, 68, 0.00) 35%, ${this.rarity[color.toUpperCase()]} 100%);`;
  },
  rarity: {
    CLASSIFIED: '#D22EE5',
    CONSUMER_GRADE: '#9BA8B8',
    CONTRABAND: '#EABD0B',
    COVERT: '#DE4D4E',
    INDUSTRIAL_GRADE: '#6C97D4',
    MIL_SPEC: '#4B69FE',
    RESTRICTED: '#8746FE',
    ST: '#F2754E',
  },
  topLineColor: 'linear-gradient(90deg, #A0F 0%, rgba(78, 53, 188, 0.00) 100%)',
} as const;
