import { onBeforeUnmount, onMounted, ref } from 'vue';

export function useWindowKeyboard(targetKey: string, callback: (e: KeyboardEvent) => void) {
  const keyPressed = ref(false);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === targetKey) {
      keyPressed.value = true;
      callback(event);
    }
  };

  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === targetKey) {
      keyPressed.value = false;
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', onKeyDown);
    window.removeEventListener('keyup', onKeyUp);
  });

  return keyPressed;
}
