import type { TImageType } from '~/components/SharedImage/SharedImage.types';

/**
 * Получить объект для SharedImage из URL картинки
 * @param {string} url - URL картинки
 * @return {TImageType} - объект путей картинки для SharedImage
 */
const parseImageURL = (url: string): TImageType => {
  const result: Partial<TImageType> = {};
  const availableExtensions = ['png', 'jpeg', 'webP'];

  const urlSlices = url.split('.');
  let urlExtension = urlSlices[urlSlices.length - 1];
  if (!urlExtension) return result as TImageType;

  if (urlExtension === 'jpg') urlExtension = 'jpeg';

  availableExtensions.forEach((extension) => {
    const key = `${extension}1` as keyof TImageType;
    result[key] = urlExtension.toLowerCase() === extension.toLowerCase() ? url : '';
  });

  return result as TImageType;
};

export const parseImageDomain = (inputURL: string): string => {
  const purifiedURL = inputURL.replace(/^.*(?=\/img)/, '');
  return `${useLinks().domen}${purifiedURL}`;
};

export const getS3Image = (feature: string, name: string, extension: string = 'png') => {
  const config = useRuntimeConfig();
  const project = (config.public.tastyProject ?? 'dota') as keyof typeof layers;

  const layers = {
    'dota': 'dota',
    'dota-global': 'dota',
    'cs': 'cs',
    'cs-global': 'cs',
  };

  return `${config.public.storageApi}/public/img/${feature}/${layers[project]}/${name}.${extension}`;
};

export default {
  SharedImage: {
    parseImageURL,
  },
};
