<template>
  <slot :user="user" :is-fake="getIsFake" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '~/store/user/user.store';
import type { IUserControllerSlots } from '~/controllers/UserController/UserController.types';

const userStore = useUserStore();
const { user, getIsFake } = storeToRefs(userStore);

defineSlots<IUserControllerSlots>();
</script>
