<template>
  <NuxtLinkLocale v-if="to" :to="to" :class="classes" :target="target" :style="styles">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot v-if="showAppendElement" name="append"></slot>
  </NuxtLinkLocale>
  <component :is="as" v-else :class="classes" :style="styles">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot v-if="showAppendElement" name="append"></slot>
  </component>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import type { ISharedTextProps } from './SharedText.types';
import { SharedTextClassNames } from './SharedText.constants';
import { FontSizes, FontWeights, LineHeightSizes } from '~/types/SharedFont.types';
import type { LinearGradientConfiguration } from '~/types/deprecated.types';
import { Breakpoints } from '~/constants/media.constants';
import { LinkTarget } from '~/constants/attributes';

const COMPONENT_NAME = 'sharedText';

const { proceedCssValue } = GlobalUtils.CSS;
const props = withDefaults(defineProps<ISharedTextProps>(), {
  as: 'div',
  inlineTextinlineText: false,
  isSmoothAnimation: true,
  lineHeight: LineHeightSizes.MEDIUM,
  shadowOnHover: false,
  size: 'default',
  target: LinkTarget.LINK_SELF,
  weight: FontWeights.REGULAR,
});

const {
  as,
  to,
  size,
  weight,
  lineHeight,
  gradient,
  fontFamily,
  fontStyle,
  textShadow,
  color,
  fontCase,
  shadowOnHover,
  showAppendSlot,
  wrap,
  display,
  justifyContent,
  alignItems,
  align,
  maxWidth,
  minWidth,
  letterSpacing,
  textOverflow,
  overflow,
  cursor,
  gap,
  inlineText,
} = toRefs(props);

const viewport = useViewport();

const showAppendElement = computed(() => {
  if (showAppendSlot.value === undefined) return viewport.isGreaterThan(Breakpoints.sm);
  return showAppendSlot.value;
});

const componentClassNames = new Proxy(SharedTextClassNames, {
  get(target, prop: keyof typeof SharedTextClassNames) {
    return COMPONENT_NAME + '_' + target[prop];
  },
});

const isClassAssigned = (classes: Record<string, boolean>) => {
  return Object.values(classes).some((value) => value);
};

const checkIsGradientValid = (gradient?: LinearGradientConfiguration | string) => {
  if (!gradient) return false;
  if (typeof gradient === 'string') return !gradient.includes('none') && gradient.includes(',');
  return !!gradient;
};

const tagClasses = computed(() => ({
  [componentClassNames.INLINE]: as?.value === 'span' && !inlineText.value,
}));

const lineHeightClasses = computed(() => ({
  [componentClassNames.LINE_HEIGHT_MEDIUM]: lineHeight?.value === LineHeightSizes.MEDIUM,
  [componentClassNames.LINE_HEIGHT_LARGE]: lineHeight?.value === LineHeightSizes.LARGE,
  [componentClassNames.LINE_HEIGHT_EXTRA_LARGE]: lineHeight?.value === LineHeightSizes.EXTRA_LARGE,
}));

const fontSizeClasses = computed(() => ({
  [componentClassNames.SIZE_DEFAULT]: size?.value === 'default',
  [componentClassNames.SIZE_SMALL_2XS]: size?.value === FontSizes.SMALL_2XS,
  [componentClassNames.SIZE_SMALL_XS]: size?.value === FontSizes.SMALL_XS,
  [componentClassNames.SIZE_SMALL]: size?.value === FontSizes.SMALL,
  [componentClassNames.SIZE_MEDIUM]: size?.value === FontSizes.MEDIUM,
  [componentClassNames.SIZE_LARGE]: size?.value === FontSizes.LARGE,
  [componentClassNames.SIZE_LARGE_XL]: size?.value === FontSizes.LARGE_XL,
  [componentClassNames.SIZE_LARGE_2XL]: size?.value === FontSizes.LARGE_2XL,
  [componentClassNames.SIZE_LARGE_3XL]: size?.value === FontSizes.LARGE_3XL,
}));

const fontWeightClasses = computed(() => ({
  [componentClassNames.WEIGHT_THIN]: weight?.value === FontWeights.THIN,
  [componentClassNames.WEIGHT_EXTRA_LIGHT]: weight?.value === FontWeights.EXTRA_LIGHT,
  [componentClassNames.WEIGHT_LIGHT]: weight?.value === FontWeights.LIGHT,
  [componentClassNames.WEIGHT_REGULAR]: weight?.value === FontWeights.REGULAR,
  [componentClassNames.WEIGHT_MEDIUM]: weight?.value === FontWeights.MEDIUM,
  [componentClassNames.WEIGHT_SEMIBOLD]: weight?.value === FontWeights.SEMIBOLD,
  [componentClassNames.WEIGHT_BOLD]: weight?.value === FontWeights.BOLD,
  [componentClassNames.WEIGHT_EXTRA_BOLD]: weight?.value === FontWeights.EXTRA_BOLD,
  [componentClassNames.WEIGHT_BLACK]: weight?.value === FontWeights.BLACK,
}));

const textTransformClasses = computed(() => ({
  [componentClassNames.UPPER_CASE]: fontCase?.value === 'upper',
  [componentClassNames.LOWER_CASE]: fontCase?.value === 'lower',
  [componentClassNames.CAPITALIZE_CASE]: fontCase?.value === 'capitalize',
}));

const colorizedClasses = computed(() => ({
  [componentClassNames.GRADIENT]: checkIsGradientValid(gradient?.value),
  [componentClassNames.TEXT_SHADOW_ON_HOVER]: shadowOnHover?.value && color?.value,
}));

const styles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {
    ...GlobalUtils.CSS.createLinearGradient(gradient?.value, '', true),
  };

  if (!isClassAssigned(fontSizeClasses.value) && size?.value) properties.fontSize = proceedCssValue(size.value);
  if (!isClassAssigned(lineHeightClasses.value) && lineHeight?.value)
    properties.lineHeight = proceedCssValue(lineHeight.value);
  if (!isClassAssigned(fontWeightClasses.value) && weight?.value) properties.fontWeight = proceedCssValue(weight.value);
  if (shadowOnHover?.value && color?.value) properties['--hover-text-shadow-color'] = proceedCssValue(color?.value);
  if (fontStyle?.value) properties.fontStyle = proceedCssValue(fontStyle.value);
  if (fontFamily?.value) properties.fontFamily = proceedCssValue(fontFamily.value);
  if (textShadow?.value) properties.textShadow = proceedCssValue(textShadow.value);
  if (maxWidth?.value) properties.maxWidth = proceedCssValue(maxWidth.value);
  if (minWidth?.value) properties.minWidth = proceedCssValue(minWidth.value);
  if (letterSpacing?.value) properties.letterSpacing = proceedCssValue(letterSpacing.value);
  if (textOverflow?.value) properties.textOverflow = proceedCssValue(textOverflow.value);
  if (overflow?.value) properties.overflow = proceedCssValue(overflow.value);
  if (color?.value) properties.color = proceedCssValue(color.value);
  if (wrap?.value) properties.whiteSpace = proceedCssValue(wrap.value);
  if (align?.value) properties.textAlign = proceedCssValue(align.value);
  if (display?.value) {
    properties.display = proceedCssValue(display.value);
    if (alignItems?.value) properties.alignItems = proceedCssValue(alignItems.value);
    if (justifyContent?.value) properties.justifyContent = proceedCssValue(justifyContent.value);
    if (gap?.value) properties.gap = proceedCssValue(gap.value);
  }
  if (props.isSmoothAnimation) properties['--duration'] = `var(--default-duration)`;
  if (cursor?.value) properties.cursor = proceedCssValue(cursor.value);
  if (props.opacity) properties.opacity = props.opacity;

  return properties;
});

const classes = computed(() => ({
  [COMPONENT_NAME]: true,
  ...tagClasses.value,
  ...lineHeightClasses.value,
  ...fontSizeClasses.value,
  ...fontWeightClasses.value,
  ...textTransformClasses.value,
  ...colorizedClasses.value,
}));
</script>

<style src="./SharedText.scss" scoped lang="scss"></style>
