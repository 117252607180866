import AudioHttp from '~/repository/modules/audio/AudioHttp';

export default class AuthService {
  private readonly audioHttp: AudioHttp;

  constructor() {
    this.audioHttp = new AudioHttp();
  }

  getAudio(url: string) {
    return this.audioHttp.getAudio(url);
  }

  async getAudioBuffer(url: string) {
    return await this.audioHttp.getAudio(url).then((response) => response.arrayBuffer());
  }
}
