import type { Ref } from 'vue';
import type { IError } from '~/repository/extensions/error/error.types';

/**
 * Оборачивает дефолтную обработку ошибок + состояния загрузки для promise
 * @param {string} res - функция запроса
 * @param {string} rej - функция обработки ошибок
 * @param {string} isPending - состояние загрузки
 * @param {string} finallyCb - функция после завершению загрузки
 */

const handleRequest = async <T, V = IError>(
  res: () => Promise<T>,
  rej?: (e: V) => void,
  isPending?: Ref<boolean>,
  finallyCb?: () => void,
) => {
  // const { public: publicRuntimeVars } = useRuntimeConfig();

  try {
    if (isPending) isPending.value = true;
    return await res();
  } catch (e: V | unknown) {
    if (isPending) isPending.value = false;
    // publicRuntimeVars.isDevelopment && console.error(e);
    if (rej) {
      return rej((e || {}) as V);
    }
  } finally {
    if (isPending) isPending.value = false;
    finallyCb && finallyCb();
  }
};

export default {
  Api: {
    handleRequest,
  },
};
