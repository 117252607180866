/**
 * Возвращает новую строку в которой добавляются пробелы перед указанными словами и выделяются указанные слова
 * @returns {string} - измененная строка
 * @param options
 */
function transformTextBk(options: {
  gap?: string;
  spaceWords?: string[];
  str: string;
  styledWords?: string[];
  styledWordsLinks?: { link: string; word: string }[];
  styles?: string;
}): string {
  const {
    str,
    spaceWords = [],
    styledWords = [],
    styledWordsLinks = [],
    styles = '#000',
    gap = 'var(--gap-m)',
  } = options;

  let currentString = str;
  const lineBreakSeparator = `<br/>\n<span style="margin-bottom:${gap}; display: block"></span>\n`;

  // Добавление стилей
  for (const word of styledWords) {
    if (currentString.includes(word)) {
      // если это слово является ссылкой, то берем линк на ссылку из массива с ссылками
      styledWordsLinks.forEach((el) => {
        if (el.word === word) {
          currentString = currentString.replace(
            word,
            ` <a target="_blank" href="${el.link}" style="color: ${styles}">${word}</a> `,
          );
        } else {
          currentString = currentString.replace(word, ` <span style="color: ${styles}">${word}</span> `);
        }
      });
    }
  }

  // Добавление пробела перед словом
  for (const word of spaceWords) {
    if (currentString.includes(word)) {
      currentString = currentString.replace(word, `${lineBreakSeparator}${word}`);
    }
  }

  return currentString;
}

export default {
  Text: {
    transformTextBk,
  },
};
