import type { ICases } from '~/repository/modules/mainPage/MainPageApi.types';
import type {
  IEventButtonGradient,
  IGetCasesImages,
  IImageBackground,
  IImageLogo,
  IPopup,
} from '~/repository/modules/mainPage/MainPageEntities.types';
import type { TBackgroundColor, TPossibleNull } from '~/types/Shared.types';
import { ETabsPresets } from '~/features/mainPage/components/HeaderTab/HeaderTab.types';

export const useEventStore = defineStore('global/event', () => {
  const {
    $api: { mainPage: MainPageService },
  } = useNuxtApp();

  const isLoaded = ref(false);

  const id = ref<number>(0);

  const casesSectionTitles = ref<TPossibleNull<ICases>>(null);

  const coinIcon = ref(`${useLinks().domen}/img/event/default/default-coin_icon.svg`);

  const coinColor = ref('#F03573');

  const coinIconShadowColor = computed(() => {
    return `0px 0px 14px 0px rgba(255, 125, 5, 0.50), 0px 0px 6px 0px ${coinColor.value}`;
  });

  const coinTextShadowColor = computed(() => {
    return `0 0 6px ${coinColor.value}`;
  });

  const popup = ref<IPopup>({
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
    image6: '',
    popupGradient: { colorStops: [], toDirection: '' },
    shadow: [],
  });

  const eventSubtitle = ref('');

  const eventTitle = ref('');

  const eventFullName = ref('');

  const eventTimerUnixTime = ref(0);

  const imageBackground = ref<IImageBackground>({ m: '', s: '', xl: '', xxl: '' });

  const eventButtonGradient = ref<IEventButtonGradient>({ color: [], hover: [] });

  const imageLogo = ref<IImageLogo>({
    case: '',
    ladder: '',
    m: '',
    s: '',
    xl: '',
  });

  const getCasesImages = ref<IGetCasesImages>({
    image1: '',
    image2: '',
    image3: '',
  });

  const battlepassGradient = ref<TBackgroundColor>('');

  const activeTabs = ref<ETabsPresets[]>([
    ETabsPresets.TASTY_BATTLE_PASS,
    ETabsPresets.NEW_CASES,
    ETabsPresets.FREE_CASES,
    ETabsPresets.QUIZ,
    ETabsPresets.TOP,
  ]);

  const getCurrentEventSettings = async () => {
    const { public: configPublic } = useRuntimeConfig();
    if (!configPublic.baseUrl) throw new Error('baseUrl is not defined');
    let domen = configPublic.baseUrl as string;
    domen = GlobalUtils.Text.sliceLastSlash(domen);

    try {
      const response = await MainPageService.getCurrentEventSettings();
      battlepassGradient.value = response.challengeCardGradient;

      activeTabs.value = response.activeTabs;

      casesSectionTitles.value = response.casesSectionTitles;
      coinIcon.value = response.coinIcon;
      coinColor.value = response.coinColor;
      popup.value = response.popup;
      eventSubtitle.value = response.eventSubtitle;
      eventTitle.value = response.eventTitle;
      eventFullName.value = response.eventFullName;
      eventTimerUnixTime.value = response.eventTimerUnixTime;
      eventButtonGradient.value = response.eventButtonGradient;
      id.value = response.id;
      imageBackground.value = response.imageBackground;
      imageLogo.value = response.imageLogo;
      getCasesImages.value = response.getCasesImages;
    } catch {
      coinIcon.value = `${domen}/img/event/default/default-coin_icon.svg`;
      coinColor.value = '#F03573';
    } finally {
      isLoaded.value = true;
    }
  };

  return {
    activeTabs,
    battlepassGradient,
    casesSectionTitles,
    coinColor,
    coinIcon,
    coinIconShadowColor,
    coinTextShadowColor,
    eventButtonGradient,
    eventFullName,
    eventSubtitle,
    eventTimerUnixTime,
    eventTitle,
    getCasesImages,
    getCurrentEventSettings,
    id,
    imageBackground,
    imageLogo,
    isLoaded,
    popup,
  };
});
