import { PaymentRoutesApi } from './PaymentRoutes.api';
import type { TPossibleNull } from '~/types/Shared.types';
import ApiQueryHttp from '~/api/global/http/ApiQueryHttp';

type TGetPaymentKeys = keyof typeof PaymentRoutesApi.GET;
type TPostPaymentKeys = keyof typeof PaymentRoutesApi.POST;

type TQueryParams = Record<string, string | number>;

export default class PaymentHttp extends ApiQueryHttp {
  protected readonly paymentApi: string;

  constructor() {
    super();
    this.paymentApi = '/v1/payments';
  }

  /* GET */

  public async getSSRData<TResponse>(
    route: TGetPaymentKeys,
    queries: TQueryParams = {},
  ): Promise<TPossibleNull<TResponse>> {
    const queryString = this.query.serialise(queries);
    const { data } = await this.$authApi.get<{ data: TResponse }>(
      this.route(PaymentRoutesApi.GET[route]) + queryString,
    );

    return (data as TResponse) || null;
  }

  public async getSSRDataWithSlug<TResponse>(route: TGetPaymentKeys, slug: string): Promise<TPossibleNull<TResponse>> {
    const codePart = `/${slug}`;
    const { data } = await this.$authApi.get<{ data: TResponse }>(this.route(PaymentRoutesApi.GET[route]) + codePart);
    return (data as TResponse) || null;
  }

  /* POST */

  public async makePostRequest<TRequest = object, TResponse = object>(
    route: TPostPaymentKeys,
    body: TRequest,
  ): Promise<TPossibleNull<TResponse>> {
    const { data }: { data: TResponse } = await this.$authApi.post(this.route(PaymentRoutesApi.POST[route]), {
      body: body || {},
      isMapped: false,
      mapBeforeSend: false,
    });
    return data;
  }

  private route = (route: string) => this.paymentApi + route;
}
