import type { TPossibleNull } from '~/types/Shared.types';
import ApiQueryHttp from '~/api/global/http/ApiQueryHttp';

export default class FaqHttp extends ApiQueryHttp {
  protected readonly faqApi: string;

  constructor() {
    super();
    this.faqApi = '/v1/page/faq';
  }

  /* GET */

  public async getCategory<IResponseInventoryDTO>(): Promise<TPossibleNull<IResponseInventoryDTO>> {
    const { data } = await this.$authApi.get<{ data: IResponseInventoryDTO }>(this.faqApi);

    return (data as IResponseInventoryDTO) || null;
  }
}
