/**
 * Функция-генератор уникального ключа в виде строки
 * @param length - Желаемая длина ключа
 * @defaults length - 10
 */
const createUniqueId = (length = 10) => {
  const MAX_RANDOM_36_LEN = 10;
  const normalisedLength = isNaN(+length) ? MAX_RANDOM_36_LEN : Math.abs(length);

  const iterations = Math.ceil(normalisedLength / MAX_RANDOM_36_LEN);
  const remainder = normalisedLength % MAX_RANDOM_36_LEN;

  /* Функция создания самой строки-id */
  const generateId = (generateLength: number): string => {
    const len = generateLength || MAX_RANDOM_36_LEN;

    const randomStr = Math.random()
      .toString(36)
      .substring(2, len + 2);
    const diff = randomStr.length - len;

    if (diff < 0) return randomStr + generateId(Math.abs(diff));

    return randomStr.substring(diff);
  };

  let id = '';
  for (let i = 0; i < iterations; i++) {
    const last = i === iterations - 1;
    id += generateId(last ? remainder : MAX_RANDOM_36_LEN);
  }

  return id;
};

function randomIntFromInterval(min: number, max: number): number {
  // min and max included
  return Math.random() * (max - min) + min;
}

/** Вычистать процент от числа
 * @param {number} x -отношение
 * @param {number} y - целое
 * @return {number}
 * */
function calculatePercents(x: number, y: number): number {
  return (x / y) * 100;
}

// функция для возврата целого числа или дробного числа с заданным кол-ом знаков после запятой
const numberOfDecimalPlaces = (value: number, quantity: number): number => {
  const isInteger = value === Math.floor(value);
  return isInteger ? value : +value?.toFixed(quantity);
};

// функция для проверки является ли значение числом
const isNumber = (value: unknown): boolean => {
  if (typeof value !== 'number' || isNaN(value)) return false;
  return true;
};

export default {
  Maths: {
    calculatePercents,
    createUniqueId,
    isNumber,
    numberOfDecimalPlaces,
    randomIntFromInterval,
  },
};
