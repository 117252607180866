import type { RouteRecordName } from '#vue-router';

export interface IAppRoute {
  external?: string;
  getDynamicPath?: (...args: Array<string>) => string;
  name: string | RouteRecordName;
  notNative?: boolean;
  path: string;
  alias?: string;
}

function toNuxtPage({ name, path, alias }: IAppRoute): Pick<IAppRoute, 'name' | 'path' | 'alias'> {
  return {
    name,
    path,
    alias,
  };
}

const goExternalLink = (link: string) => {
  return navigateTo(link, {
    external: true,
  });
};

const createRoutingNamesArray = (...routes: IAppRoute[]) => {
  return routes.map((route) => route.name as RouteRecordName);
};

export default {
  Routing: {
    createRoutingNamesArray,
    goExternalLink,
    toNuxtPage,
  },
};
