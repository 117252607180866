<template>
  <SharedKitPrice
    class="old-price"
    :variant="{ oldPrice: +price, currency: parsedCurrency, price: 0, type: ESharedKitPriceTypes.MONEY }"
  />
</template>

<script setup lang="ts">
import type { IOldPriceProps } from './OldPrice.types';
import { ESharedKitPriceTypes } from '~/components/SharedKitPrice/SharedKitPrice.types';
import { Colors } from '~/constants/colors.constants';
import { CurrencyName } from '~/constants/currency.constants';
import type { TCurrencyType } from '~/types/Shared.types';
import { FontSizesNumber } from '~/types/SharedFont.types';

const { defaultCurrency } = useProjectSettings();

const props = withDefaults(defineProps<Partial<IOldPriceProps>>(), {
  color: Colors.NEUTRAL.WHITE_50,
  fontSize: FontSizesNumber.SMALL,
  oldPrice: 0,
  price: 0,
});

const parsedCurrency = computed(() => props.currency ?? CurrencyName[defaultCurrency as TCurrencyType]);

const styles = computed(() => ({
  color: props.color,
  fontSize: props.fontSize + 'px',
}));
</script>

<style lang="scss" src="./OldPrice.style.scss" scoped></style>
<style lang="scss" scoped>
.old-price {
  --color: v-bind(styles.color);
  --font-size: v-bind(styles.fontSize);
}
</style>
