import { $fetch } from 'ofetch';
import type {
  IConfigApiMethod,
  ICreateApiProperties,
  ICreateOneConnectProperties,
  TApi,
  TRequestMethods,
} from '~/api/global/api.types';
import FetchRequest from '~/api/global/fetch/FetchRequest';

export default class ApiBuilder {
  private readonly requestMethods: TRequestMethods[];
  private readonly fetchRequest: FetchRequest;

  constructor() {
    this.requestMethods = ['delete', 'get', 'post', 'put'];
    this.fetchRequest = new FetchRequest();
  }

  /** Создать глобальную сущность API с вложенными методами
   * @param {ICreateApiProperties} properties - type - ключ для заголовкой, keyApiUrl - ключ для получения url
   * @return {TApi} object - объект api **/
  public createApi(properties: ICreateApiProperties): TApi {
    const instanceApi: Partial<TApi> = {};
    for (let i = 0; i < this.requestMethods.length; i++) {
      const currentRequestMethod = this.requestMethods[i];
      this.createOneConnect({ currentRequestMethod, instanceApi, ...properties });
    }
    return instanceApi as TApi;
  }

  /** Создать одно соединение
   * @param {ICreateOneConnectProperties} object - Объект конфигураций
   * 1. keyApiUrlProject-  Ключ для получения api адреса
   * 2. instanceApi - Объект в который будет вложен текущая сущность api
   * 3. currentRequestMethod - Метод запроса GET/Post и т.д
   * 4. type - тип ключа для получения заголовкой headers
   * **/
  private createOneConnect({ keyApiUrlProject, instanceApi, currentRequestMethod, type }: ICreateOneConnectProperties) {
    instanceApi[currentRequestMethod] = <T>(url: string, apiOptions?: IConfigApiMethod<T>): Promise<T> => {
      const opts = apiOptions || {};
      const requestUrl = this.fetchRequest.createUrl({ keyApiUrlProject, opts, url });
      const options = this.fetchRequest.createOptions({ currentRequestMethod, opts, type });
      // Преобразовываем данные после получения
      const transformData = this.fetchRequest.transformResponse({ opts });
      // Обработка базовых ошибок
      const catchError = this.fetchRequest.catchError({ opts });
      return $fetch(requestUrl, options).then(transformData).catch(catchError) as Promise<T>;
    };
  }
}
