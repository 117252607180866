import { $authApi } from '~/api/global/ApiProvider';
import type { TApi } from '~/api/global/api.types';
import { ApiSample } from '~/api/global/samples/ApiSample';

export class AuthApiSample extends ApiSample {
  public readonly $authApi: TApi;

  constructor() {
    super();
    this.$authApi = $authApi;
  }
}
