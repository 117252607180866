export default class OAuthService {
  private readonly key: string;

  constructor() {
    this.key = 'authredirect';
  }

  // Добавить проперти(данные роута) страницы с которой был осуществлён переход на сервисы oauth
  setLocation(fullPath: string) {
    const cookie = useCookie(this.key);
    cookie.value = fullPath;
  }

  // Перейти на старницу с которой был осуществлён переход на сервисы oauth
  // Если такой страницы нет, то вернуть на главную
  toLocation(): string {
    try {
      return useCookie(this.key).value || '';
    } catch {
      return useLinks().MAIN;
    }
  }
}
