<template>
  <div :style="style" class="alert-progressbar">
    <div ref="$progress" class="alert-progressbar__progress"></div>
  </div>
</template>

<script setup lang="ts">
import type { IAlertProgressbarProps } from '~/components/SharedAlert/AlertProgressbar/AlertProgressbar.types';

const $progress = ref<HTMLElement>();
const props = defineProps<IAlertProgressbarProps>();
onMounted(() => {
  // задержка для того, чтобы анимация выполнялась НАВЕРНЯКА после рендера всех эелментов
  setTimeout(() => {
    if ($progress.value) $progress.value.style.width = '100%';
  }, 100);
});

onUnmounted(() => {
  if ($progress.value) $progress.value.style.width = '0';
});

const style = computed(() => {
  return {
    '--delay-alert': props.delay + 's',
  };
});
</script>

<style scoped src="./AlertProgressbar.scss" lang="scss"></style>
