import ApiHttp from '~/api/global/http/ApiHttp';
import type { IDailyCaseItem, IDailyCaseTimerInfo } from '~/repository/modules/cases/cases.types';
import type {
  IDailyCaseTimerInfoApiResponse,
  SubscriptionsRequestDto,
  SubscriptionsResponseDto,
} from '~/repository/modules/cases/cases.dto';
import type { IAppRequest } from '~/types/Shared.types';
import type { TUserSubscriptions } from '~/store/user/user.types';

export default class DailyCasesHttp extends ApiHttp {
  private readonly caseApi: string;
  private readonly subscriptionApi: string;

  constructor() {
    super();
    this.caseApi = '/v1/cases/daily';
    this.subscriptionApi = '/v1/notifications';
  }

  async getCases(): Promise<IDailyCaseItem[]> {
    const { data } = await this.$authApi.get<IAppRequest<IDailyCaseItem[]>>(this.caseApi);
    return data;
  }

  async getTimerInfo(): Promise<IDailyCaseTimerInfo | null> {
    const { data, error } = await useAsyncData<IDailyCaseTimerInfoApiResponse>(() =>
      this.$authApi.get(this.caseApi + '/info'),
    );
    if (error.value || !data.value?.data) {
      return null;
    }
    return data.value?.data;
  }

  async updateSubscriptions(requestData: SubscriptionsRequestDto): Promise<TUserSubscriptions> {
    const {
      data: { subscriptions },
    } = await this.$authApi.post<IAppRequest<SubscriptionsResponseDto>>(
      this.subscriptionApi + '/daily-cases/update-preferences',
      {
        body: requestData,
      },
    );
    return subscriptions;
  }
}
