import type { VueI18nTranslation } from 'vue-i18n';
import type { ITopModalValidationRes } from '~/features/bk/components/IFrameModalTop/IFrameModalTop.types';

enum SteamErrors {
  STEAM_LOGIN_EMPTY = 'steamLoginEmpty',
  STEAM_LOGIN_LATIN_FIRST = 'steamLoginLatinFirst',
  STEAM_LOGIN_LENGTH = 'steamLoginLength',
  STEAM_LOGIN_SHORT = 'steamLoginShort',
  STEAM_LOGIN_VALID = 'steamLoginValid',
  STEAM_URL_EMPTY = 'steamUrlEmpty',
  STEAM_URL_VALIDATION = 'steamUrlValidation',
}

export enum SteamMarketIds {
  DOTA = 570,
  CS = 710,
}

export function validateSteamTradeUrl(url: string, localizator: VueI18nTranslation): ITopModalValidationRes {
  const regex = /^(https?:\/\/)?(www\.)?steamcommunity\.com\/tradeoffer\/new\/\?partner=\d+&token=[a-zA-Z0-9-_]+$/;
  const isValid: boolean = regex.test(url);
  let errorMessage: string | null = '';

  if (!isValid) {
    if (url.trim() === '') {
      errorMessage = SteamErrors.STEAM_URL_EMPTY;
    } else {
      errorMessage = SteamErrors.STEAM_URL_VALIDATION;
    }
    return {
      errorMessage: localizator(errorMessage),
      isValid,
    };
  }
  return {
    isValid,
  };
}

export function validateSteamLogin(login: string, localizator: VueI18nTranslation) {
  const regex = /^[a-zA-Z0-9_]{3,}$/;
  let isValid = true;
  let errorMessage = '';

  if (login.trim() === '') {
    isValid = false;
    errorMessage = SteamErrors.STEAM_LOGIN_EMPTY;
  } else if (login.length > 32) {
    isValid = false;
    errorMessage = SteamErrors.STEAM_LOGIN_LENGTH;
  } else if (login.length < 3) {
    isValid = false;
    errorMessage = SteamErrors.STEAM_LOGIN_SHORT;
  } else if (!regex.test(login)) {
    isValid = false;
    errorMessage = SteamErrors.STEAM_LOGIN_VALID;
  } else if (!/^[a-zA-Z]+$/.test(login.charAt(0))) {
    isValid = false;
    errorMessage = SteamErrors.STEAM_LOGIN_LATIN_FIRST;
  }
  if (!isValid) {
    return {
      errorMessage: localizator(errorMessage),
      isValid,
    };
  }
  return {
    isValid,
  };
}

export function createSteamMarketLink(marketId: SteamMarketIds, itemName: string, open = true) {
  const encodedItemName = encodeURIComponent(itemName);
  const steamMarketLink = `https://steamcommunity.com/market/listings/${marketId}/${encodedItemName}`;
  if (open) {
    return window.open(steamMarketLink, '_blank');
  }
  return steamMarketLink;
}

export default {
  Steam: {
    createSteamMarketLink,
    validateSteamLogin,
    validateSteamTradeUrl,
  },
};
