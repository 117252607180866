import ApiQueryHttp from '~/api/global/http/ApiQueryHttp';
import type {
  IGetProfileBaseParams,
  IGetProfileBattlesParams,
  IGetProfileWinrateParams,
  TGetBattlesResponseDTO,
  TGetBestBattleResponseDTO,
  TGetProfileWinrateDTO,
} from '~/repository/modules/profile/types/battles';
import type {
  IGetSatDashboardParams,
  TGetStatBattlesResponseDto,
  TGetStatDashboardResponseDto,
  TGetStatUpgradesResponseDto,
  IGetStatBattlesParams,
  IBaseStatRequestParams,
} from '~/repository/modules/profile/types/statistics';
import type {
  TGetProfileUpgradesRequestDto,
  TGetProfileUpgradesResponseDto,
} from '~/repository/modules/profile/types/upgrades';

export class ProfileHttp extends ApiQueryHttp {
  public generateLevel() {
    return this.$authApi.post('/v1/users/level/generate');
  }

  public getStatBattles(params: IGetStatBattlesParams) {
    return this.$authApi.get<TGetStatBattlesResponseDto>('/v1/battles/statistic' + this.query.serialise(params));
  }

  public getStatDashboard(params: IGetSatDashboardParams) {
    const serializedParams = params.active !== undefined ? this.query.serialise({ active: params.active }) : '';
    return this.$authApi.get<TGetStatDashboardResponseDto>(`/v1/users/${params.userId}/dashboard${serializedParams}`);
  }

  public getStatUpgrades(params: IBaseStatRequestParams) {
    return this.$authApi.get<TGetStatUpgradesResponseDto>('/v1/upgrades/statistic' + this.query.serialise(params));
  }

  public getUpgrades(params: TGetProfileUpgradesRequestDto) {
    return this.$authApi.get<TGetProfileUpgradesResponseDto>('/v1/upgrades' + this.query.serialise(params));
  }

  public getBattles(params: IGetProfileBattlesParams) {
    return this.$authApi.get<TGetBattlesResponseDTO>('/v1/battles' + this.query.serialise(params));
  }

  public getBestBattle(params: IGetProfileBaseParams) {
    return this.$authApi.get<TGetBestBattleResponseDTO>('/v1/battles/best' + this.query.serialise(params));
  }

  public getWinrate(params: IGetProfileWinrateParams) {
    return this.$authApi.get<TGetProfileWinrateDTO>('/v1/battles/winrate' + this.query.serialise(params));
  }
}
