import type { IApiCountriesItem } from './api.types';
import type { TCountriesItem } from './client.types';

export const transform = (apiData: IApiCountriesItem[]): TCountriesItem[] => {
  const otherIndex = apiData.findIndex((item) => item.iso === '00');
  const other = otherIndex !== -1 ? apiData.splice(otherIndex, 1)[0] : undefined;

  const result = apiData.map((item) => ({
    icon: getFlagIcon(item.iso),
    label: item.iso,
    value: item.iso,
  }));

  if (other) {
    result.push({
      icon: getFlagIcon(other.iso),
      label: 'custom',
      value: other.iso,
    });
  }

  return result;
};

const getFlagIcon = (iso: string) => `https://tastydrop.me/img/modals/payments/flags/png/${iso.toLowerCase()}.png`;
