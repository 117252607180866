import { storeToRefs } from 'pinia';
import type { THeadersApi } from '~/api/global/api.types';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { LOCALE_COOKIE } from '~/features/localization/store/localization.store';
import { useUserStore } from '~/store/user/user.store';

export class ApiStorage {
  public get defaultHeaders(): THeadersApi {
    return {
      'X-Client-Language': this.getClientLanguage,
    };
  }

  public get authHeaders(): THeadersApi {
    return {
      ...this.defaultHeaders,
      'Authorization': `Bearer ${this.getBearerToken}`,
      'X-Client-Language': this.getClientLanguage,
    };
  }

  public get devHeaders(): THeadersApi {
    return {
      'Authorization': `Basic YWRtOnRhdXJhY2RlclVqVmFjaDQ=`,
      'X-Client-Language': this.getClientLanguage,
    };
  }

  public get bkHeaders(): THeadersApi {
    return {
      'X-Client-Language': this.getClientLanguage,
    };
  }

  public get defaultOldHeaders(): THeadersApi {
    const config = useRuntimeConfig();
    const userStore = useUserStore();
    const headers = { ...this.defaultHeaders };

    if (!config.public.isProduction) {
      Object.assign(headers, {
        Authorization: 'Basic YWRtOnRhdXJhY2RlclVqVmFjaDQ=',
      });

      userStore.user &&
        Object.assign(headers, {
          // TODO костыль для батлов УБРАТЬ!
          // @ts-expect-error TODO remove or refactor
          'X-Dev-User-Id': import.meta.client ? (window.userId ?? userStore.user.userId) : userStore.user.userId,
        });
    }
    return {
      ...headers,
      'X-Client-Language': this.getClientLanguage,
    };
  }

  private get getClientLanguage(): string {
    const cookie = useCookie(LOCALE_COOKIE);
    return cookie.value?.toLowerCase() || '';
  }

  private get getBearerToken(): string {
    const store = useAuthStore();
    const { accessToken } = storeToRefs(store);
    return accessToken.value || '';
  }
}
