<template>
  <div class="alert-container">
    <TransitionGroup name="fade">
      <SharedAlert
        v-for="alert in alertList"
        :key="alert.title + alert.id"
        v-bind="alert"
        :delay="delayAnimation"
        :presets="presets"
        @close="store.close(alert.id)"
      />
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAlertStore } from '~/store/alert/alert.store';
import { Breakpoints } from '~/constants/media.constants';
import { alertDefaultPresets, alertMobilePresets } from '~/presets/alert';
import type { IOptionsPresetAlert } from '~/store/alert/alert.types';

const store = useAlertStore();
const { alertList, delayAnimation } = storeToRefs(store);

const viewport = useViewport();

const isNotDesktop = computed<boolean>(
  () => viewport.breakpoint.value !== Breakpoints.xl && viewport.breakpoint.value !== Breakpoints.xxl,
);

const presets = computed<IOptionsPresetAlert>(() => {
  return isNotDesktop.value ? alertMobilePresets : alertDefaultPresets;
});
</script>

<style scoped lang="scss" src="./AlertController.scss"></style>
