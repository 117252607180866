import DataMapper from '~/api/global/DataMapper';

export function useQuery() {
  const mapper = new DataMapper();
  const nuxtApp = useNuxtApp();
  const route = nuxtApp._route;

  return {
    params: mapper.mapDataKeys(route.params),
    query: mapper.mapDataKeys(route.query),
  };
}
