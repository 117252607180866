export const MAINPAGE_ROUTES = {
  MAINPAGE: {
    name: 'main-page',
    path: '/',
  },
  NEW_CASES: {
    name: 'new-cases',
    path: '/new-cases',
  },
  TOP: {
    name: 'top',
    path: '/top',
  },
};
